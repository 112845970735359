import React, { useEffect, useState } from 'react';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Cell
} from 'recharts';
import { FormControl, Select, MenuItem, Box, Typography, Autocomplete, TextField } from '@mui/material';
import axios from 'axios';
import { identity } from 'lodash';
import { useParams } from 'react-router-dom';

const ElearningChart = () => {
	const [selectedPeriod, setSelectedPeriod] = useState(3); // Default to Last 12 months
	const [selectedSegment, setSelectedSegment] = useState('');
	const [teachers, setTeachers] = useState([]);
	const [teachermerit, setteachermerit] = useState([]);
	const [Legend, setLegend] = useState([]);
	const [LinechartData, setLineChartData] = useState([]);


	const { id } = useParams();







	useEffect(() => {
		GetCourse(id, selectedPeriod);
	}, [selectedPeriod, id]);



	const handleSelectChange = (event) => {
		setSelectedPeriod(event.target.value); // Update the selected period state

	};




	const GetCourse = (id) => {

		axios.get(`${process.env.REACT_APP_BASE_URL}Course1/LMSTeacherCharts/${id}?months=${selectedPeriod}`)
			.then((res) => {


					console.log(res.data,"courseeee") ;
					
				setLineChartData(res.data)

			})
			.catch((err) => {
				console.log(err);

			})
	}

	// Data for chart (ensure this data is structured as expected)



	return (
		<Box

		>
			<Box className="flex-center flex-between flex-wrap mb-20">
				<Typography
					variant="h6"
					component="h6"
					className="fz-18 fw-600 "
				>
					E-learning
				</Typography>

				<FormControl  className='minw200'>
					<Select
						value={selectedPeriod}
						onChange={handleSelectChange}
						className="select"

					>
						<MenuItem value={3}>Last 3 Months</MenuItem>
						<MenuItem value={6}>Last 6 Months</MenuItem>
						<MenuItem value={9}>Last 9 Months</MenuItem>
						<MenuItem value={12}>Last 12 Months</MenuItem>
					</Select>
				</FormControl>
			</Box>


			<Box className="position_rel flex-center justify-content-center mt-20 p-20 border borderR_4">
                <BarChart
                    width={590}
                    height={300}
					data={LinechartData}
					layout="vertical"
				// margin={{ left: 20, right: 20, top: 20, bottom: 20 }}  
				>
					{/* Y-axis for course names */}
					<YAxis
						dataKey="courseName"
						type="category"
						tickLine={false}
						width={100}
					/>

					{/* X-axis for active member count */}
					<XAxis
						dataKey="Count"
						type="number"
						tickLine={false}
					/>

					<CartesianGrid
						strokeDasharray="3"
						vertical={false}
					/>

					<Tooltip />

					{/* Bar Component with dynamic colors */}
					<Bar
						dataKey="Count"
						barSize={20}
						label={false}
					>
						{LinechartData.map((entry, index) => (
							<Cell
								key={`cell-${index}`}
								fill={entry.fill || '#000000'}
							/>
						))}
					</Bar>
				</BarChart>

				<Typography
                    variant="body1"
                    component="p"
                    sx={{
                        position: "absolute",
                        left: "10px",
                        top: "20%",
                        transform: "translateY(100%)",
                        writingMode: "vertical-rl",
                        color: "#000",
                        zIndex: 8,
                        letterSpacing: "0.5px",
                    }}
                    className="fz-14 fw-600"
                >
                    Courses
                </Typography>
                <Typography
                    className="fz-14 fw-600"
                    variant="h6"
                    component="h6"
                    sx={{
                        position: "absolute",
                        left: "50%",
                        bottom: "10px",
                        color: "#000",
                        zIndex: 8,
                        letterSpacing: "0.5px",
                    }}
                >
                    Timeline
                </Typography>
			</Box>
		</Box>
	);
};

export default ElearningChart;
