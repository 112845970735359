import { Box, Button, FormControl, FormControlLabel, Grid, List, ListItem, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import courseImg from './../../../../../assets/images/Registrerbanner.png'
import { KeyboardArrowRight } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
// import MiniDrawer from '../../../../../components/drawer';
import axios from 'axios';
// import courseprofile from '../../../../../../src/assets/images/courseimage.png'
import { enqueueSnackbar } from 'notistack';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { EditorState, convertToRaw,convertFromRaw } from 'draft-js';

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";


import Confetti from 'react-confetti';
import MiniDrawer from '../../../../../../components/drawer';

import JSZip from 'jszip';
// import ZoomInOut from './ZoomInOut';




const questions = [
	'Lorem Ipsum is simply dummy?',
	'Lorem Ipsum is simply dummy?',
	'Lorem Ipsum is simply dummy?'
]

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme }) => ({
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
	variants: [
		{
			props: ({ expand }) => !expand,
			style: {
				transform: 'rotate(0deg)',
			},
		},
		{
			props: ({ expand }) => !!expand,
			style: {
				transform: 'rotate(180deg)',
			},
		},
	],
}));

function BpRadio(props) {
	return (
		<Radio
			sx={{
				'&:hover': {
					bgcolor: 'transparent',
				},
			}}
			disableRipple
			color="default"
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			{...props}
		/>
	);
}
const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: '50%',
	width: 16,
	height: 16,
	boxShadow:
		theme.palette.mode === 'dark'
			? '0 0 0 1px rgb(16 22 26 / 40%)'
			: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
	backgroundImage:
		theme.palette.mode === 'dark'
			? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
			: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	'.Mui-focusVisible &': {
		outline: '2px auto rgba(19,124,189,.6)',
		outlineOffset: 2,
	},
	'input:hover ~ &': {
		backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background:
			theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
	},
}));

const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: '#f3714d',
	backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	'&:before': {
		display: 'block',
		width: 16,
		height: 16,
		backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		content: '""',
	},
	'input:hover ~ &': {
		backgroundColor: '#f3714d',
	},
});

export default function EditCourse() {
	//UseState's:
	// const [courseimageurl, setCourseImageUrl] = React.useState('')
	// const [courseimage, setCourseImage] = React.useState('')
	// const [coursetitle, setCourseTitle] = React.useState('');
	// const [shortdescription, setShortDescription] = React.useState('')
	// const [modeofcourse, setModeofCourse] = React.useState('');
	// const [expandedStates, setExpandedStates] = useState([false]);
	const [uploadLoading,setUploadLoading] = React.useState(false);
	const [festiveOffer, setFestiveOffer] = React.useState(false)


	const auth = JSON.parse(localStorage.getItem("userData"))
	let navigate = useNavigate()
	let { id } = useParams()


	const [isPublished,setIsPublished] = React.useState(false);
	const [showConfetti, setShowConfetti] = React.useState(false);
	const [teacherName,setTeacherName] = React.useState('');
	const [digiCourse,setDigiCourse] = React.useState(null)
	


	// DigitalDownloadForm;
	// const [openDigitalDownloadBox,setOpenDigitalDownloadBox] =React.useState(false);

	// Publish Box Open;
	const [openPublishBox,setOpenPublishCourse] = React.useState(false);
	const [publishDeatils,setPublishDetails] = React.useState(null);
	const [input, setInput] = React.useState([{ type: '', name: '', size: 0 }]);
	const [paymenttype, setPaymentType] = React.useState('')
	const [payingAmount, setPayingAmount] = React.useState('');


	const getRawDescription = (data)=>{
	
		try {
            const rawContent = {
                blocks: data.blocks,
                entityMap: {} 
            };
    
            const contentState = convertFromRaw(rawContent);
            const newEditorState = EditorState.createWithContent(contentState);
    
            setEditorState(newEditorState);
        } catch (error) {
            console.error("Error converting rawdescription to EditorState:", error);
        }
	}

	function getFileNameAndExtension(file) {
		console.log(file);
		
		// Assuming file is a string like 'public/1729854898076-files.zip'
		const filePath = file.file; // Accessing the file path
		const lastSlashIndex = filePath.lastIndexOf('/'); // Find the last occurrence of '/'
		const fileNameWithExtension = filePath.substring(lastSlashIndex + 1); // Get the filename with extension
		
		// Find the last occurrence of '.'
		const lastDotIndex = fileNameWithExtension.lastIndexOf('.');
		
		// Extract the file name excluding the unwanted prefix (assumes the prefix is before the first '-')
		const fileName = fileNameWithExtension.substring(fileNameWithExtension.indexOf('-') + 1, lastDotIndex);
		
		// Extract the extension (including the dot)
		const extension = fileNameWithExtension.substring(lastDotIndex); // Get .zip
		
		function bytesToMB(bytes) {
			const mb = bytes / 1048576; // Convert bytes to MB
			return mb.toFixed(2); // Format to two decimal places
		}
		
		return {
			fullName: `${fileName}${extension}`, // Combine name and extension
			extension: extension,
			size: bytesToMB(file.size) // Assuming file.size is available
		};
	}

	const [existingFile,setExistingFile] = React.useState([])
	// course Details:
	React.useEffect(() => {
		
        getChapters()
    }, [id])

    const getChapters = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Course1/GetCourse/${id}`);
            setDigiCourse(response.data.course);
	 		setFestiveOffer(response.data.course.festiveOffer)

			getRawDescription(JSON.parse(response.data.course.rawdescription))
			setExistingFile(response.data.course.files.length && response.data.course.files )
			setPaymentType(response.data.course.course_paymenttype)

			const {fullName,extension,size} = getFileNameAndExtension(response.data.course.files.length && response.data.course.files[0] )
			console.log(fullName,extension,size)
			setInput([{type: extension, name: fullName, size: size}])
			setPayingAmount(response.data.course.amountOneTime ? response.data.course.amountOneTime : null)
            
        } catch (error) {
            console.error(error);
        }
    }


	console.log(digiCourse)
	
          
    // const [open, setOpen] = React.useState(chapters.map(() => true));

	// amountOneTime: 456,
    // course_paymenttype: 'onetimepayment',


	//Teacher details:
	// React.useEffect(()=>{
	// 	GetTeacher()
	//   },[])
	
	//   const GetTeacher = () => {
	// 	axios.get(process.env.REACT_APP_BASE_URL + `Admin/getTeachersbyid/${auth?._id}`)
	// 	.then((res)=>{
	// 	  setTeacherName(res.data.teachers[0].username);
	// 	})
	// 	.catch((err)=>{
	// 	  console.log(err,"error in GetTeacher");
	// 	})
	//   }


    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());

	  //Text Editor function

	  const onEditorStateChange = (newEditorState) => {
		  setEditorState(newEditorState);
        setInpError({ descriptionmsg: '' })
    };

	const getEditorContent = () => {
        const contentState = editorState?.getCurrentContent();
        const rawContent = convertToRaw(contentState);
        const plainText = contentState.getPlainText();
        return { rawContent, plainText };

    };

	
	const [file,setFile] = React.useState([])
	const [digiDownLoadId,setDigiDownLoad] = React.useState(id)



	// const handleFileChange =async (e) => {
		
	// 	const selectedFile = e.target.files[0];
	//   if (selectedFile) {
	   
	// 	// Validate file type
	// 	// const extension = selectedFile.name.split('.').pop().toLowerCase();
	// 	// const supportedFileTypes = ['mp3', 'mp4', 'jpg', 'png']; // Example supported file types

	// 	// Get the extension
	// 	const extension = selectedFile.name.split('.').pop().toLowerCase();

	// 	//name of the file
	// 	const fullName = selectedFile.name;

	// 	// Convert size to MB
	// 	const sizeInMB = (selectedFile.size / (1024 * 1024)).toFixed(2); // Converts bytes to MB
	// 	// Create a new zip object
    //     const zip = new JSZip();

	// 	// Add the file to the zip
    //     zip.file(fullName, selectedFile);

    //     // Generate the zip file as a Blob
    //     const zipBlob = await zip.generateAsync({ type: 'blob' });

    //     // Create a new file from the Blob
    //     const zipFile = new File([zipBlob], `${fullName}.zip`, { type: 'application/zip' });

    //     // Update the input state with the file information
    //     setInput([{ type: extension, name: fullName, size: sizeInMB }]);
	// 	// Update the input state with the file information
	
	// 	setFile(zipFile)
	//   }};

	const [fileSize,setFileSize] = React.useState(null)

	const handleFileChange = async (e) => {
		const selectedFiles = Array.from(e.target.files); // Convert FileList to an array
		if (selectedFiles.length > 0) {
			const zip = new JSZip();
			const fileDetails = []; // Array to hold file details for preview
			let totalSizeInBytes = 0; // Variable to hold total size in bytes
	
			for (const selectedFile of selectedFiles) {
				// Validate file type if needed
				const extension = selectedFile.name.split('.').pop().toLowerCase();
				const fullName = selectedFile.name;
				const sizeInMB = (selectedFile.size / (1024 * 1024)).toFixed(2); // Converts bytes to MB
	
				// Add the file to the zip
				zip.file(fullName, selectedFile);
	
				// Push the file details for preview
				fileDetails.push({ type: extension, name: fullName, size: sizeInMB });
	
				// Accumulate the total size
				totalSizeInBytes += selectedFile.size;
			}
	
			// Calculate total size in MB
			setFileSize((totalSizeInBytes / (1024 * 1024)).toFixed(2));
	
			// Add total size to the fileDetails array (optional)
			// setFileDetails([{size: totalSizeInMB,count:fileDetails.length}])
	
			
	
			// Generate the zip file as a Blob
			const zipBlob = await zip.generateAsync({ type: 'blob' });
	
			// Create a new file from the Blob
			const zipFile = new File([zipBlob], 'files.zip', { type: 'application/zip' });
	
			// Update the input state with the file information
			setInput(fileDetails); // Update to show details of all files
			setFile(zipFile); // Set the zip file for uploading
		}
	};
	
	  console.log(input)
	  console.log(file)
	
	  
	// const handleExpandClick = (index) => {
	// 	setExpandedStates((prev) =>
	// 		prev.map((state, i) => (i === index ? !state : state))
	// 	);
	// };


	//Validation:
	const [error, setError] = React.useState({ titlemsg: '', modemsg: '',onetimemsg:"" });
	const [inperror, setInpError] = React.useState({ descriptionmsg: '', filemsg: '',paymentmsg:"" });


	// const Validation = () => {
	// 	if (!coursetitle) {
	// 		setError({ titlemsg: 'This field is required' })
	// 		return false
	// 	}
	// 	if (!modeofcourse) {
	// 		setError({ modemsg: 'This field is required' })
	// 		return false
	// 	}
		
	// 	return true
	// }

	const validateEditorContent = () => {
		const content = editorState.getCurrentContent();
		return !content.hasText(); // Returns true if there's no text
	  };

	const DigiValidation = () =>{
		
		
			
			if(validateEditorContent()){
				setInpError({descriptionmsg:"This field is required"})
				return false;
			}
			
			if(!input[0].name){
			
				setInpError({filemsg:"This field is required"})
				return false;
			}
			if(paymenttype === "paid"){
				console.log("called")

				if(!payingAmount){
					setInpError({paymentmsg:"This field is required"})
					return false;
				}
			}
		
		return true;
	}


	// const handleImageChange = (e) => {
	// 	const PreviewImage = URL.createObjectURL(e.target.files[0])
	// 	const file = e.target.files[0];
	// 	if (PreviewImage && file) {
	// 		setCourseImageUrl(URL.createObjectURL(file));
	// 		setCourseImage(file)
	// 	}
	// };



	const handleFestiveOffer =async (e) => {
		
		setFestiveOffer(e.target.value);
		
		try {
			const response = await axios.put(
			  process.env.REACT_APP_BASE_URL + `Course1/festiveOfferupdate/${digiCourse._id}`,
			  { festiveOffer:e.target.value}
			); 
			
			if (response.data.update.festiveOffer) {
				enqueueSnackbar('Course is added in Festive Offer', { variant: 'success' });
				
			  } else {
				
				enqueueSnackbar(' Course is excluded from Festive Offer', { variant: 'success' });
			  }
		  } catch (error) {
			console.error("Error while adding offer:", error);
		  }
	  }

	const DigiDownloadUpdateCourse = async () =>{
		setUploadLoading(true)
		if(DigiValidation()){

			const { rawContent, plainText } = getEditorContent();
			// alert("hurray")
			const url = `${process.env.REACT_APP_BASE_URL}Course1/addPaymentPricingforDigital/${digiDownLoadId}`;
			let formdata = new FormData()
			formdata.append('rawdescription',JSON.stringify(rawContent) )
			formdata.append("plaindescription", plainText)
			formdata.append("course_paymenttype", paymenttype)

			if(paymenttype !== "free") formdata.append("amountOneTime", payingAmount)   
			
			formdata.append("currency", "USD")
			if (!file.length && file) {
				console.log("called")
				formdata.append(`files`, file);
				formdata.append(`name`, file.name);
				formdata.append(`size`, file.size);
		
			}
			if(!file.length){
					existingFile
					  ? existingFile.forEach((item) => {
						formdata.append("existingfile", item.file);
						})
					  : formdata.append("existingfile", "");
			}

			
			try {
				const response = await axios.put(url,formdata,{
					headers:{
						"Content-Type": "multipart/form-data",
						  Authorization: localStorage.getItem("token"),
					}
				})
				enqueueSnackbar('Data Updated Successfully',{variant:'success'})
				
				setOpenPublishCourse(true)
				setPublishDetails(response.data.course)
				setIsPublished(response.data.course.publishCourse)
				console.log(response.data.course)
			} catch (error) {
				console.error("Error uplo.course Digital Download Data", error);		
				enqueueSnackbar('Error Updating Digital Download Data',{variant:'error'})

			} finally{
				setUploadLoading(false)
			}
		}
	}
	


	
	const handlePublishToggle = async (event) => {
		
		  const newPublishStatus = true;
			try {
				const response = await axios.put(
					process.env.REACT_APP_BASE_URL + `Course1/PublishCourse/${digiDownLoadId}`,
					{ publishCourse: true, publishername: teacherName }
				);
				setIsPublished(newPublishStatus);
	  
				if (newPublishStatus) {
					enqueueSnackbar('Course Published Successfully', { variant: 'success' });
					setShowConfetti(true); // Trigger confetti

					setTimeout(() => {
						navigate(`/teachers/details/course/${response.data.update._id}`)
					}, 3500);
					
				} else {
					enqueueSnackbar('Course Unpublished Successfully', { variant: 'success' });
				}
	  
				// Optionally, you can refresh course details after updating
			} catch (error) {
				console.error("Error updating publish status:", error);
				enqueueSnackbar('Error while Publishing Digital content', { variant: 'error' });

			
		  }
	  };
	  

	const handleUnPublishToggle = async (e) => {
		
			const newPublishStatus = false;
			try {
				const response = await axios.put(
					process.env.REACT_APP_BASE_URL + `Course1/PublishCourse/${digiDownLoadId}`, 
					{ publishCourse: false,publishername:teacherName }
				);
				setIsPublished(newPublishStatus);
	  
				// Use newPublishStatus for the notification
				if (newPublishStatus) {
					enqueueSnackbar('Course Published Successfully', { variant: 'success' });
				} else {
				 setShowConfetti(false)
				 if(digiCourse){
					enqueueSnackbar('Course Under Edit', { variant: 'success' });
					return;
				 }
					enqueueSnackbar('Course Unpublished Successfully', { variant: 'success' });
				}
	  
				// Optionally, you can refresh course details after updating
			} catch (error) {
				console.error("Error updating publish status:", error);
				enqueueSnackbar('Error while UnPublishing Digital content', { variant: 'error' });

			}
		
	  };

	  const handleNavigateToTable = () =>{
		
			navigate(`/teachers/details/course/${auth._id}`)
		
	  }

	const dropDownArray  = [12,324,854,4]
	const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };
	const trimmedDescription = publishDeatils?.plaindescription?.split(' ').slice(0, 20).join(' ') + (publishDeatils?.plaindescription?.split(' ').length > 20 ? '...' : '');


	return (
		<Box className={auth.role_id === 1 ? 'withSubmenu' : ''}>
			<MiniDrawer menuOrder={4} />
			<Box component="section" className="contentWraper">
				<Box className='headingBox'>
					<Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Course</Typography>
				</Box>
				<Box className="course firstScreen whiteContainer">
					<Grid container>
						<Grid item xs={12} sm={6} md={6} className='flex-center justify-content-center'>
							<Box className="h-100 p-20 leftContent">
								<Box>
									<Typography component='h5' variant='h5' className='semibold'>Describe what you'd like to teach and  we'll craft your course</Typography>
									<Typography component='div' variant='body1' className='mt-30'>Lorem Ipsum is simply dummy text of the printing and typesetting industry</Typography>
								</Box>
								<List className='w100 my-30'>
									{questions.map((question, index) =>
										<ListItem className='p-0'>
											<ListItemText ><Typography component='span' className='semibold'>{`${index + 1}. ${question}`}</Typography></ListItemText>
										</ListItem>,
									)}
								</List>
								<Typography component='div' variant='body1' className='fz-14'>Lorem Ipsum is simply dummy text of the printing and typesetting industry</Typography>
							</Box>
						</Grid>



						{
							!openPublishBox && 
							<Grid item xs={12} sm={6} md={6} className='flex-center justify-content-center rightContent_grid'>
								<Box className="d-flex justify-content-center p-20 flex-col">
									<Card className='mt-20 p-20'>
									<Typography component='div' className='label' style={{ marginRight: 430 }}>Description <Typography component='span' className='star'> *</Typography></Typography>

											<Editor
												editorState={editorState}
												toolbarClassName="toolbarClassName"
												wrapperClassName="wrapperClassName"
												editorClassName="editorClassName"
												onEditorStateChange={onEditorStateChange}
												editorStyle={{ minHeight: 300 }}
											/>
									{inperror.descriptionmsg && <p style={{ color: 'red' }} className="fz-13 m-0">{inperror.descriptionmsg}</p>}

									</Card>

								
									{/* <Card style={{display:"flex", justifyContent:"space-between"}} className="mt-20 p-20">
										<Card style={{ width: "250px" }} className="mt-20 p-20">
											
											<Box >
											<Typography component='div' className='label' >Update File <Typography component='span' className='star'> *</Typography></Typography>
		
													<Button
															className="upload_btn"
															variant="contained"
															component="label"
															fullWidth
															>
															<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
																<FileUploadOutlinedIcon className="fz-18 mr-5" />
																{"Update File"}
															</Box>
														
																<input
																	//   accept={row.type === "Video" ? "video/*" :
																	//   row.type === "Audio" ? "audio/*" : "file/*"}
																	accept='file/*'
																	onChange={(e) => {
																		handleFileChange(e);
																		setInpError({filemsg:""})
																	}}
																	multiple
																	hidden
																	type="file"
																/>
																
													</Button>						
											</Box>
											{inperror.filemsg && <p style={{ color: 'red' }} className="fz-13 m-0">{inperror.filemsg}</p>}
		
											</Card>
										{
											
											<Card  style={{ width: "250px" }} className="mt-20 p-20">
											<Box >
												<Typography component='div' className='label' >File Name</Typography>
												<Typography component='span' className='star'>{input[0].name}</Typography>
												
												<Typography component='div' className='label' >File Extention</Typography>
												<Typography component='span' className='star'>{input[0].type}</Typography>

												<Typography component='div' className='label' >File Size</Typography>
												<Typography component='span' className='star'>{`${input[0].size} MB`}</Typography>						
											</Box>
									

											</Card>
										}
									</Card> */}

					<Card
                      sx={{
                        display: "flex",
                        
                        gap: 2,
                      }}
					  className="mt-20 p-20"
                    >
                      {/* Left section (1 part) */}
                      <Box sx={{ width: "25%" }}>
                        <Card sx={{ p: 2 }}>
							
                          <Box>
                            <Typography component="div" className="label">
                              Update File{" "}
                              <Typography
                                component="span"
                                sx={{ color: "error.main" }}
                              >
                                *
                              </Typography>
                            </Typography>

                            <Button
                              className="upload_btn"
                              variant="contained"
                              component="label"
                              fullWidth
                              sx={{ mt: 1 }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <FileUploadOutlinedIcon
                                  sx={{ fontSize: 18, mr: 0.5 }}
                                />
                                Update File
                              </Box>
                              <input
                                //   accept={row.type === "Video" ? "video/*" :
                                //   row.type === "Audio" ? "audio/*" : "file/*"}
                                accept="file/*"
                                onChange={(e) => {
                                  handleFileChange(e);
                                  setInpError({ filemsg: "" });
                                }}
                                multiple
                                hidden
                                type="file"
                              />
                            </Button>
                          </Box>
                          {inperror.filemsg && (
                            <Typography
                              sx={{ color: "error.main", fontSize: 13, mt: 1 }}
                            >
                              {inperror.filemsg}
                            </Typography>
                          )}
                        </Card>
                      </Box>

                      {/* Right section (3 parts) */}
                      <Card sx={{ width: "75%" }}>
                        <Card sx={{ p: 2 }}>
                          <Grid container spacing={2}>
                            {/* Left column */}
                            <Grid item xs={6}>
                            <Box>
                              <Typography component="div" className="label">
                                ZIP Name
                              </Typography>
                              <Typography
                                component="div"
                                sx={{ color: "error.main" }}
                              >
                                {file.name || input[0].name}
                              </Typography>

                              

                              <Typography
                                component="div"
                                className="label"
                                sx={{ mt: 2 }}
                              >
                                Total Size
                              </Typography>
                              <Typography
                                component="div"
                                sx={{ color: "error.main" }}
                              >
								
								{
									fileSize ? fileSize : (input && `${input[0].size} MB`) 
								}
                                {/* {fileDetails[0]?.size && `${fileDetails[0].size} MB`} */}
                              </Typography>
                            </Box>
                          </Grid>

                            {/* Right column */}
                            <Grid item xs={6}>
                            {
								input.length > 0 &&
								<Box>
                              <Typography component="div" className="label">
                                Additional Details
                              </Typography>
                              <Typography variant="body2" >
                                List of Files:
                              </Typography>
                              <ul >
                                {input.map((file, index) => (
                                  <li key={index}>
                                    {file.name}
                                  </li>
                                ))}
                              </ul>
                              <Typography variant="body2" >
                                Count Of Files: {input.length}
                              </Typography>
                            </Box>
							}
                          </Grid>
                          </Grid>
                        </Card>
                      </Card>
					  
                   
                  </Card>
										
									
									


									<Card className="mt-20 p-20">
										<Box className="mb-20 ">
										<Typography component='div' className='label' style={{ marginRight: 430 }}>Choose Payment Mode <Typography component='span' className='star'> *</Typography></Typography>
											<RadioGroup
												row
												aria-labelledby="demo-customized-radios"
												name="payment_type"
												onChange={(e) => {
													setPaymentType(e.target.value);
													setInpError({paymentmsg:""});
												}}
												value={paymenttype}
											>
												<FormControlLabel
													value="free"
													className='radio-btns'
													control={<BpRadio />}
													label={<Typography className='fz-14'>Free</Typography>}
												/>
												
												<FormControlLabel
													value="onetimepayment"
													className='radio-btns'
													control={<BpRadio />}
													label={<Typography className='fz-14'>Paid</Typography>}
												/>

											</RadioGroup>
										</Box>

										{paymenttype == 'onetimepayment' && <Grid container columnSpacing={2} rowSpacing={2} className="mb-20">
							<Grid item xs={12} sm={6} md={4} lg={4}>
								<FormControl variant="standard" fullWidth>
									<Typography component='div' className='label'>Amount<Typography component='span' className='star'> *</Typography></Typography>
									<TextField
										id="filled-size-small"
										variant="outlined"
										size="small"
										className='textfield'
										placeholder='Amount'
										name="amount"
										onInput={(e) => onlyNumbers(e)}
										value={"$" + payingAmount}
										
										sx={{
											marginBottom: "5px"
										}}

										onChange={(e) => {
											 setPayingAmount(e.target.value);
											  setInpError({ paymentmsg: '' })
											   }} 
											   disabled/>
									{inperror.paymentmsg && <p style={{ color: 'red' }} className="fz-13 m-0">{inperror.paymentmsg}</p>}
								</FormControl>
							</Grid>

						

										</Grid>}
										</Card>

										<Box className="text-right mt-30">
											{/* <Button className="theme-btn" onClick={() => navigate('/teachers/details/courses/details')}>Create</Button> */}
											
											<Button className="theme-btn" onClick={DigiDownloadUpdateCourse} >Update</Button>
											
											
										</Box>

								</Box>	
						</Grid>
						}


						{openPublishBox && 
							<Grid item xs={12} sm={6} md={6} className='flex-center justify-content-center rightContent_grid'>
								<Box className="d-flex justify-content-center p-20 flex-col">
									<Card style={{ width: "410px" }} className='mt-20 p-20'>
									<Box >
										<Typography className="fz-16 text_black semibold">Course Name</Typography>
										<Typography
										component="h6"
										variant="h6"
										className="fz-16 text_black mb-10"
										>
										{publishDeatils.course_title}
										</Typography>

										<Typography className="fz-16 text_black semibold">Course Description</Typography>
										<Typography
										component="h6"
										variant="h6"
										className="fz-16 text_black mb-10"
										>
										{trimmedDescription}
										</Typography>


										<Typography className="fz-16 text_black semibold">File Name</Typography>
										<Typography
										component="h6"
										variant="h6"
										className="fz-16 text_black mb-10"
										>
										{publishDeatils.files[0]?.name ? publishDeatils.files[0]?.name : "NA"}
										</Typography>

										<Typography className="fz-16 text_black semibold">Payment Type</Typography>
										<Typography
										component="h6"
										variant="h6"
										className="fz-16 text_black mb-10"
										>
										{publishDeatils ? publishDeatils.course_paymenttype : "NA"}
										</Typography>


										<Typography className="fz-16 text_black semibold">Course Fees</Typography>
										<Typography
										component="h6"
										variant="h6"
										className="fz-16 text_black mb-10"
										>
										{publishDeatils.amountOneTime ? `${publishDeatils.currency === "USD" ? `$` : ""} ${publishDeatils.amountOneTime} ` : "free"}
										</Typography>

											<FormControl variant="standard" fullWidth className="mt-20 mb-20">
												<Typography component="div" className="label fz-20 text_black semibold">
													Would you like to include this FansPage in Festive Offer?
												</Typography>
												<RadioGroup
													row
													aria-labelledby="demo-customized-radios"
													name="payment_type"
													onChange={handleFestiveOffer}
													value={festiveOffer}
												>
													<FormControlLabel
														value={true}
														className="radio-btns"
														control={<BpRadio />}
														label={<Typography className="fz-16">Yes</Typography>}
													/>
													<FormControlLabel
														value={false}
														className="radio-btns"
														control={<BpRadio />}
														label={<Typography className="fz-16">No</Typography>}
													/>
												</RadioGroup>
											</FormControl>


										<Box>
										{!isPublished ? 
												<Button  variant="contained" className="theme-btn" onClick={(e)=>handlePublishToggle(e)} >Publish Course</Button> 
												:
												<Button  variant="contained" className="theme-btn" onClick={(e)=>handleUnPublishToggle(e)} >UnPublish Course</Button> }
										{showConfetti == true && 
											
											<>
											<Confetti
											width={window.innerWidth}
											height={window.innerHeight}
											recycle={false}
											numberOfPieces={500}
											/>
											{/* <ZoomInOut/> */}
											</>
										}
												<Button  variant="contained" className="theme-btn" onClick={(e)=>handleNavigateToTable(e)}  >Back To Course</Button> 
										</Box>



										
										
															
									</Box>	
									</Card>
								</Box>
							</Grid>
						}

						
					</Grid>
				</Box>
			</Box>
		</Box>
	)
}
