import Sidebar from './../../Sidebar'
import MiniDrawer from '../../../../../components/drawer';
import React, { useRef, useState } from "react";

import Check from '@mui/icons-material/Check';
import Avatar from '@mui/material/Avatar';
import { Box, Button, Typography, Tooltip, ClickAwayListener, Input, FormControl, Grid, Select, ListItemText, Radio, RadioGroup, Checkbox, OutlinedInput, Grow, InputAdornment, MenuItem, MenuList, Popper, TextField, FormControlLabel } from '@mui/material';
import axios from 'axios';

import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { usePlacesWidget } from "react-google-autocomplete";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Category, Close, Dvr, ListAlt, WidthFull } from '@mui/icons-material';

export default function AddfanPost() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }

    function BpRadio(props) {
        return (
            <Radio
                sx={{
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                }}
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                {...props}
            />
        );
    }
    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#f3714d',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#f3714d',
        },
    });


    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#784af4',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#784af4',
            zIndex: 1,
            fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }));

    function QontoStepIcon(props) {
        const { active, completed, className } = props;
        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </QontoStepIconRoot>
        );
    }

    QontoStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
    };

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor:
                    '#f3714d',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor:
                    '#f3714d',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundColor:
                '#f3714d',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundColor:
                '#f3714d',
        }),
    }));


    //UseState's:
    const navigate = useNavigate()
    const params = useParams()
    const auth = JSON.parse(localStorage.getItem("userData"))
    const [timeline, setTimeline] = React.useState([])
    const [statesingle, setStatesingle] = React.useState([])
    const [statesingle2, setStatesingle2] = React.useState([])
    const [state1, setState1] = React.useState([])
    const [state2, setState2] = React.useState([])
    const [imageAddError, setImageAddError] = React.useState('')
    const [imageError, setImageError] = React.useState('')
    const [selectedVideo, setSelectedVideo] = useState([]);
    const [videoPreviewUrl, setVideoPreviewUrl] = useState([]);
    const [description, setDescription] = React.useState('')
    const [addDisable, setAddDisable] = React.useState(false)
    const [community, setCommunity] = React.useState([])
    const [selectGallert, setSelectGallery] = useState('Image')
    const [error, setError] = useState({ video: "", image: "" });
    const [user, setUser] = React.useState([])
    const { enqueueSnackbar } = useSnackbar()
    const [newtimeline, setNewTimeline] = React.useState({ timelineName: '' })


    //To Upload Single Image:
    const SingleImage = (e, index) => {
        setImageError('');
        setError('');

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files);

        for (let i = 0; i < fileObj[0].length; i++) {
            const img = new Image();
            img.src = URL.createObjectURL(fileObj[0][i]);

            img.onload = function () {
                if (fileObj[0][i].size > 1000000) { // 1 MB in bytes
                    setImageError('Image size should be less than 1 MB.');
                } else {
                    fileArray.push(URL.createObjectURL(fileObj[0][i]));
                    filepath.push(fileObj[0][i]);

                    var data = statesingle.filter(x => x != null);
                    var data1 = statesingle2.filter(x => x != null);
                    setStatesingle(data);
                    setStatesingle2(data1);
                    setStatesingle(prevValue => prevValue.concat(fileArray));
                    setStatesingle2(prevValue => prevValue.concat(filepath));
                }
            };
        }
    };

    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }

    //To Upload Multiple Image:
    const multipleImgChange = (e, index) => {
        setImageAddError('');
        setImageError('')

        // Validate file size
        const maxSize = 1024 * 1024; // 1MB in bytes
        const files = e.target.files;
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        for (let i = 0; i < files.length; i++) {
            if (files[i].size > maxSize) {
                // Handle file size error (for example, set an error state)
                setImageAddError('Image size should be less than 1 MB.');
                continue; // Skip this file
            }

            fileObj.push(files[i]);
            fileArray.push(URL.createObjectURL(files[i]));
            filepath.push(files[i]);
        }

        var data = state1?.filter(x => x != null);
        var data1 = state2?.filter(x => x != null);
        setState1(data);
        setState2(data1);
        setState1(prevValue => prevValue.concat(fileArray));
        setState2(prevValue => prevValue.concat(filepath));
    }

    const indexDelete = (e, index) => {
        var filteredpreview = state1?.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
    }



    //Hook's state:


    //OnChange:
    let name, value;
    const handlechange = (e) => {
        name = e.target.name;
        value = e.target.value;
        setNewTimeline({ ...newtimeline, [name]: value })
    }

    //Hook's state and Get User details:

    const [selecttedUsers, setSelecttedUsers] = React.useState([])
    const [userName, setUserName] = React.useState([])


    const [selecttedCommunity, setSelecttedCommunity] = React.useState([])
    const [coummunityName, setCommunityName] = React.useState([])







    const handleVideoChange = (e, index) => {
        setError(prevError => ({ ...prevError, video: '' }))
        setImageError('')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setVideoPreviewUrl(fileArray)
        setSelectedVideo(filepath)
    }

    const clearVideo = () => {
        setSelectedVideo([]);
        setVideoPreviewUrl([]);
    };

    const handleEditGallery = (e) => {
        setSelectGallery(selectGallert === 'Image' ? 'Video' : 'Image')
        if (selectGallert === 'Image') {
            setSelectedVideo([]);
            setVideoPreviewUrl([]);
        }

    }


    React.useEffect(() => {
        getTimeline()
    }, [])

    const getTimeline = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Post/getallpost`, { headers: headerformdata });
            setTimeline(response.data);
        } catch (error) {
            console.log(error.response, "error");
        }
    }




    //Validation:
    const Validator = () => {
        if (statesingle2?.length === 0) {
            setImageError("This field is required.")
            return false
        }
        if (selectGallert == 'Image') {
            if (state1.length === 0) {
                setImageError("This field is required")
                // setError(prevError => ({ ...prevError, image: "This field is required." }));
                return false;
            } else {
                setImageError('')
                setError(prevError => ({ ...prevError, image: "" }));
            }
        }
        else if (selectGallert == 'Video') {
            if (selectedVideo.length === 0) {
                setImageError('This field is required')
                // setError(prevError => ({ ...prevError, video: "This field is required." }));
                return false;
            } else {
                setImageError('')
                setError(prevError => ({ ...prevError, video: "" }));
            }
        }
        return true;
    };


    const addTimelineDetails = () => {

        var multipleImage = state2.filter(x => x != null);
        const validator = Validator()
        if (validator == true) {
            setAddDisable(true)
            const formData = new FormData();
            selecttedUsers.forEach(id => {
                formData.append("createdby[]", auth && auth._id);
            });
            formData.append("description", description)

            formData.append('fan_page_id', params && params.fanid)
            { statesingle2 ? statesingle2.forEach((item) => { formData.append("newimage", item) }) : formData.append("newimage", '') }
            formData.append("gallery", selectGallert === 'Image' ? 'Image' : 'Video')
            {
                selectGallert === 'Image' ? <>
                    {multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '')}
                    {selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", '') }) : formData.append("Video", '')}
                </>
                    :
                    <>
                        {multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", '') }) : formData.append("additionalimages", '')}
                        {selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '')}</>
            }
            axios.post(process.env.REACT_APP_BASE_URL + `FanPost/AddFanPost`, formData, { headers: headerformdata })
                .then((res) => {
                    setStatesingle('')
                    setState1([]);
                    setState2([]);
                    setSelectedVideo([]);
                    setDescription('');
                    setNewTimeline({ timelineName: '', description: "" });
                    enqueueSnackbar("Fan post added successfully", { variant: 'success' });
                    navigate(-1)

                }).catch((err) => {
                    console.log(err)
                    enqueueSnackbar(err && err.response && err.response.message ? err.response.message : err.response.data.error ? err.response.data.error : "Somthing went wrong", { variant: 'error' })
                })
                .finally(()=>{
                    setAddDisable(false)
                })
        }
    }



    return (
        <Box className={auth.role_id == 1 ? 'withSubmenu' : ''}>
            {auth.role_id == 1 ? <MiniDrawer menuOrder={2} submenuOrder={1} />
                : <MiniDrawer menuOrder={17} />}
            {/* <Sidebar subtype={9} /> */}
            {auth.role_id == 1 ? <Sidebar subtype={8} /> : ''}

            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle mb-20'>Add Fan Page Post</Typography>

                                <Grid item sm={12} md={12} className="w-100">
                                    <Box className="form-box " >


                                        <Grid item xs={12} sm={12} md={12} >
                                            <label className='form-labels label' for="my-input" >Primary Image <Typography component='span' className='star'> * </Typography></label>
                                            <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                {statesingle.length < 1 &&

                                                    <Button className="multiple_upload_btn" variant="contained" component="label">
                                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                        <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
                                                    </Button>
                                                }
                                            </Box>
                                            {imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}
                                        </Grid>


                                        <Grid item xs={12} sm={12} md={12}  >
                                            {statesingle.length > 0 &&
                                                <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                    {statesingle.length > 0 ? statesingle.map((x, i) => (
                                                        <Grid item xs={6} sm={4} md={4} lg={4} >
                                                            <Box className='uploaded_img' >
                                                                <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                                                <img src={x} alt='' height={155} width={195} />
                                                            </Box>
                                                        </Grid>
                                                    )) : ""}
                                                </Grid>
                                            }
                                            <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 280px * 280px for better quality picture.</Typography>
                                        </Grid>

                                        {/* <Box className="steppy_content mb-10"> */}

                                        {/* </Box> */}

                                        <Grid item xs={12} sm={4} md={4} lg={4} className="mt-20">
                                            <Typography component='div' className='label'>Images/Video </Typography>

                                        </Grid>
                                        <Grid item xs={12} sm={8} md={8} lg={8}>
                                            <Box className=" ">
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-customized-radios"
                                                    name="payment_type"
                                                    onChange={handleEditGallery}
                                                    value={selectGallert}
                                                >
                                                    <FormControlLabel value="Image" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Additional images</Typography>} />
                                                    <FormControlLabel value="Video" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Video</Typography>} />
                                                </RadioGroup>
                                            </Box>
                                        </Grid>
                                        {selectGallert === 'Image' ? <>
                                            <Grid item xs={12} sm={12} md={12} className="mt-20" >
                                                <label className='form-labels label' for="my-input" >Additional Images <Typography component='span' className='star'> * </Typography></label>
                                                <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                    {state1.length < 1 &&
                                                        <Button className="multiple_upload_btn" variant="contained" component="label">
                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                            <input accept={"image/png, image/jpg,image/jpeg"} onChange={multipleImgChange} hidden multiple type="file" />
                                                        </Button>
                                                    }
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}  >
                                                {state1.length > 0 &&
                                                    <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                        {state1.length > 0 ? state1.map((x, i) => (
                                                            <Grid item xs={6} sm={4} md={3} lg={3} >
                                                                <Box className='uploaded_img' >
                                                                    <Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
                                                                    <img src={x} alt='' height={155} width={195} />
                                                                </Box>
                                                            </Grid>
                                                        )) : ""}
                                                        <Grid item xs={6} sm={4} md={3} lg={3} >
                                                            <label htmlFor="contained-button-file1" className="w-full">
                                                                <Box className='multiple_upload_btn' >
                                                                    <Input
                                                                        inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
                                                                        onChange={multipleImgChange}
                                                                        style={{ display: "none", }}
                                                                        id="contained-button-file1"
                                                                        type="file"
                                                                    />
                                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
                                                                </Box>
                                                            </label>
                                                        </Grid>
                                                    </Grid>}
                                                {imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}

                                            </Grid>
                                            <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 280px * 280px for better quality picture.</Typography>
                                        </> : <>
                                            <Grid item xs={12} sm={12} md={12} className="mb-20">
                                                <label className='form-labels label' for="my-input" >Video <Typography component='span' className='star'> * </Typography></label>

                                                <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                    {selectedVideo && selectedVideo?.length === 0 &&
                                                        <Button className="multiple_upload_btn" variant="contained" component="label">
                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
                                                            </Box>
                                                            <input id="video-upload" accept="video/*" onChange={handleVideoChange} hidden type="file" />
                                                        </Button>
                                                    }
                                                    {selectedVideo && selectedVideo?.length > 0 &&
                                                        <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                                <Box className="uploaded_vdo" >
                                                                    <Button className='close_icon' onClick={clearVideo}><Close /></Button>
                                                                    <video className='uploaded_vdo' controls>
                                                                        <source src={videoPreviewUrl} type={selectedVideo.type} />
                                                                    </video>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Box>
                                                {imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}
                                            </Grid>

                                        </>}

                                        <Grid item xs={5} sm={5} md={5} lg={9}>
                                            <label className='form-labels label' for="my-input" >Description </label>
                                            <FormControl variant="standard" fullWidth>
                                                <textarea
                                                    placeholder='Description '
                                                    className='textarea'
                                                    style={{ width: '100% ', resize: 'none' }}
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    rows={4}
                                                    cols={40}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item lg={12} className=' text-right'>
                                            {addDisable == false ? <Button variant="contained" onClick={addTimelineDetails} sx={{ mt: 1, mr: 1, width: 100, marginLeft: 'auto' }}
                                                className='theme-btn'>Submit</Button> : <Button variant="contained" sx={{ mt: 1, mr: 1 }} className='theme-btn'>Please wait</Button>}
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>


            </Box >
        </Box >
    )
}