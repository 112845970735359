import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { Add, Close, Videocam } from "@mui/icons-material";
import { Editor } from "@tinymce/tinymce-react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";
import { useNavigate, useParams } from "react-router-dom";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import AudioPlayer from 'react-h5-audio-player';
import { Delete as DeleteIcon } from '@mui/icons-material';
import ContentUploader from "./ContentUploader";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';



export default function Content_uploader() {
  const { id } = useParams();

  const [chapters, setChapters] = React.useState([]);

  const [thumbnailToDelete, setThumbnailToDelete] = React.useState("");
  const [videoToDelete, setVideoToDelete] = React.useState("");

  const [showContent, setShowContent] = React.useState(false);
  const [addChapter, setAddChapter] = React.useState(false);
  const [checked, setChecked] = React.useState([0]);
  const [error, setError] = React.useState({ video: "", image: "" });
  const [video, setVideo] = React.useState([]);

  const [chapterid, setChapterId] = React.useState("");
  const [lessonid, setLessonId] = React.useState("");

  const [selectedVideo, setSelectedVideo] = React.useState(null);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [vdotitle,setVdoTitle] = React.useState("")
  const [selectedLessonIndex, setSelectedLessonIndex] = React.useState(null);

  const [openDel, setOpenDel] = React.useState(false);

  const [lessonToDelete, setLessonToDelete] = React.useState(null);

  // State to manage additional inputs for thumbnails and videos
  const [submitOpen, setSubmitOpen] = React.useState(false);

  const [addbtn,setAddbtn] = React.useState(true)


  const [selectedAudio, setSelectedAudio] = React.useState(null);
  const [selectedAudioImage, setSelectedAudioImage] = React.useState(null);  
  const [audiotitle,setAudioTitle] = React.useState("")



  const [selectedFile,setSelectedFile] = React.useState(null)
  const [fileTitle,setFileTitle] = React.useState("")
  const [fileToDelete,setFileToDelete] = React.useState("")
  


  const [audiothumbnailtoDelete,setAudiothumbnailtoDelete] = React.useState(null)
  const [audioToDelete,setAudioToDelete] = React.useState(null)

  


  

  let navigate = useNavigate();

  React.useEffect(() => {
    getChapters();
  }, [id]);

  const getChapters = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + `Course1/GetCourse/${id}`
      );
      setChapters(response.data.course.chapters);
      setOpen(new Array(chapters.length).fill(false));
    } catch (error) {
      console.error(error);
    }
  };


  
  

  const initializeLessons = (chapters) => {
    return chapters.map(chapter => ({
      ...chapter,
      lessons: chapter.lessons.map(lesson => ({
        ...lesson,
        imgpreview: "", // Initialize for image preview
        imgraw: null,
        vdopvw: "", // Initialize for video preview
        vdoraw: null,
      })),
    }));
  };

  console.log(chapters);

  const [open, setOpen] = React.useState(chapters.map(() => true));

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleChange = (index) => (event, isExpanded) => {
    const newOpen = [...open];
    newOpen[index] = isExpanded;
    setOpen(newOpen);
  };

  const editorRef = React.useRef(null);

  const addContent = () => {
    setShowContent(true);
    setAddChapter(false);
  };

  const [activeItemId, setActiveItemId] = React.useState(null);

  const handleItemClick = (Lid, Cid) => {
    console.log(Lid, "landid");
    console.log(Cid, "ChuId");
    setLessonId(Lid);
    setChapterId(Cid);
    setActiveItemId(Lid);
    setShowContent(true);
  };

  // const [uploadedFiles, setUploadedFiles] = React.useState({});

  const handleImageChange = (e, cardItemIndex) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const updatedChapters = [...chapters];
      updatedChapters.forEach((chapter) => {
        chapter.lessons.forEach((lesson, index) => {
          if (index === cardItemIndex) {
            lesson.imgpreview = URL.createObjectURL(selectedFile);
            lesson.imgraw = selectedFile; // Store the raw file for upload
          }
        });
      });
      setChapters(updatedChapters);
      setSelectedImage(selectedFile); // Store the image file in state
      setSelectedLessonIndex(cardItemIndex); // Store the lesson index
      setError("");
    }
  };



  const handleVideoChange = (e, cardItemIndex) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const updatedChapters = [...chapters];
      updatedChapters.forEach((chapter) => {
        chapter.lessons.forEach((lesson, index) => {
          if (index === cardItemIndex) {
            lesson.vdopvw = URL.createObjectURL(selectedFile);
            lesson.vdoraw = selectedFile; // Store the raw file for upload
          }
        });
      });
      setChapters(updatedChapters);
      setSelectedVideo(selectedFile); // Store the video file in state
      setSelectedLessonIndex(cardItemIndex); // Store the lesson index
      setError("");
    }
  };

  // http://192.168.1.16:4000/Course1/uploadAudio/66ffbabfcb3708fcd245846c/66ffbad5cb3708fcd2458477/66ffbaeccb3708fcd245847c

  // BODY
  
  
  
  
  
  // audioName
  // audio
  // Audiothumbnail
  

  const uploadContentToBackend = async (
    courseId,
    chapterId,
    lessonId,
    videoFile,
    imageFile,
    videoName
  ) => {
    const url = `${process.env.REACT_APP_BASE_URL}Course1/uploadVideoThumbnail/${courseId}/${chapterId}/${lessonId}`;

    const formData = new FormData();
    if (videoFile) {
      formData.append("Video", videoFile);
    }
    if (imageFile) {
      formData.append("thumbnail", imageFile);
    }
    if (videoName) {
      formData.append("VideoName", videoName);
    }

    try {
      const response = await axios.put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log("Upload successful:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error uploading content:", error);
      throw error;
    }
  };

  const handleAddVideoClick = async () => {
    if (selectedVideo && selectedImage) {
      const videoFile = selectedVideo;
      const imageFile = selectedImage;
      const videoName = vdotitle;
      console.log(videoFile);
      console.log(imageFile);
      console.log(videoName);


      // const chapterId = chapters[0]._id; // Assuming you want the first chapter's ID
      // const lessonId = chapters[0].lessons[selectedLessonIndex]._id; // Get the lesson ID

      try {
        const response = await uploadContentToBackend(
          id,
          chapterid,
          lessonid,
          videoFile,
          imageFile,
          videoName
        );
        console.log("Files uploaded successfully:", response);
        // Clear selected files and reset state if needed
        setSelectedVideo(null);
        setSelectedImage(null);
        setSelectedLessonIndex(null);
        enqueueSnackbar("Thumbnail and Video Uploaded Successfully", {
          variant: "success",
        });

        getChapters();
      } catch (error) {
        console.error("Error during upload:", error);

        enqueueSnackbar("Failed to Upload Thumbnail and Video", {
          variant: "error",
        });
      }
    }
    if (!selectedImage) {
      setError({ image: "Image is required" });
      return;
    }
    if (!selectedVideo) {
      setError({ video: "Video is required" });
      return;
    }
  };


     const handleAudio = (e,cardItemIndex) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const updatedChapters = [...chapters];
      updatedChapters.forEach((chapter) => {
        chapter.lessons.forEach((lesson, index) => {
          if (index === cardItemIndex) {
            lesson.audioPreview = URL.createObjectURL(selectedFile);
            console.log(lesson.audioPreview)
              lesson.audioRaw = selectedFile; // Store the raw file for upload
          }
        });
      });
      setChapters(updatedChapters);
      // setSelectedVideo(selectedFile); // Store the video file in state

      setSelectedAudio(selectedFile)// Store the image file in state
      setSelectedLessonIndex(cardItemIndex); // Store the lesson index
      setError("");
    }
    }

    const handleAudioImageChange = (e, cardItemIndex) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        const updatedChapters = [...chapters];
        updatedChapters.forEach((chapter) => {
          chapter.lessons.forEach((lesson, index) => {
            if (index === cardItemIndex) {
              
              lesson.audioImagePreview = URL.createObjectURL(selectedFile);
              lesson.audioImageRaw = selectedFile; // Store the raw file for upload
            }
          });
        });
        setChapters(updatedChapters);
      // setSelectedImage(selectedFile); // Store the image file in state

        setSelectedAudioImage(selectedFile) // Store the image file in state
        setSelectedLessonIndex(cardItemIndex); // Store the lesson index
        setError("");
      }
    };


  const uploadAudioContentToBackend = async (
    courseId,
    chapterId,
    lessonId,
    audioFile,
    imageFile,
    audioName
  ) => {
    const url = `${process.env.REACT_APP_BASE_URL}Course1/uploadAudio/${courseId}/${chapterId}/${lessonId}`;

    const formData = new FormData();
    


    if (audioFile) {
      formData.append("audio", audioFile);
    }
    if (imageFile) {
      formData.append("Audiothumbnail", imageFile);
    }
    if(audioName){
      formData.append("audioName", audioName);
    }

    try {
      const response = await axios.put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log("Upload successful:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error uploading content:", error);
      throw error;
    }
  };

  const handleAddAudioClick = async () => {
    if (selectedAudio && selectedAudioImage) {
      const audioFile = selectedAudio;
      const imageFile = selectedAudioImage;
      const audioName = audiotitle;
      console.log(audioFile);
      console.log(imageFile);
      console.log(audioName)

      try {
        const response = await uploadAudioContentToBackend(
          id,
          chapterid,
          lessonid,
          audioFile,
          imageFile,
          audioName
        );
        console.log("Files uploaded successfully:", response);
        // Clear selected files and reset state if needed
        setSelectedVideo(null);
        setSelectedImage(null);
        setSelectedLessonIndex(null);
        enqueueSnackbar("Thumbnail and Video Uploaded Successfully", {
          variant: "success",
        });

        getChapters();
      } catch (error) {
        console.error("Error during upload:", error);


        enqueueSnackbar("Failed to Upload Thumbnail and Video", {
          variant: "error",
        });
      }
    }


    
  };


  const uploadAudioFileToBackend = async (
    courseId,
    chapterId,
    lessonId,
    File,
    fileName
  ) => {
    const url = `${process.env.REACT_APP_BASE_URL}Course1/uploadPdf/${courseId}/${chapterId}/${lessonId}`;

    const formData = new FormData();
    if (File) {
      formData.append("pdf", File);
    }
    if (fileName) {
      formData.append("pdfName", fileName);
    }
  

    try {
      const response = await axios.put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log("Upload successful:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error uploading content:", error);
      throw error;
    }
  };

  const handleAddFileClick = async () => {
    if (Files) {
      const file = Files[0].file;
      const fileName = fileTitle;
      console.log(file);
    
      console.log(fileName)

      try {
        const response = await uploadAudioFileToBackend(
          id,
          chapterid,
          lessonid,
          file,
          fileName
        
        );
        console.log("Files uploaded successfully:", response);
        // Clear selected files and reset state if needed
        setSelectedVideo(null);
        setSelectedImage(null);
        setSelectedLessonIndex(null);
        enqueueSnackbar("Thumbnail and Video Uploaded Successfully", {
          variant: "success",
        });

        getChapters();
      } catch (error) {
        console.error("Error during upload:", error);


        enqueueSnackbar("Failed to Upload Thumbnail and Video", {
          variant: "error",
        });
      }
    }


    
  };


  const handleSubmitClick = async ()=>{
    if (selectedAudio || selectedAudioImage) {
      console.log("called")
      handleAddAudioClick();
      setSelectedAudio(null);
      setSelectedAudioImage(null);


    }
    if (selectedVideo || selectedImage) {
      console.log("called")

      handleAddVideoClick();

    }
    if(Files.length > 0){
      console.log("called")
      handleAddFileClick()
      setFiles([])
    }
  }


  // Function to remove video
  const handleRemoveVideo = (e, index) => {
    setChapters((prevChapters) => {
      const updatedChapters = [...prevChapters];
      updatedChapters.forEach((chapter) => {
        chapter.lessons.forEach((lesson, lessonIndex) => {
          if (lessonIndex === index) {
            lesson.vdopvw = ""; // Clear video preview
            lesson.vdoraw = null; // Clear raw video file
            setSelectedVideo(null);
          }
        });
      });
      return updatedChapters;
    });
  };

  
  const handleRemoveAudio = (e, index) => {
    setChapters((prevChapters) => {
      const updatedChapters = [...prevChapters];
      updatedChapters.forEach((chapter) => {
        chapter.lessons.forEach((lesson, lessonIndex) => {
          if (lessonIndex === index) {
            lesson.audioPreview = ""; // Clear video preview
            lesson.audioRaw = null; // Clear raw video file
            // setSelectedVideo(null);
          }
        });
      });
      return updatedChapters;
    });
  };



  const handleRemoveAudioImage = (e, index)=>{
    setChapters((prevChapters) => {
      const updatedChapters = [...prevChapters];
      updatedChapters.forEach((chapter) => {
        chapter.lessons.forEach((lesson, lessonIndex) => {
          if (lessonIndex === index) {
            lesson.audioImagePreview = ""; // Clear video preview
            lesson.audioImageRaw = null; // Clear raw video file
            // setSelectedVideo(null);
          }
        });
      });
      return updatedChapters;
    });
  }


  
  const [Files,setFiles] = React.useState([])
  const [previousFiles, setPreviousFiles] = React.useState([]);
  const supportedFileTypes = ['pdf', 'doc', 'docx', 'txt'];


  const handleFileChange = (e,cardItemIndex) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const updatedChapters = [...chapters];
      updatedChapters.forEach((chapter) => {
        chapter.lessons.forEach((lesson, index) => {
          if (index === cardItemIndex) {
            
            lesson.filePreview = URL.createObjectURL(selectedFile);
            lesson.fileRaw = selectedFile; // Store the raw file for upload
          }
        });
      });
      setChapters(updatedChapters);
      
      setSelectedImage(selectedFile); // Store the image file in state
      setSelectedLessonIndex(cardItemIndex); // Store the lesson index
      setError("");
    }

    const selectedFiles = Array.from(e.target.files);
    const filesWithNames = selectedFiles.map((file) => ({
        name: file.name,
        size: file.size,
        file: file,
    }));

    const validFiles = filesWithNames.filter(file => {
        const extension = file.name.split('.').pop().toLowerCase();
        return supportedFileTypes.includes(extension);
    });

    const unsupportedFiles = filesWithNames.filter(file => !validFiles.includes(file));
    if (unsupportedFiles.length > 0) {
        enqueueSnackbar(`Invalid file ${unsupportedFiles.map(file => file.name).join(', ')}`, { variant: 'error' });
    }

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
};

const removeFile = (index) => {
  setSubmitOpen(false)
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
};

  // Function to reset thumbnail image
  const resetThumbnailImage = (e, index) => {
    setChapters((prevChapters) => {
      const updatedChapters = [...prevChapters];
      updatedChapters.forEach((chapter) => {
        chapter.lessons.forEach((lesson, lessonIndex) => {
          console.log(lessonIndex);
          console.log(index);
          if (lessonIndex === index) {
            lesson.imgpreview = ""; // Clear image preview
            lesson.imgraw = null; // Clear raw image file
            setSelectedImage(null);
          }
        });
      });
      setVdoTitle("")
      return updatedChapters;
    });
  };

  const handleDeleteClick = (lessonId, thumb, video) => {
    console.log(thumb);
    console.log(video);
    setLessonToDelete(lessonId);
    setOpenDel(true);
    setThumbnailToDelete(thumb);
    setVideoToDelete(video);
  };

  const handleDeleteAudioClick = (lessonId, thumb, audio) => {
    console.log(thumb);
    console.log(audio);
    setLessonToDelete(lessonId);
    setOpenDel(true);
    setAudiothumbnailtoDelete(thumb)
    setAudioToDelete(audio)
  };


  const handleDeleteFileClick = (lessonId,  file) => {
    console.log(lessonId);
    console.log(lessonId);
    setLessonToDelete(lessonId);
    setOpenDel(true);
    setFileToDelete(file)
  };

  const handleClose = () => {
    setOpenDel(false);
  };

  const deleteContentFromBackend = async (
    courseId,
    chapterId,
    lessonId,
    body
  ) => {
    const url = `${process.env.REACT_APP_BASE_URL}Course1/deleteVideoFromLesson/${courseId}/${chapterId}/${lessonId}`;

    try {
      console.log(body);
      const response = await axios.put(
        url,
        body
        //     {
        //     headers: {
        //         Authorization: localStorage.getItem("token")
        //     },
        // }
      );
      console.log("Delete successful:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error deleting content:", error);
      throw error;
    }
  };

  const handleConfirmDelete = async () => {
    const body = {
   
      videoPath: videoToDelete?.file[0],
      thumbnailPath: thumbnailToDelete.file[0],
    };
    if (lessonToDelete) {
      try {
        const response = await deleteContentFromBackend(
          id,
          chapterid,
          lessonToDelete,
          body
        );
        console.log("Content deleted successfully:", response);
        enqueueSnackbar("Thumbnail and Video Deleted Successfully", {
          variant: "success",
        });

        getChapters();
      } catch (error) {
        console.error("Error during deletion:", error);
        enqueueSnackbar("Failed To Delete Thumbnail and Video", {
          variant: "error",
        });
      }
    }
    setOpenDel(false);
  };


  const handleDeleteAudioContent = async (courseId, chapterId, lessonToDelete, videoToDelete, thumbnailToDelete) => {
    const url = `${process.env.REACT_APP_BASE_URL}Course1/deleteaudieo/${courseId}/${chapterId}/${lessonToDelete}`;
  
    const body = {

      audioPath: videoToDelete?.file[0],
      thumbnailPath: thumbnailToDelete.file[0],
    };
  
    try {
      console.log(body);
      const response = await axios.put(url, body);
      console.log("Delete successful:", response.data);
      
      enqueueSnackbar("Thumbnail and Video Deleted Successfully", {
        variant: "success",
      });
  
      getChapters();
      
      return response.data;
    } catch (error) {
      console.error("Error deleting content:", error);
      enqueueSnackbar("Failed To Delete Thumbnail and Video", {
        variant: "error",
      });
      throw error;
    } finally {
      setOpenDel(false);
    }
  };


  const handleDeleteFileContent = async (courseId, chapterId, lessonToDelete, fileTodelete) => {

    const url = `${process.env.REACT_APP_BASE_URL}Course1/deletepdf/${courseId}/${chapterId}/${lessonToDelete}`;
  
    const body = {
      pdfPath: fileTodelete?.file[0],
      
    };
  
    try {
      console.log(body);
      const response = await axios.put(url, body);
      console.log("Delete successful:", response.data);
      
      enqueueSnackbar("Thumbnail and Video Deleted Successfully", {
        variant: "success",
      });
  
      getChapters();
      
      return response.data;
    } catch (error) {
      console.error("Error deleting content:", error);
      enqueueSnackbar("Failed To Delete Thumbnail and Video", {
        variant: "error",
      });
      throw error;
    } finally {
      setOpenDel(false);
    }
  };
  
  // Example event handler
  const handleDelete= () => {
    if (audioToDelete) {
      console.log("called")
      handleDeleteAudioContent(id, chapterid, lessonToDelete, audioToDelete ,audiothumbnailtoDelete);
    }
    if (videoToDelete) {
      console.log("called")
      handleConfirmDelete();
    }
    if(fileToDelete){
      handleDeleteFileContent(id, chapterid , lessonToDelete, fileToDelete)
    }
  };



 


  // Function to add new inputs
  const handleAddNew = (chapterIndex, lessonIndex) => {
    setVdoTitle("")
    setAudioTitle('')
    setFileTitle("")
    setChapters(prevChapters => {
      const updatedChapters = [...prevChapters];
  
      // Check if the chapter and lesson indexes are valid
      if (updatedChapters[chapterIndex] && updatedChapters[chapterIndex].lessons[lessonIndex]) {
        // setSubmitOpen(true)
        
        // Ensure the lesson has the required fields if they don't already exist
        updatedChapters[chapterIndex].lessons[lessonIndex] = {
          ...updatedChapters[chapterIndex].lessons[lessonIndex],
          // imgpreview: updatedChapters[chapterIndex].lessons[lessonIndex].imgpreview || "", // Ensure this field is initialized
          // imgraw: updatedChapters[chapterIndex].lessons[lessonIndex].imgraw || null,
          // vdopvw: updatedChapters[chapterIndex].lessons[lessonIndex].vdopvw || "", // Ensure this field is initialized
          // vdoraw: updatedChapters[chapterIndex].lessons[lessonIndex].vdoraw || null,

        imgpreview:"",
        imgraw:null,
        vdopvw:"",
        vdoraw:null,
        audioImagePreview:"",
        audioImageRaw:null,
        audioPreview:"",
        audioRaw:null,
        filePreview:"",
        fileRaw:null
        };
        setAddbtn(false)
      }
  
      return updatedChapters;
    });
  };

  const handleAddNewVideo = (chapterIndex, lessonIndex) => {
    setChapters(prevChapters => {
      const updatedChapters = [...prevChapters];
  
      // Check if the chapter and lesson indexes are valid
      if (updatedChapters[chapterIndex] && updatedChapters[chapterIndex].lessons[lessonIndex]) {
        setSubmitOpen(true)
        // Ensure the lesson has the required fields if they don't already exist
        updatedChapters[chapterIndex].lessons[lessonIndex] = {
          ...updatedChapters[chapterIndex].lessons[lessonIndex],
          // imgpreview: updatedChapters[chapterIndex].lessons[lessonIndex].imgpreview || "", // Ensure this field is initialized
          // imgraw: updatedChapters[chapterIndex].lessons[lessonIndex].imgraw || null,
          // vdopvw: updatedChapters[chapterIndex].lessons[lessonIndex].vdopvw || "", // Ensure this field is initialized
          // vdoraw: updatedChapters[chapterIndex].lessons[lessonIndex].vdoraw || null,

        imgpreview:"",
        imgraw:null,
        vdopvw:"",
        vdoraw:null,
        };
      }
  
      return updatedChapters;
    });
  };

  
  const handleAddNewAudio = (chapterIndex, lessonIndex) => {
    setChapters(prevChapters => {
      const updatedChapters = [...prevChapters];
  
      // Check if the chapter and lesson indexes are valid
      if (updatedChapters[chapterIndex] && updatedChapters[chapterIndex].lessons[lessonIndex]) {
        setSubmitOpen(true)
        // Ensure the lesson has the required fields if they don't already exist
        updatedChapters[chapterIndex].lessons[lessonIndex] = {
          ...updatedChapters[chapterIndex].lessons[lessonIndex],
        audioImagePreview:"",
        audioImageRaw:null,
        audioPreview:"",
        audioRaw:null
       
        };
      }
  
      return updatedChapters;
    });
  };

  const handleAddNewFile = (chapterIndex, lessonIndex) => {
    setChapters(prevChapters => {
      const updatedChapters = [...prevChapters];
  
      // Check if the chapter and lesson indexes are valid
      if (updatedChapters[chapterIndex] && updatedChapters[chapterIndex].lessons[lessonIndex]) {
        setSubmitOpen(true)
        // Ensure the lesson has the required fields if they don't already exist
        updatedChapters[chapterIndex].lessons[lessonIndex] = {
          ...updatedChapters[chapterIndex].lessons[lessonIndex],
        filePreview:"",
        fileRaw:null
        };
      }
  
      return updatedChapters;
    });
  };
  
  


  const [idOfChapter,setIdOfChapter] = React.useState();
  const [titleOfChapter,setTitleOfChapter] = React.useState();
  const [idOfCourse,setIdOfCourse] = React.useState();
  const [indexOfChapter,setIndexOfChapter] = React.useState();
  const [indexOfLesson,setIndexOfLesson] = React.useState();
  // state for lesson component open.
  const [selectedLesson, setSelectedLesson] = React.useState(null);
  const [selectedLessonOpen, setSelectedLessonOpen] = React.useState(null);

  
  const [selectedIndex, setSelectedIndex] = React.useState(null);


  const handleLessonClick = (chapterIndex, lessonIndex) => () => {
    setIdOfChapter(chapters[chapterIndex]._id)
    setTitleOfChapter(chapters[chapterIndex].title)
    setIdOfCourse(id)
    setIndexOfChapter(chapterIndex)
    setIndexOfLesson(lessonIndex)
    const lesson = chapters[chapterIndex].lessons[lessonIndex];
    setSelectedLesson({
      ...lesson,
      chapterTitle: chapters[chapterIndex].title, // Add chapter title for context
      chapterId:chapters[chapterIndex]._id,
      courseId:id
    });
    setSelectedLessonOpen(true); // Assuming you want to show the uploader
    setSelectedIndex({ chapterIndex, lessonIndex });
  };







  
  


  return (
    <div className="curriculumPage">
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={3}>
          <Box className="chapterlist">
            {chapters.length > 0 &&
              chapters.map((item, chapterIndex) => {
                console.log(item.lessons.length==0,"datassa");
                
                return(
                <Accordion
                  key={chapterIndex}
                  className="mb-20"
                  expanded={open[chapterIndex]}
                  onChange={handleChange(chapterIndex)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${item._id}-content`}
                    id={`panel${item._id}-header`}
                  >
                    <Typography className="fz-16 text_black semibold">
                      {chapterIndex >= 0
                        ? "Chapter " + `${chapterIndex+1}` + ": " + item.title
                        : item.title}
                    </Typography>

                   
                  </AccordionSummary>
                  <AccordionDetails>
                   

                    <List className="mb-10">

                   
                      {item.lessons.length>0?(
                      
                      item.lessons.map((cardItem,lessonIndex) => 
                      { 
                        console.log(cardItem,"lesson");
                        
                      return (
                        <ListItem
                          key={cardItem._id}
                          // className="eachcard mb-10 flex-col align-items-start"
                          // onClick={handleLessonClick(chapterIndex, lessonIndex)}
                          className={`eachcard mb-10 flex-col align-items-start ${selectedIndex?.lessonIndex === lessonIndex && selectedIndex.chapterIndex === chapterIndex ? 'selected' : ''}`}
                        onClick={handleLessonClick(chapterIndex, lessonIndex)}
                        style={{
                          backgroundColor: selectedIndex?.lessonIndex === lessonIndex && selectedIndex.chapterIndex === chapterIndex ? '#fdf5e6' : 'transparent', // Change this to your desired color
                        }}
                        >
                          <ListItemText primary={cardItem.title} />
                          <div style={{
                            display:"flex"
                          }}>
                          <PictureAsPdfIcon className="text-grey"/>
                          <AudiotrackIcon className="text-grey"/>
                          <Videocam className="text-grey" />
                          </div>

                      
                          
                        </ListItem>






                        
                      ) })
                    ):(" Add Lesson To Upload Your Content")
                  }
                     
                    </List>
                  </AccordionDetails>
                </Accordion>
              )}
              )}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={8}>
          {chapters.length <= 0 && (
            <Box className="h-100p whiteContainer flex-col flex-center justify-content-center">
              <Typography
                component="h5"
                variant="h5"
                className=" text_black semibold"
              >
               Add Chapter And Lesson To Upload Your Content 
              </Typography>
            </Box>
          )}


          
          {/* {showContent ? */}

          {/* 
          const [indexofChapter,setIndexOfChapter] = React.useState();
  const [titleOfChapter,setTitleOfChapter] = React.useState();
  const [idOfCourse,setIdOfCourse] = React.useState();
          */}

            <Box>
            {selectedLesson && ( <ContentUploader  
            titleOfChapter={titleOfChapter}
            idOfCourse={idOfCourse}  
            idOfChapter={idOfChapter}
            indexOfChapter={indexOfChapter}
            indexOfLesson={indexOfLesson}
            /> )}
            </Box>
        </Grid>
      </Grid>

      <Dialog
        open={openDel}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="custom_modal"
      >
        <DialogTitle>
          {videoToDelete ? `Would you like to delete this Thumbnail and video?`: audioToDelete ? `Would you like to delete this Thumbnail and Audio?`:`coming`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} className="cancel_btn">
            No
          </Button>
          <Button onClick={handleDelete} className="theme-btn">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
