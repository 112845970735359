import React, { useEffect } from 'react'
import { Box, FormControlLabel, Switch, Table, TableBody,  TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';






const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const QnATable = () => {

  const navigate = useNavigate()
const auth= JSON.parse(localStorage.getItem("userData"));

const [total, setTotal] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [loading, setLoading] = React.useState(true); 
    const [question, setQuestion] = React.useState([]);

   

    useEffect(() => {
      getQuestion();
    }, [page, rowsPerPage]); // Fetch questions when page or rowsPerPage changes

 // Fetch questions from the API with pagination
 const getQuestion = () => {
  setLoading(true);
  axios
    .get(process.env.REACT_APP_BASE_URL + `Questions/GetQuestionsToteacher/${auth._id}`, {
      params: {
        page: page + 1,
        limit: rowsPerPage,
      },
    })
    .then((res) => {
      setQuestion(res.data.questions);  // Set the fetched questions
      setTotal(res.data.totalQuestions); // Set the total count
      
    })
    .catch((err) => {
      console.log(err, "error in CourseTable");
      
    }).finally(()=>{
      setTimeout(() => {
        setLoading(false)
    }, 1500);
    })
};





      // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage); // Update the current page
    getQuestion(newPage, rowsPerPage); // Fetch data for the new page
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10); // Get the new rows per page
    setRowsPerPage(newRowsPerPage); // Update the rows per page state
    setPage(0); // Reset to the first page when rows per page change
    getQuestion(0, newRowsPerPage); // Fetch data for the first page with new rows per page
  };

    function capitalizeFirstLetter(string) {
     
        if (!string) return string; // Check for empty string
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


      const navigateToViewPage = (row)=>{
        navigate(`/QuestionandAnswerView/${row._id}`, { state: row })
      }


  return (
    <>
      <Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <TableHead className="DT-head">
              <TableRow className="">
                <TableCell
                  align="left"
                  className="DT-checkbox TRow semibold"
                  style={{ minWidth: 200, padding: "6px 16px" }}
                >
                  Question
                </TableCell>
                {/* <TableCell align="left" className='DT-checkbox TRow semibold' style={{ minWidth: 200, padding: '6px 16px' }}>Mega Event</TableCell> */}
                <TableCell
                  align="center"
                  className="DT-checkbox TRow semibold"
                  style={{ minWidth: 200, padding: "6px 16px" }}
                >
                  Asked By
                </TableCell>
                <TableCell
                  align="center"
                  className="DT-checkbox TRow semibold"
                  style={{ minWidth: 200, padding: "6px 16px" }}
                >
                  Answers Count
                </TableCell>

                <TableCell
                  align="center"
                  className="DT-checkbox TRow semibold"
                  style={{ minWidth: 200, padding: "6px 16px" }}
                >
                  Answers
                </TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableBody className="characters">
                <TableRow>
                  <TableCell colSpan={12} align="center" className="DT-row">
                    <CircularProgress sx={{ color: "#f4733e" }} />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody className="characters">
                {question && question.length > 0 ? (
                  question.map((row, i) => (
                    <TableRow key={i}>
                      {row && row.title ? (
                        <TableCell
                          className="DT-row"
                          align="left"
                          style={{ minWidth: 200 }}
                        >
                          {row.title.length > 150
                            ? row.title.slice(0, 150) + "..."
                            : row.title}
                        </TableCell>
                      ) : (
                        <TableCell className="DT-row" style={{ minWidth: 200 }}>
                          -
                        </TableCell>
                      )}

                      {row && row.AskedBy && (
                        <StyledTableCell align="center" className="DT-row">
                          <Typography component="div">
                            {capitalizeFirstLetter(row.AskedBy.username)}
                          </Typography>
                        </StyledTableCell>
                      )}

                      <StyledTableCell align="center" className="DT-row">
                        {row.answersCount}
                      </StyledTableCell>

                      <StyledTableCell align="center" className="DT-row">
                        <Tooltip title="Provide Answer " arrow>
                                            <ChatBubbleIcon onClick={() => navigateToViewPage(row)} className="fz-18 mr-5" />

                         
                        </Tooltip>
                      </StyledTableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell className="DT-row" align="center" colSpan={5}>
                      No Data Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 15, 20]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="DT-pagination"
        />
      </Box>
    </>
  );
}

export default QnATable