import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import MiniDrawer from '../../../../../components/drawer'
import DataTable from './Datatable_Job'

export default function Category() {
    let navigate = useNavigate()
    // const role = JSON.parse(localStorage.getItem("roledata"))
    const params = useParams()
    

    const addjobdetails = () => {
        navigate(`/teachers/details/career/addjobdetails/${params.id}`);
    }
    return (
        <Box className='companies'>
            <MiniDrawer menuOrder={12} />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Job Details</Typography>
                    <Button variant="contained" className='addbtn' onClick={() => addjobdetails()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Job Details</Button>
                    </Box>
                <Box className='whiteContainer'>
                    <DataTable />
                </Box>
            </Box>
        </Box>
    )
}