import React, { useEffect, useRef, useState } from "react";
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Sidebar from '../Sidebar'
import MiniDrawer from '../../../../components/drawer'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, MenuItem, Checkbox, ListItemText, TextField, Select, OutlinedInput, Typography, Input } from '@mui/material'
import { Close } from '@mui/icons-material';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Add_Video from './EditVideo'
import Edit_Video from './EditVideo'


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#E80A89',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#E80A89',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function Service_Steppyform() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [videos, setVideos] = useState([])
    const [community, setCommunity] = React.useState([])
    const [error,setError] = useState({video:""});
    const [videoAddError, setVideoAddError] = useState("");
    const [user, setUser] = React.useState([])
    const params = useParams()
    const [addVideoFrom, setaddVideoFrom] = useState('Community')
    const[truebutton,setTruebutton]=React.useState(false)
  

    const [communityData, setCommunityData] = useState(null);

	useEffect(() => {
     
        const communityDataString = localStorage.getItem('communityData');
        if (communityDataString) {
            setCommunityData(JSON.parse(communityDataString));
        }
    }, []);

	
    const validatenew = () => {
        //  if (videos?.length === 0) {
        // 	setError({ video: "This field is required." })
        // 	return false
        // }
        // if (!videos?.imgraw  && !videos?.vdoraw ) {
        // 	setError({ video: "This field is required." })
        // 	return false
        // }
        // Validate if either thumbnail or video is missing
		if (videos.length === 0) {

			setVideoAddError("Thumbnail and Video fields are required.");
			return false;
		}
		if (videos.some(video => !video.imgraw)) {
            
			setVideoAddError("Thumbnail is required.");
			return false;
		}
		if (videos.some(video => !video.vdoraw)) {
			setVideoAddError("Video is required.");
			return false;
		}
        
		return true;
        // if (state2?.length == 0) {
        // 	setImageAddError("This field is required.")
        // 	return false
        // }
       
        
    }

    const handleAddVideo = () => {
        const imgrawArray = videos.map(video => video.imgraw);
        const vdorawArray = videos.map(video => video.vdoraw);
        var valid = validatenew();

        if (valid === true) {
            setTruebutton(true)
            const formData = new FormData();
            formData.append('createdby[]', communityData?._id);
            formData.append('addVideoFrom',addVideoFrom )
            formData.append('community_id[]', communityData?._id)
            // formData.append('user_id', JSON.stringify(selecttedUsers.length > 0 ? selecttedUsers : []))
            // Assuming selecttedUsers is an array of user IDs
            { imgrawArray ? imgrawArray.forEach((item) => { formData.append("thumbnail", item) }) : formData.append("thumbnail", '') }
            { vdorawArray ? vdorawArray.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '') }
            console.log(...formData, "formDataformData");
            axios.post(process.env.REACT_APP_BASE_URL + `video/addVideo`, formData, { headers: headerformdata })
                .then((res) => {
                    navigate(`/community/community-details/videotable/${params.id}`);
                    enqueueSnackbar("Video added successfully", { variant: 'success' })
                    console.log(res, "added");
                })
                .catch((err) => {
                    enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
                    console.log(err, "error");
                })
                .finally(()=>{
                    setTruebutton(false)
                })
        }
    }

    const handleparent = (value) => {
        const newData = value.map(obj => ({
            "imgraw": obj.imgraw,
            "vdoraw": obj.vdoraw
        }));
        setVideos(newData);

    }

    return (
        <Box className='withSubmenu'>
            <MiniDrawer menuOrder={7} />
            <Sidebar subtype={6} />
            <Box component="section" className="contentWraper">
                <Box className=''>
                    <Typography component='h6' className='formTitle'>Add Videos</Typography>

                    <Box className="whiteContainer mt-20 mb-10 p-20">
                        <Box className=" mb-10">
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                <label className='form-labels ' for="my-input" >Videos <Typography component='span' className='star'> * </Typography></label>
                                <Box className="mt-20">
                                    <Add_Video parentFunc={handleparent} setVideoAddError={setVideoAddError} onChange={()=>{
                                        setVideoAddError("")
                                    }} />
                                    {videoAddError && <span className="error-message" style={{color:"red"}}>{videoAddError}</span>}
                                </Box>
                            </Grid>
                        </Box>
                        <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                            {
                                truebutton?
                                <Button variant="contained" className='theme-btn'>Please wait...</Button>
                                :
                                <Button onClick={handleAddVideo} variant="contained" className='theme-btn'>Submit</Button>
                            }
                      
                    </Box>
                    </Box>

                  
                </Box>
            </Box >
        </Box >
    )
}

export default Service_Steppyform;
