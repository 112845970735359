import React, { useState } from 'react'
import { Box, Button, Grid, IconButton, Stack, TextField, Typography, styled, Divider, CircularProgress } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import Avatar from '../../../../../assets/images/Avatar.png';
import axios from 'axios';
import { Pagination, Autoplay } from 'swiper/modules';
import moment from 'moment';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import playBtn from '../../../../../assets/images/play.png'
import CloseIcon from '@mui/icons-material/Close';
import videoPoster from '../../../../../assets/images/TeacherBanner.png'
import yoga2 from '../../../../../assets/images/Jobsbanner.png'
// import Header from '../../../Home/Header';
// import Menu from '../../../Menu';
// import Footer from '../../../Home/Footer';
import Spacing from '../../../../../components/Spacing';
import Comment_section from './Comment_section';
import { MdDelete } from "react-icons/md";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';


export default function Fans_page() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const location = useLocation()
    const params = useParams()
    const [open, setOpen] = React.useState(false);
    const [postid, setPostId] = React.useState('')
    const [isActive, setIsActive] = React.useState(false);
    const [isActive2, setIsActive2] = React.useState(false);
    const [isActive3, setIsActive3] = React.useState(false);
    const [timeline, setTimeline] = React.useState([])
    const [newdata, setNewdata] = React.useState([])
    const [fansPage, setFansPage] = React.useState([])
    const [fans_id, setFans_id] = React.useState([])
    const currentURL = window.location.href;
    const hasMultipleImages = currentURL.includes("multiple-images");
    const [videourl, setVideoUrl] = React.useState('')
    const [openvideo, setOpenVideo] = React.useState(false)
    const [loading, setLoading] = React.useState(true);



    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));


    //
    React.useEffect(() => {
        getFans()
    }, [])

    const getFans = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `FanPost/GetPostbyfanpageId/${params.id}`, { headers: headerlocal });
            setNewdata(response.data.data);
            setLoading(false)
        } catch (error) {
            console.log(error, "error in service get");
            setLoading(false)
        }
    }



    React.useEffect(() => {
        getFansPage();
    }, [fans_id]);

    const getFansPage = () => {
        if (fans_id.length > 0) {
            axios.get(process.env.REACT_APP_BASE_URL + `Fanpagecomment/getCommentsByFanPageByIds/${fans_id}`)
                .then((res) => {
                    setFansPage(res.data.data);
                })
                .catch((error) => {
                    console.log(error, "error in Timeline");
                });
        }
    }

    const ConfirmDelete = (id) => {
        setPostId(id)
        setOpen(true);
    };
    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleDeletePost = (id) => {
        axios.put(process.env.REACT_APP_BASE_URL + `FanPost/DeletePost/${id}`)
            .then((res) => {
                setOpen(false)
                enqueueSnackbar('Post deleted successfully', { variant: 'success' })
                getFans()
            })
            .catch((error) => {
                console.log(error, "error in DeletePost");
            })
    }

    //To Open & Close Video Modal:
    const handleOpenModal = (url) => {
        setVideoUrl(url)
        setOpenVideo(true);
    };

    const CloseVideoModal = () => {
        setOpenVideo(false)
        setVideoUrl('')
    }


    return (
        <Box className='community_details fanTimeline mt-30 mb-30'>
            {/* <Header /> */}
            {/* <Menu /> */}
            
             {loading ? (
                <Box className="loading-spinner" display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress style={{ color: '#fab452' }} />
                </Box>
            ) : (
                // If data is available, display the content
                newdata && newdata.length > 0 ? newdata.map((data) => (
                    <Box className="timeline_box" key={data._id}>
                        <Box className="container ">
                            <Box className="video_banner">
                                {data.additionalimages.length > 0 ? (
                                    <Swiper
                                        spaceBetween={30}
                                        pagination={{ clickable: true }}
                                        autoplay={{ delay: 5000, disableOnInteraction: false }}
                                        modules={[Pagination, Autoplay]}
                                        className="mySwiper p-0"
                                    >
                                        {data.additionalimages.map((img, imgIndex) => (
                                            <SwiperSlide key={imgIndex}>
                                                <img src={process.env.REACT_APP_BASE_URL + img} className='w100' alt={`slide-${imgIndex}`} />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                ) : (
                                    <Swiper>
                                        <SwiperSlide>
                                            <React.Fragment>
                                                <video
                                                    loop
                                                    muted
                                                    width='100%'
                                                    poster={process.env.REACT_APP_BASE_URL + data.image}
                                                ></video>
                                                {data.Video ? <Button disableRipple onClick={() => handleOpenModal(data.Video[0])} className='playbtn'>
                                                    <img src={playBtn} alt="Play Button" />
                                                </Button> : null}
                                            </React.Fragment>
                                        </SwiperSlide>
                                    </Swiper>
                                )}
                            </Box>
                        </Box>
                        <Box className='p-20'>
                            <Box className='d-flex mb-20 flex-wrap'>
                                <img src={data.fan_page_id?.profile ? process.env.REACT_APP_BASE_URL + data.fan_page_id?.profile : 'path/to/default-avatar.jpg'} width={60} className='mr-10 userImg' alt="User" />
                                <Box>
                                    <Typography component='h6' variant='body1' className='fz-14 text_black semibold'>
                                        {data.fan_page_id?.name}
                                    </Typography>
                                    <Typography component='span' variant='body1' className='fz-14 text_grey fw-400'>
                                        {moment(data.createdAt).fromNow()} <i className='fa-solid fa-earth-americas'></i>
                                    </Typography>
                                </Box>
                                <MdDelete style={{ marginLeft: '900px' }} onClick={() => ConfirmDelete(data._id)} />
                            </Box>
                            <Typography component='span' variant='body1' className='fz-14 text_black letter_sp fw-400'>
                                {data.short_description}
                            </Typography>
                        </Box>
                        <Divider />
                        <Box>
                            <Box className="p-20">
                                <i className="fa-solid fa-thumbs-up text_primary fz-18 mr-10"></i>
                                {data.likes ? data.likes.length : '0'}
                                <Button disableRipple className='likebtn btn text_black letter_sp mr-20'>
                                    <i className="fa-regular fa-message text_primary fz-16 mr-10"></i>{data.totalCommentsAndReplies}
                                </Button>
                            </Box>
                            <Box className={isActive3 ? 'comment-collapse' : 'comment-collapse comment-close'}>
                                <Comment_section data={data} getTimeLine={getFans} />
                            </Box>
                        </Box>
                    </Box>
                )) : (
                    <Box className="text-center">
                        <p className='text-center semibold'>No data found</p>
                    </Box>
                )
            )}


            <Dialog open={open} keepMounted
                onClose={handleCloseModal}
                aria-describedby="alert-dialog-slide-description"
                className='custom_modal'>
                <DialogTitle>Would you like to delete this post?</DialogTitle>
                <DialogActions>
                    <Button onClick={() => handleCloseModal()} className='cancel_btn'>No</Button>
                    <Button onClick={() => handleDeletePost(postid)} className='theme-btn'>Yes</Button>
                </DialogActions>
            </Dialog>

            {/* To Play Video in Modal View  */}
            <BootstrapDialog
                onClose={CloseVideoModal}
                aria-labelledby="customized-dialog-title"
                open={openvideo}
                fullWidth
                maxWidth="xl"
                className='fullscreen_modal'
            >
                <IconButton
                    aria-label="close"
                    onClick={CloseVideoModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'white',
                        zIndex: 1
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Box>
                    <iframe width="100%" height="600px" src={process.env.REACT_APP_BASE_URL + `${videourl}`} frameborder="0" allowfullscreen allow='autoplay'></iframe>
                </Box>
            </BootstrapDialog>
        </Box>

    )
}