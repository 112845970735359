
import axios from 'axios';
import * as React from 'react';
import { useCommunity } from "../Context/CommunityContext";
import { useSnackbar } from 'notistack'
import { SearchOutlined, Delete as DeleteIcon, Check } from '@mui/icons-material';
import { AiOutlineInfoCircle } from 'react-icons/ai'; // Example icon from React Icons
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, CircularProgress, IconButton, Tooltip, Card, Checkbox, Link, ListItemText, OutlinedInput, Input, Breadcrumbs, TableContainer, Select, TablePagination, MenuItem, Dialog, Typography, Grid, FormControl, TextField, DialogContent, DialogActions, DialogTitle, styled, CardContent, AccordionDetails, AccordionSummary, Accordion, Divider, RadioGroup, Radio, InputAdornment, Tabs, Tab, Avatar } from '@mui/material';


export default function DataTable({ params }) {
    const { communityAttachments, fetchCommunity } = useCommunity();
    const [previousFiles, setPreviousFiles] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const[Tableloading,setTableloading]=React.useState(true)
    const[truebutton,setTruebutton]=React.useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    };

    const [communityData, setCommunityData] = React.useState(null);

	React.useEffect(() => {
      get()
    }, []);

    const get=()=>{

        setTableloading(true)
       
        const communityDataString = localStorage.getItem('communityData');
        if (communityDataString) {
            setCommunityData(JSON.parse(communityDataString));
            setTableloading(false)
        }
    }


	console.log(params, 'paramschild1');
	console.log(communityData)

    const supportedFileTypes = ['pdf', 'doc', 'docx', 'txt'];

    const deleteFile =async (filepath) => {
        if (!filepath) {
            console.error("Filepath is undefined or empty.");
            return;
        }
        console.log(filepath)

        const deleteData = {
            filepath: filepath
        };

       await axios.put(
            `${process.env.REACT_APP_BASE_URL}community/deleteattachments/${communityData?._id}`,
            deleteData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('token')
                }
            }
        )
            .then((res) => {
                console.log(res.data)
                enqueueSnackbar("File deleted successfully", { variant: 'success' });
                fetchCommunity(communityData?._id);
                localStorage.setItem('communityData', JSON.stringify(res.data.deleteattachment));
                setCommunityData(res.data.deleteattachment)
            })
            .catch((err) => {
                enqueueSnackbar(
                    err.response && err.response.data.error ? err.response.data.error : "Failed to delete file.",
                    { variant: 'error' }
                );
                console.error(err);
            });
    };

    React.useEffect(() => {
        fetchCommunity(communityData?._id);
    }, [communityData?._id]);

    
    React.useEffect(() => {
        setPreviousFiles(communityAttachments);
    }, [communityAttachments]);

    console.log(previousFiles)

    // const handleFileChange = (e) => {
    //     const selectedFiles = Array.from(e.target.files);
    //     const filesWithNames = selectedFiles.map((file) => ({
    //         name: file.name,
    //         size: file.size,
    //         file: file,
    //     }));
    //     setFiles((prevFiles) => [...prevFiles, ...filesWithNames]);
    // };

    // const handleFileChange = (e) => {
    //     const selectedFiles = Array.from(e.target.files);

    //    console.log(selectedFiles)
    //     let filesWithNames = [];

    //     if (selectedFiles.length > 0) {
    //       filesWithNames = selectedFiles.map((file) => ({
    //         name: file.name,
    //         size: file.size,
    //         file: file,
    //       }));
    //     }else{
    //         enqueueSnackbar(`Attachment is Missing`, { variant: 'error' });
    //     }
        
        

    //     const validFiles = filesWithNames?.filter(file => {
    //         const extension = file.name.split('.').pop().toLowerCase();
    //         return supportedFileTypes.includes(extension);
    //     });

    //     const unsupportedFiles = filesWithNames.filter(file => !validFiles.includes(file));
    //     if (unsupportedFiles.length > 0) {
    //         enqueueSnackbar(`Invalid file ${unsupportedFiles && unsupportedFiles?.map(file => file.name).join(', ')}`, { variant: 'error' });
    //     }

    //     setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    // };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
    
        console.log(selectedFiles);
        let filesWithNames = [];
    
        if (selectedFiles.length > 0) {
            filesWithNames = selectedFiles.map((file) => ({
                name: file.name,
                size: file.size,
                file: file,
            }));
        } else {
            enqueueSnackbar(`Attachment is Missing`, { variant: 'error' });
        }
    
        // List of supported file extensions
        const supportedFileTypes = [
            'jpg', 'jpeg', 'png', 'gif', 'bmp',   // Images
            'mp4', 'avi', 'mkv',                   // Videos
            'mp3', 'wav', 'ogg',                   // Audios
            'txt',                                 // Text
            'xlsx',                                // Excel files
            'pdf',                                 // PDF
            'doc', 'docx',                         // Word Documents
        ];
    
        // Filter valid files based on the supported file types
        const validFiles = filesWithNames.filter(file => {
            const extension = file.name.split('.').pop().toLowerCase();
            return supportedFileTypes.includes(extension);
        });
    
        // Filter unsupported files
        const unsupportedFiles = filesWithNames.filter(file => 
            !validFiles.some(validFile => validFile.name === file.name)
        );
    
        if (unsupportedFiles.length > 0) {
            enqueueSnackbar(`Invalid file ${unsupportedFiles.map(file => file.name).join(', ')}`, { variant: 'error' });
        }
    
        // Add valid files to the state
        setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    };
    
    

    console.log(files)
   

   
    console.log(previousFiles, 'previousFiles');
    const saveFilesToServer = () => {
        // const filesArray = Array.isArray(files) ? files : [files];
        // const formData = new FormData();
        // filesArray.forEach((file, index) => {
        //     formData.append(`files`, file.file);
        //     formData.append(`name_${index}`, file.name);
        //     formData.append(`size_${index}`, (file.size) / 1024);
        // });
       
        // if(files.length <= 0) return


const formData = new FormData();

// Check if files array exists and has elements
if (files && files.length > 0) {
    files.forEach((file, index) => {
        formData.append(`files`, file.file);
        formData.append(`name_${index}`, file.name);
        formData.append(`size_${index}`, (file.size) / 1024);
    });
}

// Handle previous files
if (previousFiles && previousFiles.length > 0) {
    previousFiles.forEach((item) => {
        formData.append("ExistingFiles", JSON.stringify(item));
    });
} else {
    formData.append("ExistingFiles", '');
    setTruebutton(true)
}
        axios.post(process.env.REACT_APP_BASE_URL + `community/updatecommunityFile/${communityData?._id}`, formData, { headers: headerformdata })
            .then((res) => {
                enqueueSnackbar("Community updated successfully", { variant: 'success' });
                setFiles([]);
                fetchCommunity(communityData?._id);
                localStorage.setItem('communityData', JSON.stringify(res.data.updateFile));
                setCommunityData(res.data.updateFile); 
            })
            .catch((err) => {
                enqueueSnackbar( "Something went wrong, Check The File", { variant: 'error' });
                console.log(err, "error");
            }).finally(()=>{
                setTruebutton(false)
            })
    };
    const removeFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    console.log(communityData)

    return (
        <div className="contentWraper">
            <Box className="whiteContainer newmerchant mb-20">
                <Typography component='h6' className='formTitle'>Select Files</Typography>

                <Grid container className='mb-20 '>
                    <Grid item sm={12} md={12} className="w-100">
                        <Box className="form-box staff_list">
                            <Grid container columnSpacing={2} rowSpacing={2}>
                            {Tableloading?
                            (
                                <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ height: '100%' }}
                                  >
                                    <Grid item>
                                      <CircularProgress sx={{ color: '#F3714D' }} />
                                    </Grid>
                                  </Grid>

                            )
                            :
                            
                            (communityData  && communityData.files?.length > 0 ? communityData.files.map((file, index) => (
                                    <Grid item sm={12} md={12} lg={4} className="w-100 d-flex" key={index}>
                                        <Box
                                            key={file._id}
                                            // onClick={() => habdleSelectEvent(e, file, index)}
                                            className={file.selectEvent ? "selected_staff staff_card" : "staff_card"}
                                        >
                                            <Check className="checksvg"/>
                                            <Avatar className='mr-10' >
                                                {file.name && file.name.substring(0, 1)}
                                            </Avatar>
                                            
                                            <Box>
                                                <Box className='fz-16 semibold'>{file.name}</Box>
                                                {/* {category && category?.length > 0 ? category.map((data) => (
                                                    <Box className="fz-13" value={data._id}>{data.category_title}</Box>)) : <Box disabled>{"No Record's found"}</Box>} */}
                                            </Box>
                                                    
                                            <div style={{
                                                position: 'absolute',
                                                right:"0px"
                                            }}>
                                            <DeleteIcon className='ml-10 fz-20 text-grey' onClick={() => deleteFile(file.file)} />
                                            </div>

                                                   
                                        </Box>
                                    </Grid>
                                )) : (
                                    <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ height: '100%' }}
                                  >
                                    <Grid item>
                                     <Typography> No Files found</Typography>
                                    </Grid>
                                  </Grid>
                                ))}
                            </Grid>
                        </Box>

                    </Grid>
                </Grid>
                <Box className="text-right ">
                    {/* {buttondisable === false ? <Button variant="contained" onClick={handleUpdateCommunity} */}
                        {/* className='theme-btn mt-20'>Submit</Button> : <Button variant="contained" className='theme-btn mt-20'>Please wait</Button>} */}
                </Box>
            </Box>
            {/* <FileUploadOutlinedIcon className="fz-18 mr-5" />
            <input type="file" multiple onChange={handleFileChange} /> */}
            <Box className="mb-20 whiteContainer p-20" >
                <Box sx={{display:'flex',flexDirection:'row',}}>
                    <Typography component='div'  className='mb-20' sx={{marginTop:'6px',fontWeight:'200px'}}>Add Files</Typography>
                    <Tooltip sx={{marginLeft:'0px',marginBottom:'20px'}} title="This name will be used to create a unique URL for your community">
                        <IconButton aria-label="information">
                          <AiOutlineInfoCircle size={20} />
                        </IconButton>
                      </Tooltip>
                </Box>
          
                <FormControl variant="standard" fullWidth>
                    <Box className='flex-center flex-between flex-wrap w100'>
                        <Button className="upload_btn" variant="contained" component="label">
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <FileUploadOutlinedIcon className="fz-18 mr-5" />
                                Add Files
                       
                            </Box>
                        
                          
                            <input onChange={handleFileChange} multiple hidden type="file" />
                        </Button>
                    </Box>
                </FormControl>
                  <Typography className=' fz-12 mt-10 mb-20' component='p' variant='p'><strong>Note: </strong>Only pdf, doc, docx and txt files are supported </Typography>
          
            <ul className='p-0'>
                {files && files.length>0 && files.map((file, index) => (
                    <li key={index} className='flex-center fz-16 text_black'>
                        {file.fileName ? file.fileName : file.name}
                        {/* <button onClick={() => removeFile(index)}>Remove</button> */}
                        {/* <button onClick={() => deleteFile(file.file)}>Delete</button> */}
                        <DeleteIcon className='ml-10 fz-20 text-grey' sx={{cursor:"pointer"}} onClick={() => removeFile(index)} />
                    </li>
                ))}
            </ul>
            {/* <button onClick={() => saveFilesToServer()}>Submit</button> */}
            <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                {
                    truebutton?
                    <Button variant="contained" className='addbtn mr-10' >Please wait...</Button>
                    :
                    <Button variant="contained" className='addbtn mr-10' onClick={() => saveFilesToServer()}>Submit</Button>
                }
               
            </Box>
            </Box>
        </div>
    );
}

