import React from 'react'
import { Box, Button, Divider, Typography, FormControlLabel, RadioGroup, Radio, styled, Grid, FormControl, TextField, Select, MenuItem } from '@mui/material';
import banner from './../../../../../../../assets/images/course_pricing.jpg'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useCallback } from 'react';
import debounce from 'lodash.debounce';






function BpRadio(props) {
	return (
		<Radio
			sx={{
				'&:hover': {
					bgcolor: 'transparent',
				},
			}}
			disableRipple
			color="default"
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			{...props}
		/>
	);
}

const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: '50%',
	width: 16,
	height: 16,
	boxShadow:
		theme.palette.mode === 'dark'
			? '0 0 0 1px rgb(16 22 26 / 40%)'
			: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
	backgroundImage:
		theme.palette.mode === 'dark'
			? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
			: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	'.Mui-focusVisible &': {
		outline: '2px auto rgba(19,124,189,.6)',
		outlineOffset: 2,
	},
	'input:hover ~ &': {
		backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background:
			theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
	},
}));

const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: '#f3714d',
	backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	'&:before': {
		display: 'block',
		width: 16,
		height: 16,
		backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		content: '""',
	},
	'input:hover ~ &': {
		backgroundColor: '#f3714d',
	},
});





export default function Pricing() {
	//UseState's:
	const { id } = useParams()
	const [paidDetails, setPaidDetails] = React.useState(false);
	const [paymenttype, setPaymentType] = React.useState('free')
	const [weeklyamount, setWeeklyAmount] = React.useState('');
	const [monthlyamount, setMonthlyAmount] = React.useState('');
	const [onetimeamount, setOneTimeAmount] = React.useState('');
	const [alert, setAlert] = React.useState(false);
	const [installment, setInstallment] = React.useState(1)
	const [amount, setAmount] = React.useState('')
	const [frequency, setFrequency] = React.useState('weekly')
	//for validation:
	const [error, setError] = React.useState({ frquencymsg: '', installmentPaymsg: '', onetimemsg: '' })

	// for debounce:
	const [loading, setLoading] = React.useState(false);

	const commonStyles = {
		'& .MuiOutlinedInput-root': {
		  height: '56px',
		},
		'& .MuiInputBase-root': {
		  height: '56px',
		},
	  };




	// stateFor Reccuring Payment
	const [reccuringn, setReccuring] = React.useState(false)

	// stateFor OneTimePayment Payment
	const [OTPayment, setOTPayment] = React.useState(false)

	React.useEffect(() => {
		getChapters()
	}, [id])

	const getChapters = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `Course1/GetCourse/${id}`);
			if (response?.data?.course && response.data?.course.course_paymenttype == 'recurring') {
				setPaymentType('recurring')
				setAmount(response.data.course.amountInWeek ? response.data.course.amountInWeek : response.data.course.amountInMonth ? response.data.course.amountInMonth : response.data.course.amountInByWeekly)
				setReccuring(true)
				setAlert(true)
			}
			else if (response?.data?.course && response.data?.course.course_paymenttype == 'onetimepayment') {
				setPaymentType('onetimepayment')
				setOneTimeAmount(response.data.course.amountOneTime)
				setOTPayment(true)
				setAlert(true)
			}
			else if (response?.data?.course && response.data?.course.course_paymenttype == 'free') {
				setPaymentType('free')
				setAlert(true)
			}
		} catch (error) {
			console.error(error);
		}
	}


	//Validation:
	const PayValidation = () => {
		if (paymenttype == 'recurring') {
			if (!amount) {
				setError({ installmentPaymsg: 'This field is required' });
				return false
			}
		}
		if (paymenttype == 'onetimepayment') {
			if (!onetimeamount) {
				setError({ onetimemsg: 'This field is required' });
				return false
			}
		}
		return true
	}



	//Payment functionality:
	// const handlePaidDetails = async (data) => {
	// 	let valid = PayValidation();
	// 	if (valid == true) {
	// 		if (data == 'free') {
	// 			setLoading(true);
	// 			let freebody = {
	// 				"course_paymenttype": data == 'free' && data
	// 			}

	// 			await axios.put(process.env.REACT_APP_BASE_URL + `Course1/addPaymentPricing/${id}`, freebody)
	// 				.then((res) => {
	// 					getChapters()
	// 					enqueueSnackbar('Free Payment Submitted Successfully', { variant: 'success' })
	// 					setPaymentType('free')
	// 					setOneTimeAmount('')
	// 					setWeeklyAmount('')
	// 					setMonthlyAmount('')
	// 					setReccuring(false)
	// 					setOTPayment(false)
	// 				})
	// 				.catch((err) => {
	// 					console.log(err, "error in free handlePaidDetails");
	// 				}).finally(()=>{
	// 					setLoading(false);
	// 				})
	// 		}
	// 		else if (data == 'onetimepayment') {
	// 			setLoading(true);

	// 			let onetimebody = {
	// 				"amountOneTime": onetimeamount,
	// 				"currency": 'USD',
	// 				"course_paymenttype": data == 'onetimepayment' && data
	// 			}

	// 			await axios.put(process.env.REACT_APP_BASE_URL + `Course1/addPaymentPricing/${id}`, onetimebody)
	// 				.then((res) => {
	// 					getChapters()

	// 					enqueueSnackbar('One-Time Payment Submitted Successfully', { variant: 'success' })
	// 					setOneTimeAmount('')
	// 					// setPaymentType('onetimepayment')
	// 					setWeeklyAmount('')
	// 					setMonthlyAmount('')
	// 					setReccuring(false)
	// 					setOTPayment(false)
	// 				})
	// 				.catch((err) => {
	// 					console.log(err, "error in onetime payment handlePaidDetails");
	// 				}).finally(()=>{
	// 					setLoading(false);
	// 				})
	// 		}
	// 		else if (data == 'recurring') {
	// 			console.log(amount,'fjvornvrniffoienfo');
	// 			setLoading(true);
	// 			let recurringbody = {
	// 				"amountInWeek":frequency === 'weekly' ? amount : '',
	// 				"amountInMonth":frequency === 'monthly' ? amount : '',
	// 				"amountInByWeekly":frequency === 'biweekly' ? amount : '',
	// 				"currency": 'USD',
	// 				"course_paymenttype": data == 'recurring' && data
	// 			}
				
	// 			await axios.put(process.env.REACT_APP_BASE_URL + `Course1/addPaymentPricing/${id}`, recurringbody)
	// 				.then((res) => {
	// 					getChapters()
	// 					enqueueSnackbar(frequency == 'weekly' ? 'Weekly Payment Submitted Successfully' : frequency == 'monthly' ? 'Monthly Payment Submitted Successfully' : 'BiMonthly Payment Submitted Successfully' , { variant: 'success' })
	// 					setWeeklyAmount('')
	// 					setMonthlyAmount('')
	// 					setOneTimeAmount('')

	// 					// setPaymentType('recurring')
	// 					setOTPayment(false)
	// 					setReccuring(false)
	// 				})
	// 				.catch((err) => {
	// 					console.log(err, "error in onetime payment handlePaidDetails");
	// 				}).finally(()=>{
	// 					setLoading(false);
	// 				})
	// 		}
	// 	}
	// }

	const handlePaidDetails = useCallback(debounce(async (data) => {
		let valid = PayValidation();
		if (valid) {
			try {
				let body = {};
				if (data === 'free') {
					body = { "course_paymenttype": data };
				} else if (data === 'onetimepayment') {
					body = { "amountOneTime": onetimeamount, "currency": 'USD', "course_paymenttype": data };
				} else if (data === 'recurring') {
					body = {
						"amountInWeek": frequency === 'weekly' ? amount : '',
						"amountInMonth": frequency === 'monthly' ? amount : '',
						"amountInByWeekly": frequency === 'biweekly' ? amount : '',
						"currency": 'USD',
						"course_paymenttype": data,
						"installment":installment
					};
				}
			setLoading(true);

				await axios.put(process.env.REACT_APP_BASE_URL + `Course1/addPaymentPricing/${id}`, body);
				getChapters();
				enqueueSnackbar(`${data.charAt(0).toUpperCase() + data.slice(1)} Payment Submitted Successfully`, { variant: 'success' });
				// Reset amounts and state
				setOneTimeAmount('');
				setWeeklyAmount('');
				setMonthlyAmount('');
				setReccuring(false);
				setOTPayment(false);
			} catch (err) {
				console.log(err, `error in ${data} handlePaidDetails`);
			} finally {
				setLoading(false);
			}
		}
	}, 1000), [amount, frequency, id, onetimeamount]);

console.log(amount,"amountttttt");


	const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };

	return (
		<Box className='whiteContainer pricingPage p-20'>
			<Box className="card p-20">
				<Box className="text-center ">
					<img src={banner} height={350} />
					<Typography component='h6' variant='h6' className='text_black semibold mb-30'>Are you creating free or recurring or onetime course?</Typography>
					<Box className="mb-20 ">
						<Typography component='div' className='label' style={{ marginRight: 430 }}>Choose Payment Mode</Typography>
						<RadioGroup
							row
							aria-labelledby="demo-customized-radios"
							name="payment_type"
							onChange={(e) => setPaymentType(e.target.value)}
							value={paymenttype}
						>
							<FormControlLabel
								value="free"
								className='radio-btns'
								control={<BpRadio />}
								label={<Typography className='fz-14'>Free</Typography>}
							/>
							<FormControlLabel
								value="recurring"
								className='radio-btns'
								control={<BpRadio />}
								label={<Typography className='fz-14'>Recurring</Typography>}
							/>
							<FormControlLabel
								value="onetimepayment"
								className='radio-btns'
								control={<BpRadio />}
								label={<Typography className='fz-14'>One-Time Payment</Typography>}
							/>
						</RadioGroup>
					</Box>





					{paymenttype === 'recurring' && (
						<Grid container spacing={2} className="mb-20" alignItems="center">
							<Grid item xs={4}>
								<FormControl variant="standard" fullWidth>
									<Typography component='div' className='label'>
										Select Frequency<Typography component='span' className='star'> *</Typography>
									</Typography>
									<Select
										id="weekly-price-select"
										variant="outlined"
										className='textfield'
										name="amount"
										value={frequency}
										disabled={reccuringn}
										onChange={(e) => {
											setFrequency(e.target.value);
											setError({ frquencymsg: '' });
										}}
									>
										<MenuItem value={'weekly'}>Weekly</MenuItem>
										<MenuItem value={'monthly'}>Monthly</MenuItem>
										<MenuItem value={'biweekly'}>Bi-Weekly</MenuItem>
									</Select>
									{error.frquencymsg && <p style={{ color: 'red' }} className="fz-13 m-0">{error.frquencymsg}</p>}
								</FormControl>
							</Grid>

							<Grid item xs={4}>
								<FormControl variant="standard" fullWidth>
									<Typography component='div' className='label'>
										Amount<Typography component='span' className='star'> *</Typography>
									</Typography>
									<TextField
										id="filled-size-large" 
										variant="outlined"
										className='textfield' 
										placeholder='Amount'
										name="amount"
										onInput={(e) => onlyNumbers(e)}
										value={"$" + amount}
										// disabled={reccuringn}
										sx={commonStyles}
										onChange={(e) => {
											setAmount(e.target.value);
											setError({ installmentPaymsg: '' });
										}}
									/>
									{error.installmentPaymsg && (
										<p style={{ color: 'red' }} className="fz-13 m-0">{error.installmentPaymsg}</p>
									)}
								</FormControl>
							</Grid>

							<Grid item xs={4}>
								<FormControl variant="standard" fullWidth>
									<Typography component='div' className='label'>
										Select Installments<Typography component='span' className='star'></Typography>
									</Typography>
									<Select
										id="weekly-price-select"
										variant="outlined"
										className='textfield'
										name="amount"
										value={installment}
										disabled={reccuringn}
										onChange={(e) => { setInstallment(e.target.value); }}>
										<MenuItem value={'1'}>1 Installments</MenuItem>
										<MenuItem value={'2'}>2 Installments</MenuItem>
										<MenuItem value={'3'}>3 Installments</MenuItem>
										<MenuItem value={'4'}>4 Installments</MenuItem>
										<MenuItem value={'5'}>5 Installments</MenuItem>
										<MenuItem value={'6'}>6 Installments</MenuItem>
										<MenuItem value={'7'}>7 Installments</MenuItem>
										<MenuItem value={'8'}>8 Installments</MenuItem>
										<MenuItem value={'9'}>9 Installments</MenuItem>
										<MenuItem value={'10'}>10 Installments</MenuItem>
										<MenuItem value={'11'}>11 Installments</MenuItem>
										<MenuItem value={'12'}>12 Installments</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					)}




					{paymenttype == 'onetimepayment' &&
						<Grid container columnSpacing={2} rowSpacing={2} className="mb-20">
							<Grid item xs={12} sm={6} md={4} lg={4}>
								<FormControl variant="standard" fullWidth>
									<Typography component='div' className='label'>Amount<Typography component='span' className='star'> *</Typography></Typography>
									<TextField
										id="filled-size-small"
										variant="outlined"
										size="small"
										className='textfield'
										placeholder='Amount'
										name="amount"
										
										onInput={(e) => onlyNumbers(e)}
										value={"$" + onetimeamount}
										disabled={OTPayment}
										sx={commonStyles}

										onChange={(e) => { setOneTimeAmount(e.target.value); setError({ onetimemsg: '' }) }} />
									{error.onetimemsg && <p style={{ color: 'red' }} className="fz-13 m-0">{error.onetimemsg}</p>}
								</FormControl>
							</Grid>

							{/* {
						
						OTPayment ? <Button className='theme-btn' sx={{width:"95%",margin:"auto"}} onClick={() => handlePaidEditDetails(paymenttype)} 
						>Update</Button> :
						    <Button className='theme-btn' sx={{width:"95%",margin:"auto"}} onClick={() => handlePaidDetails(paymenttype)}>Submit</Button>
							} */}

						</Grid>}








					<Box className="flex-center justify-content-center">
						{/* <Button sx={{ padding: '4px 10px' }} className='btnTransparent capitalize text_black border mr-10 semibold'>Free Course</Button> */}
						{/* {alert == true ?
						<Button className='theme-btn' fullWidth onClick={() => handlePaidDetails(paymenttype)}>Update</Button>
						:
						<Button className='theme-btn' fullWidth onClick={() => handlePaidDetails(paymenttype)}>Submit</Button>
						} */}

						{/* {
							reccuringn ? <Button className='theme-btn' fullWidth disabled={loading} onClick={() => handlePaidDetails(paymenttype)} >Update</Button> :

								OTPayment ? <Button className='theme-btn' fullWidth disabled={loading} onClick={() => handlePaidDetails(paymenttype)} >Update</Button> :

									<Button className='theme-btn' fullWidth disabled={loading} onClick={() => handlePaidDetails(paymenttype)}>Submit</Button>


						} */}

{loading ? (
  <Button className='theme-btn' fullWidth disabled>
    Loading...
  </Button>
) : (
  reccuringn || OTPayment ? (
    <Button
      className='theme-btn'
      fullWidth
      disabled={loading}
      onClick={() => handlePaidDetails(paymenttype)}
    >
      Update
    </Button>
  ) : (
    <Button
      className='theme-btn'
      fullWidth
      disabled={loading}
      onClick={() => handlePaidDetails(paymenttype)}
    >
      Submit
    </Button>
  )
)}


						{/* {
							reccuringn ? <Button className='theme-btn' fullWidth onClick={() => handlePaidDetails(paymenttype)}>Update</Button> :
						<Button className='theme-btn' fullWidth onClick={() => handlePaidDetails(paymenttype)}>Submit</Button>
						} */}
						{/* {
							 paymenttype == 'free' && <Button className='theme-btn' fullWidth onClick={() => handlePaidDetails(paymenttype)}>

								{(OTPayment || reccuringn ) ? `update` : `submit`}
							 </Button>
						} */}
					</Box>
				</Box>
				{/* {paidDetails &&
                    <Box className="priceCard">
                        <Typography component='div' variant='body1' className='semibold my-10 '>Price</Typography>
                        <Divider></Divider>
                        <Typography component='h5' variant='h5' className='semibold'>Course Title</Typography>
                        <Typography component='h6' variant='h6' className='semibold'>$24</Typography>
                    </Box>
                } */}
			</Box>

		</Box>
	)
}
