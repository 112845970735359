import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, Grid, Button, TextField, Select, InputAdornment, FormControl, FormControlLabel, Card,CircularProgress, Menu, Tooltip, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, tableCellClasses, Paper } from '@mui/material';
import { SearchOutlined, Verified, CloseOutlined,GridView,ListAlt } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DOMPurify from 'dompurify';
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack'
import Switch, { SwitchProps } from '@mui/material/Switch';
import CardContent from '@mui/material/CardContent';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import moment from 'moment';
import PersonIcon from '@mui/icons-material/Person';
import 'swiper/css/pagination';

import { Pagination, Navigation } from 'swiper/modules';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function FormPropsTextFields() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const navigate = useNavigate()
    const params = useParams()
    const { enqueueSnackbar } = useSnackbar()
    const [newdata, setNewdata] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [openview, setOpenview] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [isDeleting, setIsDeleting] = React.useState(false);
      const [openComments,setopenComments]=React.useState(false)
    const menuopen = Boolean(anchorEl);
    const [alldata, setAlldata] = React.useState('');
    const [cat, setCat] = React.useState([]);
    const [view, setView] = React.useState(false);
    const [deleteid, setDeleteId] = React.useState('');
    const [catid, setCatId] = React.useState('all');
    const [deletebutton,setdeletebutton]=React.useState(false)

    const auth = JSON.parse(localStorage.getItem("userData"))
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    React.useEffect(() => {
        getCategory()
    }, [])

    const Gridview = () => {
        setView(false)
    }
    const Listview = () => {
        setView(true)
    }

    const getCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `category/GetCategory?size=100&page=0&order=asc`, { headers: headerlocal })
            .then((res) => {
                console.log(res.data)
                setCat(res.data.category)
            }).catch((error) => {
                console.log(error.response, "error");
            })
    }

    const handleClick = (event, data) => {
        setAlldata(data)
        setAnchorEl(event.currentTarget);
    };

    const handleClose_menu = () => {
        setAnchorEl(null);
    };


    // const navigateToComments = (e, book_id) => {
    //     e.preventDefault()
    //     navigate("/teachers/details/fans/edit/${params.id}", { state: book_id })
    // }


    const navigateToComments = (e, book_id) => {
        e.preventDefault()
        navigate("/bookreviews/bookcomment", { state: book_id })
    }

    const[Tableloading,setTableloading]=React.useState(true)


    React.useEffect(() => {
        getBookreviews()
    }, [rowsPerPage, page, search, catid])

    const getBookreviews = async () => {

      setTableloading(true)

        await axios.get(process.env.REACT_APP_BASE_URL + `BookReview/GetbookReviewsbyCreatorId/${params.id}?size=${rowsPerPage}&page=${page}&search=${search}&category_id=${catid}`, { headers: headerlocal })
            .then((res) => {
                console.log(res.data, "book reviews++");
                setNewdata(res.data.bookReview)
                setTotal(res.data.totalcount)
            }).catch((error) => {
                console.log(error, "error in service get");
            })
            .finally(()=>{
              setTimeout(() => {
                setTableloading(false);
            }, 1500);
            })
    }


    const handleDeleteService = async () => {

        if (isDeleting) return;


        setIsDeleting(true); 


        // await axios.put(process.env.REACT_APP_BASE_URL + `BookReview/DeleteBookReview/${deleteid}`, {}, { headers: headerlocal })
        //     .then((res) => {
        //         enqueueSnackbar("Bookreviews deleted successfully", { variant: 'success' })
        //         getBookreviews()
        //         handleClose()
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     })

        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}BookReview/DeleteBookReview/${deleteid}`, {}, { headers: headerlocal });
            enqueueSnackbar("Bookreviews deleted successfully", { variant: 'success' });
            getBookreviews();
            handleClose();
        } catch (error) {
            console.log(error);
        } finally {
            setIsDeleting(false); // Reset loading state after operation
        }
           
    }


    const handleClickOpen = () => {
        setAnchorEl(null);
        setDeleteId(alldata._id)
        setOpen(true);
    };

    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };


    const handleEdit = () => {
        let val = {
            data: alldata
        }
        navigate(`/teachers/details/bookreview/edit/${params.id}`, { state: val })
    }

    const handleView = () => {
        setAnchorEl(null);
        setOpenview(true)

    }

    const handleRemove = () => {
        setCatId('all')
        setSearch('')
        setPage(0)
    }




    const sanitizedContent = DOMPurify.sanitize(alldata.Book_Description, { ALLOWED_TAGS: ['img'] });

    const handleClickClose = () => {
        setOpenview(false)
        setAlldata('')
    }

    const handleChangeStatus = async (e, data, type) => {
        console.log(data, type, 'type');
        // if (type === 'publish') {
        //     const body = {
        //         Book_publish: e.target.checked,
        //     };
        //     console.log(body, 'body');
        //     try {
        //         console.log('try');
        //         await axios.put(process.env.REACT_APP_BASE_URL + `BookReview/updateBookReview/${data._id}`, body, { headers: headerlocal }).then((res) => {
        //             getBookreviews();
        //         }).catch((error) => {
        //             enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
        //             console.log(error, 'errrrr');
        //         })

        //     } catch (error) {
        //         enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
        //         console.error("Error", error);
        //     }
        // }
        if (type === 'Public') {
            const body = {
                public: e.target.checked,
            };
            console.log(body, 'body');
            try {
                console.log('try');
                await axios.put(process.env.REACT_APP_BASE_URL + `BookReview/updateBookReview/${data._id}`, body, { headers: headerlocal }).then((res) => {
                    getBookreviews();
                }).catch((error) => {
                    enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                    console.log(error, 'errrrr');
                })

            } catch (error) {
                enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                console.error("Error", error);
            }
        }
        if (type === 'Primary') {
            const body = {
                primary: e.target.checked,
            };
            try {
                const response = await axios.put(process.env.REACT_APP_BASE_URL + `BookReview/updatebookreviewPrimary/${data._id}`, body, { headers: headerlocal }).then((res) => {
                    getBookreviews();
                }).catch((error) => {
                    enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                    console.log(error, 'errrrr');
                })

            } catch (error) {
                enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                console.error("Error while updating primary add", error);
            }
        }
    };
    const closeCommentsModal = () => {
        setopenComments(false)
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
   

        const items = Array.from(newdata);

        const [reorderedItem] = items.splice(result.source.index, 1);

        items.splice(result.destination.index, 0, reorderedItem);
        var fromvar = [...items]
        
        
        // var intial = fromvar[result.source.index]
        var final = fromvar[result.destination.index]
        var before = fromvar[result.destination.index - 1]
        var after = fromvar[result.destination.index + 1]
        let currElIndexNumber = 0;

        if (before === undefined || before === "undefined") {
            currElIndexNumber = after.index_position + 512;
            console.log("called1",currElIndexNumber)
        } else if (after === undefined || after === "undefined") {

            
            currElIndexNumber = before.index_position - 512;
            console.log("called1",currElIndexNumber)
        } else if (before.setService === true && after.setService === false) {
            console.log("called1")

            currElIndexNumber = before.index_position - 512;
        } else if (before.setService === false && after.setService === true) {
            console.log("called1")

            currElIndexNumber = after.index_position + 512;

        } else if (before.setService === false && after.setService === true) {
            console.log("called1")

            currElIndexNumber = before.index_position - 512;
        } else if (before.setService === true && after.setService === false) {
            console.log("called1")

            currElIndexNumber = after.index_position + 512;
        }
        else {
            console.log("called1")

            currElIndexNumber = Math.floor((before.index_position + after.index_position) / 2);
        }

        setNewdata(fromvar);

        var body = {
            index_position: currElIndexNumber
        }

        console.log(body)
        axios.put(process.env.REACT_APP_BASE_URL + `BookReview/updateBookReviewpositions/${final._id}`, body, { headers: headerlocal })
            .then((res) => {
                getBookreviews()
            }).catch((err) =>
                console.log(err.response, "drag & drop")
            )
    }

    return (
        // <>
        //     <Box className=' '>
        //         <Box className=''>
        //             <Box className='flex-center flex-wrap mb-20 '>
        //             <Box component='div' className='DTsearch mr-10'>
        //                     <FormControl variant="standard">
        //                         <Typography component='div' className='label mr-10'>Search :</Typography>
        //                         <TextField
        //                             id="filled-size-small"
        //                             variant="outlined"
        //                             size="small"
        //                             value={search}
        //                             className='textfield search'
        //                             onChange={(e) => { setSearch(e.target.value); setPage(0) }}
        //                             placeholder='Search Here...'
        //                             InputProps={{
        //                                 endAdornment: (
        //                                     <InputAdornment position="end">
        //                                         {search !== '' && (
        //                                             <IconButton onClick={handleRemove} size="small">
        //                                                 <CloseOutlined className='fz-18' />
        //                                             </IconButton>
        //                                         )}
        //                                         <SearchOutlined className='fz-18' />
        //                                     </InputAdornment>
        //                                 ),
        //                             }}
        //                         />
        //                     </FormControl>
        //                 </Box>
        //                 {/* <Box className='flex-center'>
        //                     <Typography component="div" className="label mr-10 mb-0">Category: </Typography>
        //                     <FormControl fullWidth className='minw200' >
        //                         <Select className="select"
        //                             labelId="demo-simple-select-label"
        //                             id="demo-simple-select"
        //                             onChange={(e) => setCatId(e.target.value)}
        //                             value={catid ? catid : 'all'}
        //                         >
        //                             <MenuItem value={'all'}>All</MenuItem>
        //                             {cat && cat.length > 0 && cat.map((data) => (
        //                                 <MenuItem value={data._id}>{data.category_title}</MenuItem>
        //                             ))}
        //                         </Select>
        //                     </FormControl>
        //                 </Box> */}

        //                 {/* {search != '' ?
        //                     <Button variant="contained" className='addbtn theme-btn ml-10' onClick={handleRemove} >Reset</Button>
        //                     : ""} */}
        //             </Box>
        //         </Box>
        //         <Box className=''>
        //             <Grid container columnSpacing={2} rowSpacing={2} className="servicesList otherList" >
        //                 {newdata && newdata.length > 0 ? newdata.map((data, index) => {
        //                     return (
        //                         <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={3} sx={{ marginTop: 2 }}>
        //                             <Card className="cardGrid" >
        //                                 <IconButton aria-label="more" id="long-button"
        //                                     aria-controls={menuopen ? 'long-menu' : undefined}
        //                                     aria-expanded={menuopen ? 'true' : undefined}
        //                                     aria-haspopup="true"
        //                                     onClick={(e) => handleClick(e, data)}
        //                                     className='vertical_menu'>
        //                                     <MoreVertIcon />
        //                                 </IconButton>
        //                                 <Menu
        //                                     id="long-menu"
        //                                     MenuListProps={{
        //                                         'aria-labelledby': 'long-button',
        //                                     }}
        //                                     anchorEl={anchorEl}
        //                                     open={menuopen}
        //                                     onClose={handleClose_menu}
        //                                     PaperProps={{
        //                                         style: {
        //                                             width: '15ch',
        //                                         },
        //                                     }}
        //                                     transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        //                                     anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        //                                 >
        //                                     <MenuItem className='menuitems' onClick={() => handleView()}><VisibilityIcon className='fz-16 mr-5' />View</MenuItem>
        //                                     <MenuItem className='menuitems' onClick={() => handleEdit()}><EditIcon className='fz-16 mr-5' />Edit</MenuItem>
        //                                     <MenuItem className='menuitems' onClick={(e) => handleClickOpen(e, data._id)}><DeleteIcon className='fz-14 mr-5' />Delete</MenuItem>
                                            
        //                                 </Menu>
        //                                 <Grid item xs={12} sm={12} md={12} lg={12}>
        //                                     <div className="">
        //                                         <Swiper
        //                                             modules={[Navigation, Pagination]}
        //                                             className="mySwiper "
        //                                             slidesPerView={1}
        //                                             pagination={{
        //                                                 clickable: true,
        //                                             }}
        //                                         >
        //                                             {data && data.allimages && data.allimages?.length > 0 && data.allimages.map((tdata, i) => (
        //                                                 <SwiperSlide> <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} /></SwiperSlide>
        //                                             )
        //                                             )}
        //                                         </Swiper>
        //                                     </div>
        //                                 </Grid>

        //                                 <CardContent>
        //                                     <Box className='mb-10 d-flex align-items-center justify-content-between' style={{ display: 'flex', justifyContent: 'space-between' }}>
        //                                         <Typography noWrap className='semibold title fz-18' variant="h6" component="h6">{data.Book_title}</Typography>
        //                                         {
        //                                             data.primary && <Typography component='div' variant='body1' className='fz-14' style={{ display: 'flex', alignItems: 'center' }}><Verified className='fz-18' style={{ color: '#1492fd' }} /></Typography>
        //                                         }
        //                                     </Box>

        //                                     {/* <Box className='mb-10 d-flex align-items-start justify-content-between' >
        //                                         <Box className=' text-right' style={{ display: 'flex', justifyContent: 'space-between' }}>
        //                                             <Typography component='div' variant='body1' className='fz-14 semibold'>Publish Enable/Disable:</Typography>
        //                                             <FormControlLabel className='m-0' style={{ display: 'flex', alignItems: 'center' }} control={<IOSSwitch onChange={(e) => handleChangeStatus(e, data, 'publish')} checked={data.Book_publish} sx={{ m: 1 }} />} />
        //                                         </Box>
        //                                     </Box> */}
        //                                     <Box className='mb-10 flex-center flex-wrap  justify-content-between' >
        //                                         <Box className='' >
        //                                             <Typography component='div' variant='body1' className='fz-14 semibold'>Public:   </Typography>
        //                                             <FormControlLabel className='m-0' control={<Switch size='small' onChange={(e) => handleChangeStatus(e, data, 'Public')} checked={data.public} />} />
        //                                         </Box>
        //                                         <Box className='' >
        //                                             <Typography component='div' variant='body1' className='fz-14 semibold'>Primary:   </Typography>
        //                                             <FormControlLabel className='m-0' control={<Switch size='small' onChange={(e) => handleChangeStatus(e, data, 'Primary')} checked={data.primary} />} />
        //                                         </Box>
        //                                     </Box>

        //                                     {data && data.Book_shortdescription ?
        //                                         <Typography component='div' variant='body1' className='fz-14 ' >{data && data.Book_shortdescription.slice(0, 50) + "..."}</Typography> :
        //                                         <Typography component='div' variant='body1' className='fz-14 '>There is no description</Typography>}

        //                                     <Box className='d-flex align-items-center justify-content-between' style={{ display: 'flex', justifyContent: 'space-between', }}>
        //                                     <Typography noWrap className='semibold title fz-18' variant="h6" component="h6" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}  onClick={(e) => navigateToComments(e, data._id)} >Comments</Typography>
        //                                         {/* <Typography component='div' variant='body1' className='fz-14 ' style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => navigateToComments(e, data._id)}>{data && data.Allcommentcount}</Typography> */}
        //                                     </Box>
        //                                 </CardContent>
        //                             </Card>
        //                         </Grid>
        //                     )
        //                 }) : <Grid item xs={12} sm={12} md={12} lg={12}>
        //                     <p className='text-center semibold'>No data found</p></Grid>}
        //             </Grid>
        //             <Dialog open={open} keepMounted
        //                 onClose={handleClose}
        //                 aria-describedby="alert-dialog-slide-description"
        //                 className='custom_modal'>
        //                 <DialogTitle>Would you like to delete Book Review?</DialogTitle>
        //                 <DialogActions>
        //                     <Button onClick={handleClose} className='cancel_btn'>No</Button>
        //                     {/* <Button onClick={handleDeleteService} className='theme-btn'>Yes</Button> */}
        //                     <Button onClick={handleDeleteService} className='theme-btn' disabled={isDeleting}>
        //                                             {isDeleting ? "Deleting..." : "Yes"}
        //                                     </Button>
        //                 </DialogActions>
        //             </Dialog>
        //         </Box>
        //         <TablePagination
        //             rowsPerPageOptions={[8, 12, 16, 20, 24]}
        //             component="div"
        //             count={total}
        //             rowsPerPage={rowsPerPage}
        //             page={page}
        //             onPageChange={handleChangePage}
        //             onRowsPerPageChange={handleChangeRowsPerPage}
        //             className='DT-pagination'
        //         />
        //     </Box>

        //     <BootstrapDialog
        //         onClose={handleClickClose}
        //         aria-labelledby="customized-dialog-title"
        //         open={openview}
        //         className='custom_modal'
        //         maxWidth='md'
        //     >
        //         <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        //             Book Review Details
        //         </DialogTitle>

        //         <DialogContent dividers>
        //             <Swiper
        //                 modules={[Navigation, Pagination]}
        //                 className="mySwiper maxw100"
        //                 slidesPerView={1}
        //                 pagination={{
        //                     clickable: true,
        //                 }}
        //             >
        //                 {alldata && alldata.allimages && alldata.allimages?.length > 0 && alldata.allimages.map((tdata, i) => (
        //                     <SwiperSlide> <img width={'100%'} height={300} className='objfit_cover' src={process.env.REACT_APP_BASE_URL + `${tdata}`} /></SwiperSlide>
        //                 )
        //                 )}
        //             </Swiper>




        //             <Typography component='div' variant='body1' className='fz-14 semibold my-10'>Description: </Typography>
        //             <Typography component='div' variant='body1' className='fz-14 ' >{alldata.Book_shortdescription}</Typography> 

        //             {sanitizedContent ? <div className='w-full editor_view' dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
        //                 : <Typography className='p18_tag' >{""}</Typography>}

        //         </DialogContent>
        //         <DialogActions>
        //             <Button className='cancel_btn' onClick={handleClickClose}>Close</Button>
        //         </DialogActions>
        //     </BootstrapDialog>
        // </>








        <>
        {!view ? (
          <Box className=" p-20">
            <Box className="flex-center flex-wrap mb-20 ">
              <Box className="mr-10 mb-5">
                <Tooltip title="Grid View">
                  <Button
                    className={view === false ? "active_tab" : "inactive_tab"}
                    onClick={Gridview}
                  >
                    <GridView />
                  </Button>
                </Tooltip>
                <Tooltip title="List View">
                  {" "}
                  <Button
                    className={view === true ? "active_tab" : "inactive_tab"}
                    onClick={Listview}
                  >
                    <ListAlt />
                  </Button>
                </Tooltip>
              </Box>

              <Box component="div" className="DTsearch mr-10">
                <FormControl variant="standard">
                  <Typography component="div" className="label mr-10">
                    Search :
                  </Typography>
                  <TextField
                    id="filled-size-small"
                    variant="outlined"
                    size="small"
                    value={search}
                    className="textfield search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPage(0);
                    }}
                    placeholder="Search Here..."
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {search !== "" && (
                            <IconButton onClick={handleRemove} size="small">
                              <CloseOutlined className="fz-18" />
                            </IconButton>
                          )}
                          <SearchOutlined className="fz-18" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Box>

              <Box className="flex-center">
                <Typography component="div" className="label mr-10 mb-0">
                  Category:{" "}
                </Typography>
                <FormControl fullWidth className="minw200">
                  <Select
                    className="select"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => setCatId(e.target.value)}
                    value={catid ? catid : "all"}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    {cat &&
                      cat.length > 0 &&
                      cat.map((data) => (
                        <MenuItem value={data._id}>
                          {data.category_title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              {search !== "" ? (
                <Button
                  variant="contained"
                  className="addbtn theme-btn ml-10"
                  onClick={handleRemove}
                >
                  Reset
                </Button>
              ) : (
                ""
              )}
            </Box>

            <Box className="">
              <Grid
                container
                columnSpacing={2}
                rowSpacing={2}
                className="servicesList otherList"
              >
                {Tableloading?
                (

                  <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: '100%' }}
                >
                  <Grid item>
                    <CircularProgress sx={{ color: '#F3714D' }} />
                  </Grid>
                </Grid>
                )
                :
                
                
                
                (newdata && newdata.length > 0 ? (
                  newdata.map((data, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        xl={3}
                        sx={{ marginTop: 2 }}
                      >
                        <Card className="cardGrid">
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={menuopen ? "long-menu" : undefined}
                            aria-expanded={menuopen ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => handleClick(e, data)}
                            className="vertical_menu"
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl}
                            open={menuopen}
                            onClose={handleClose_menu}
                            PaperProps={{
                              style: {
                                width: "15ch",
                              },
                            }}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <MenuItem
                              className="menuitems"
                              onClick={() => handleView()}
                            >
                              <VisibilityIcon className="fz-16 mr-5" />
                              View
                            </MenuItem>
                            <MenuItem
                              className="menuitems"
                              onClick={() => handleEdit()}
                            >
                              <EditIcon className="fz-16 mr-5" />
                              Edit
                            </MenuItem>
                            <MenuItem
                              className="menuitems"
                              onClick={(e) => handleClickOpen(e, data._id)}
                            >
                              <DeleteIcon className="fz-14 mr-5" />
                              Delete
                            </MenuItem>
                            {/* <MenuItem className='menuitems' onClick={(e) => navigateToComments(e, book_id)} ><LiaComments className='fz-14 mr-5' />Comments</MenuItem> */}
                          </Menu>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div className="">
                              <Swiper
                                modules={[Navigation, Pagination]}
                                className="mySwiper "
                                slidesPerView={1}
                                pagination={{
                                  clickable: true,
                                }}
                              >
                                {data &&
                                  data.allimages &&
                                  data.allimages?.length > 0 &&
                                  data.allimages.map((tdata, i) => (
                                    <SwiperSlide>
                                      {" "}
                                      <img
                                        width={"100%"}
                                        alt={"pic"}
                                        height={250}
                                        src={
                                          process.env.REACT_APP_BASE_URL +
                                          `${tdata}`
                                        }
                                      />
                                    </SwiperSlide>
                                  ))}
                              </Swiper>
                            </div>
                          </Grid>

                          <CardContent>
                            <Box
                              className="mb-10 d-flex align-items-center justify-content-between"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                noWrap
                                className="semibold title fz-18"
                                variant="h6"
                                component="h6"
                              >
                                {data.Book_title}
                              </Typography>
                              {data.primary && (
                                <Typography
                                  component="div"
                                  variant="body1"
                                  className="fz-14"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Verified
                                    className="fz-18"
                                    style={{ color: "#1492fd" }}
                                  />
                                </Typography>
                              )}
                            </Box>

                            {/* <Box className='mb-10 d-flex align-items-start justify-content-between' >
                                                <Box className=' text-right' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography component='div' variant='body1' className='fz-14 semibold'>Publish Enable/Disable:</Typography>
                                                    <FormControlLabel className='m-0' style={{ display: 'flex', alignItems: 'center' }} control={<IOSSwitch onChange={(e) => handleChangeStatus(e, data, 'publish')} checked={data.Book_publish} sx={{ m: 1 }} />} />
                                                </Box>
                                            </Box> */}
                            <Box className="mb-10 flex-center flex-wrap  justify-content-between">
                              <Box className="">
                                <Typography
                                  component="div"
                                  variant="body1"
                                  className="fz-14 semibold"
                                >
                                  Public:{" "}
                                </Typography>
                                <FormControlLabel
                                  className="m-0"
                                  control={
                                    <Switch
                                      size="small"
                                      onChange={(e) =>
                                        handleChangeStatus(e, data, "Public")
                                      }
                                      checked={data.public}
                                    />
                                  }
                                />
                              </Box>
                              <Box className="">
                                <Typography
                                  component="div"
                                  variant="body1"
                                  className="fz-14 semibold"
                                >
                                  Primary:{" "}
                                </Typography>
                                <FormControlLabel
                                  className="m-0"
                                  control={
                                    <Switch
                                      size="small"
                                      onChange={(e) =>
                                        handleChangeStatus(e, data, "Primary")
                                      }
                                      checked={data.primary}
                                    />
                                  }
                                />
                              </Box>
                              
                            </Box>

                            {data && data.Book_shortdescription ? (
                              <Typography
                                component="div"
                                variant="body1"
                                className="fz-14 "
                              >
                                {data &&
                                  data.Book_shortdescription.slice(0, 50) +
                                    "..."}
                              </Typography>
                            ) : (
                              <Typography
                                component="div"
                                variant="body1"
                                className="fz-14 "
                              >
                                There is no description
                              </Typography>
                            )}

                            {/* <Box className='d-flex align-items-center justify-content-between' style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                <Typography noWrap className='semibold title fz-18' variant="h6" component="h6" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => navigateToComments(e, data._id)} >Comments</Typography>
                                                {/* <Typography component='div' variant='body1' className='fz-14 ' style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => navigateToComments(e, data._id)}>{data && data.Allcommentcount}</Typography> 
                                            </Box> */}
                            {/* <Button className='d-flex align-items-center commentIcons mt-10' onClick={openCommentsModal}> */}
                            {/* <i class="fa-regular fa-comment fz-18 text-grey mr-10"></i> */}
                            {/* <Typography component='div' variant='body1' className='fz-14 count'>12</Typography> */}
                            {/* <Typography component='span' variant='body1' className='fz-14 text-grey'>comments</Typography> */}
                            {/* </Button> */}
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <p className="text-center semibold">No data found</p>
                  </Grid>
                ))}
              </Grid>
              <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className="custom_modal"
              >
                <DialogTitle>Would you like to delete Book Review?</DialogTitle>
                <DialogActions>
                  <Button onClick={handleClose} className="cancel_btn">
                    No
                  </Button>
                  {deletebutton==false?(
                    <Button onClick={handleDeleteService} className="theme-btn">
                    Yes
                  </Button>

                  ):(<Button  className="theme-btn">
                    Please wait...
                  </Button>)}
                  
                </DialogActions>
              </Dialog>
            </Box>

            <TablePagination
              rowsPerPageOptions={[8, 12, 16, 20, 24]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className="DT-pagination"
            />
          </Box>
        ) : (
          <>
            <Box className="flex-center flex-wrap  p-20">

              <Box className="mr-10 mb-5">
                <Tooltip title="Grid View">
                  <Button
                    className={view === false ? "active_tab" : "inactive_tab"}
                    onClick={Gridview}
                  >
                    <GridView />
                  </Button>
                </Tooltip>
                <Tooltip title="List View">
                  {" "}
                  <Button
                    className={view === true ? "active_tab" : "inactive_tab"}
                    onClick={Listview}
                  >
                    <ListAlt />
                  </Button>
                </Tooltip>
              </Box>

              <Box component="div" className="DTsearch mr-10">
                <FormControl variant="standard">
                  <Typography component="div" className="label mr-10">
                    Search :
                  </Typography>
                  <TextField
                    id="filled-size-small"
                    variant="outlined"
                    size="small"
                    value={search}
                    className="textfield search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPage(0);
                    }}
                    placeholder="Search Here..."
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {search !== "" && (
                            <IconButton onClick={handleRemove} size="small">
                              <CloseOutlined className="fz-18" />
                            </IconButton>
                          )}
                          <SearchOutlined className="fz-18" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Box>

              <Box className="flex-center">
                <Typography component="div" className="label mr-10 mb-0">
                  Category:{" "}
                </Typography>
                <FormControl fullWidth className="minw200">
                  <Select
                    className="select"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => setCatId(e.target.value)}
                    value={catid ? catid : "all"}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    {cat &&
                      cat.length > 0 &&
                      cat.map((data) => (
                        <MenuItem value={data._id}>
                          {data.category_title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              {search !== "" ? (
                <Button
                  variant="contained"
                  className="addbtn theme-btn ml-10"
                  onClick={handleRemove}
                >
                  Reset
                </Button>
              ) : (
                ""
              )}

            </Box>

            <Box>
              <TableContainer component={Paper} id="table">
                <Table
                  sx={{ minWidth: 700 }}
                  aria-label="customized table"
                  className=""
                >
                  <TableHead className="DT-head">
                    <TableRow className="">
                    {/* style={{ minWidth: 150 }} align="center" className='tbl_head' */}
                      <TableCell
                        align="center"
                        className="TRow DT-checkbox"
                        style={{ minWidth: 150, padding: "6px 16px" }}
                      >
                        Drag row
                      </TableCell>
                      <TableCell
                        align="center"
                        className="TRow DT-checkbox"
                        style={{ minWidth: 200, padding: "6px 16px" }}
                      >
                        Book Cover
                      </TableCell>
                      <TableCell
                        align="left"
                        className="TRow DT-checkbox"
                        style={{ minWidth: 200, padding: "6px 16px" }}
                      >
                        Book Title
                      </TableCell>

                      <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 200, padding: '6px 16px' }}>Auther Name</TableCell>
                      <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 200, padding: '6px 16px' }}>Published By</TableCell>
                      
                      <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 200, padding: '6px 16px' }}>Primary</TableCell>
                      <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 200, padding: '6px 16px' }}>Public</TableCell>
                      
                     

                      <TableCell
                        align="left"
                        className="TRow DT-checkbox"
                        style={{ minWidth: 200, padding: "6px 16px" }}
                      >
                        Publish Date
                      </TableCell>

                      
                    </TableRow>
                  </TableHead>

                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters">
                      {(provided) => (
                        <TableBody
                          className="characters "
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {newdata && newdata?.length > 0 ? (
                            newdata.map((row, i) => (
                              <Draggable
                                key={row._id}
                                draggableId={row._id}
                                index={i}
                              >
                                {(provided) => (
                                  <>
                                    <TableRow
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className=""
                                    >
                                      <TableCell
                                        className="DT-row"
                                        style={{ width: 200 }}
                                        align="center"
                                      >
                                        <DragHandleIcon />
                                      </TableCell>

                                      <TableCell align="center" className='DT-row'>
                                        {row.image
                                        ? <img className="cat_image" src={process.env.REACT_APP_BASE_URL + row.image} alt='' height={50} width={50} />
                                        : <PersonIcon style={{ height: 50, width: 50 }} />}
                                    </TableCell>

                                      {row && row.Book_title ? (
                                        <TableCell
                                          className="DT-row"
                                          align="left"
                                          style={{ minWidth: 200 }}
                                        >
                                          {row && row.Book_title}
                                          {row && row.primary === true ? (
                                            <Typography
                                              component="span"
                                              variant="body1"
                                              className="fz-14 "
                                            >
                                              {" "}
                                              <Verified
                                                className="fz-18"
                                                style={{ color: "#1492fd" }}
                                              />
                                            </Typography>
                                          ) : (
                                            ""
                                          )}
                                        </TableCell>
                                      ) : (
                                        <TableCell
                                          className="DT-row"
                                          style={{ minWidth: 200 }}
                                        >
                                          -
                                        </TableCell>
                                      )}

                                        {row && row.author ? (
                                        <TableCell
                                          className="DT-row"
                                          align="left"
                                          style={{ minWidth: 200 }}
                                        >
                                          {row && row.author}
                                        </TableCell>
                                      ) : (
                                        <TableCell
                                          className="DT-row"
                                          style={{ minWidth: 200 }}
                                        >
                                          -
                                        </TableCell>
                                      )}

                                    <TableCell
                                          className="DT-row"
                                          align="left"
                                          style={{ minWidth: 200 }}
                                        >
                                          {row && row.Book_published_by}
                                    </TableCell>

                                    
                                    
                                    <StyledTableCell
                                        align="left"
                                        className="DT-row"
                                      >
                                        <FormControlLabel
                                          className="m-0"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          control={
                                            <Switch
                                              size="small"
                                              onChange={(e) =>
                                                handleChangeStatus(
                                                  e,
                                                  row,
                                                  "Primary"
                                                )
                                              }
                                              checked={row.primary}
                                              sx={{ m: 1 }}
                                            />
                                          }
                                        />
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="DT-row"
                                      >
                                        <FormControlLabel
                                          className="m-0"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          control={
                                            <Switch
                                              size="small"
                                              onChange={(e) =>
                                                handleChangeStatus(
                                                  e,
                                                  row,
                                                  "Public"
                                                )
                                              }
                                              checked={row.public}
                                              sx={{ m: 1 }}
                                            />
                                          }
                                        />
                                      </StyledTableCell>


                                      <StyledTableCell align="left" className='DT-row'>{moment(row.review_posted_online, "ddd, DD MMM YYYY HH:mm:ss [GMT]").format("DD MMM YYYY")}</StyledTableCell>

                                    </TableRow>
                                  </>
                                )}
                              </Draggable>
                            ))
                          ) : (
                            <TableRow className="">
                              <TableCell
                                className="DT-row"
                                align="center"
                                colSpan={5}
                              >
                                No Data Found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[8, 12, 16, 20, 24]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="DT-pagination"
              />

            </Box>
          </>
        )}

        <BootstrapDialog
          onClose={handleClickClose}
          aria-labelledby="customized-dialog-title"
          open={openview}
          className="custom_modal"
          maxWidth="md"
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Book Review Details
          </DialogTitle>

          <DialogContent dividers>
            <Swiper
              modules={[Navigation, Pagination]}
              className="mySwiper maxw100"
              slidesPerView={1}
              pagination={{
                clickable: true,
              }}
            >
              {alldata &&
                alldata.allimages &&
                alldata.allimages?.length > 0 &&
                alldata.allimages.map((tdata, i) => (
                  <SwiperSlide>
                    {" "}
                    <img
                      width={"100%"}
                      alt='pic'
                      height={300}
                      className="objfit_cover"
                      src={process.env.REACT_APP_BASE_URL + `${tdata}`}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>

            {alldata && alldata.Book_shortdescription ? (
              <Typography component="div" variant="body1" className="fz-14 ">
                {alldata && alldata.Book_shortdescription.slice(0, 50) + "..."}
              </Typography>
            ) : (
              <Typography component="div" variant="body1" className="fz-14 ">
                There is no description
              </Typography>
            )}

            {/* <Typography component='div' variant='body1' className='fz-14 semibold my-10'>Description: </Typography>
                    {sanitizedContent ? <div className='w-full editor_view' dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                        : <Typography className='p18_tag' >There is no description</Typography>} */}
          </DialogContent>
          <DialogActions>
            <Button className="cancel_btn" onClick={handleClickClose}>
              Close
            </Button>
          </DialogActions>
        </BootstrapDialog>

        <BootstrapDialog
          onClose={closeCommentsModal}
          aria-labelledby="customized-dialog-title"
          open={openComments}
          className="custom_modal commentModal"
          maxWidth="md"
          PaperProps={{
            style: {
              width: "100%",
            },
          }}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            className="semibold"
            id="customized-dialog-title"
          >
            Comments
          </DialogTitle>

          <DialogContent dividers>  
            {/* <Comments/> */}
          </DialogContent>
          <DialogActions>
            <Button className="cancel_btn" onClick={closeCommentsModal}>
              Close
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </>
    );
}
