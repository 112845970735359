
import { Box, Typography } from '@mui/material'
import React from 'react'
import {useParams } from 'react-router-dom'
import MiniDrawer from '../../../../../components/drawer'
import Settings from './TwoFA/Settings'


const MainIndex = () => {
    
    const params = useParams()
    console.log(params)

  return (
    <>
    <Box className='companies'>
            <MiniDrawer menuOrder={13} submenuOrder={1}/>
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Settings</Typography>
                </Box>
                <Box className='whiteContainer' sx={{minHeight:"50vh"}}>
                  <Settings/>  
                </Box>
            </Box>
        </Box>
    </>
  )
}

export default MainIndex;