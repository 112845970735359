import { CloseOutlined, SearchOutlined } from '@mui/icons-material';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle, FormControl, FormControlLabel, IconButton, InputAdornment, ListItem, Menu, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar, Typography,List } from '@mui/material';
import React, { useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DeleteOutline} from '@mui/icons-material';
import { CiLink } from "react-icons/ci";
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

// import { Table, TableHead, TableRow, TableCell, TableBody, Menu, MenuItem, Button } from '@mui/material';







const Datatablecoupons = () => {

    const params = useParams()
    const navigate = useNavigate()
    const headerformdata = {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("token")
    }
    const [loading,setLoading] = useState('false')
    const [searchTerm, setSearchTerm] = React.useState('')
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [count, setCount] = React.useState(0)
    const [coupons, setCouponsDetails] = React.useState([])
    const [deleteid, setDeleteId] = React.useState('')
    const [openDel, setOpenDel] = React.useState(false);


    const [communities, setCommunities] = useState([])
    const [course, setCourses] = useState([])
    const [yogaFans, setYogaFans] = useState([])

    const [rawCouponData,setRawCouponData] = useState([])


    console.log(params.id)

    // React.useEffect(() => {
    //     getCouponDetails()
    // }, [page, rowsPerPage, searchTerm])

   

    // const getCouponDetails =async () => {
    //     // setLoading(true); // Set loading to true before making the API call
        
    //     // Simulate network delay
        
    //        await axios.get(`${process.env.REACT_APP_BASE_URL}Coupon/getCoupons?size=${rowsPerPage}&page=${page}&search=${searchTerm}`, { headers: headerformdata })
    //        .then((res) => {
    //         console.log(res.data.coupons, 'res.getTeacherDetails.list');
    //         setCouponsDetails(res.data.coupons)
    //         // setTeacherDetails(res.data.teachers);
    //         setCount(res.data.totalcount);
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //         // Ensure loading is turned off even if there's an error
    //     }).finally(()=>{
    //         setLoading(false); // Set loading to false after data is fetched

    //     })

    // };

    
    //   const getCommunity = async () => {
    //     await axios
    //       .get(
    //         process.env.REACT_APP_BASE_URL +
    //         `Community/GetCommunitybasedonteacherid?createdby=${params && params.id
    //         }`
    //       )
    //       .then((res) => {
    //         // console.log(res.data, "community get");
    
    //         const discountOptions = res.data.communities.filter(community => community.community_type !== 'public');
    //         setCommunities(discountOptions)

    //     const communityMap = Object.fromEntries(communities.map(community => [community._id, community.coummunity_name]));


    //         setCouponsDetails(coupons => 
    //             coupons.map(coupon => ({
    //               ...coupon,
    //               communities: coupon.communities.map(id => communityMap[id]), // Replace ID with name
    //             }))
    //           )
    
            
    
    //         console.log(res.data, "community");
    //       })
    //       .catch((error) => {
    //         console.log(error, "error in community get");
    //       });
    //   };
    
  
    
    
    //   const CourseTable =async () => {
    //     await axios.get(process.env.REACT_APP_BASE_URL + `Course1/GetCourseallpublic`)
    //       .then((res) => {
    
    //         // setTotal(res.data.totalcount)
    //         const paidcourses = res.data.course.filter(course => course.course_paymenttype !== 'free');
            
    //         const courseMap = Object.fromEntries(paidcourses.map(course => [course._id, course.course_title]));

    //         setCouponsDetails(coupons => 
    //             coupons.map(coupon => ({
    //               ...coupon,
    //               courses: coupon.courses.map(id => courseMap[id]) // Replace ID with name
    //             }))
    //           )
    
    //         console.log(res.data.course)
    //       })
    //       .catch((err) => {
    //         console.log(err, "error in CourseTable");
    //       })
    //   }
    
   
    //   const getFans = async () => {
    //     await axios.get(process.env.REACT_APP_BASE_URL + `FanPage/GetFansbyTeacherId/${params.id}`).then((res) => {
    //       const paidFanspage = res.data.fans.filter(fan => fan.fanspage_paymentype !== 'free');
    //       setYogaFans(paidFanspage)

    //     const fansPageMap = Object.fromEntries(yogaFans.map(page => [page._id, page.name]));
    //     console.log(fansPageMap)
    //     setCouponsDetails(coupons => 
    //         coupons.map(coupon => ({
    //           ...coupon,
              
    //           fanspages: coupon.fanspages.map(id => fansPageMap[id] ), // Replace ID with name
              
    //         }))
    //       )
    //     //   console.log(res.data.fans)
    //     }).catch((error) => {
    //       console.log(error, "error in service get");
    //     })
    //   }


    //       console.log(coupons)


     const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    React.useEffect(() => {
       

        fetchData();
    }, [page, rowsPerPage, searchTerm]);

    const fetchData = async () => {

        setLoading(true);
        try {
            // Fetch all necessary data concurrently
            const [couponsResponse, communitiesResponse, fansResponse, coursesResponse] = await Promise.all([
                axios.get(`${process.env.REACT_APP_BASE_URL}Coupon/GetCouponbycreateId/${params.id}?size=${rowsPerPage}&page=${page}&search=${searchTerm}`, { headers: headerformdata }),
                axios.get(`${process.env.REACT_APP_BASE_URL}Community/GetCommunitybasedonteacherid?createdby=${params && params.id}`),
                axios.get(`${process.env.REACT_APP_BASE_URL}FanPage/GetFansbyTeacherId/${params.id}`),
                axios.get(`${process.env.REACT_APP_BASE_URL}Course1/GetCourseallpublic`)
            ]);

            // Coupon/GetCouponbycreateId/id

            // Set total count and coupon details
            setCount(couponsResponse.data.totalcount);
            const fetchedCoupons = couponsResponse.data.Coupons;

            setRawCouponData(couponsResponse.data.Coupons)

            // Prepare community mapping
            console.log(couponsResponse.data)
           

            const paidCommunity = communitiesResponse.data.communities.filter(comm => comm.community_type !== 'public');
            console.log(paidCommunity)
            const communityMap = Object.fromEntries(paidCommunity.map(community => [community._id, community.coummunity_name]));


            // const communityMap = Object.fromEntries(
            //     communitiesResponse.data.communities
            //         .filter(community => community.community_type !== 'public')
            //         .map(community => [community._id, community.community_name])
            // );

            // Prepare fans page mapping
            const paidFans = fansResponse.data.fans.filter(fan => fan.fanspage_paymentype !== 'free');
            const fansPageMap = Object.fromEntries(paidFans.map(page => [page._id, page.name]));

            // Prepare course mapping
            const paidCourses = coursesResponse.data.course.filter(course => course.course_paymenttype !== 'free');
            console.log(paidCourses)
            const courseMap = Object.fromEntries(paidCourses.map(course => [course._id, course.course_title]));

            // Combine all mappings into the coupon details
            const enrichedCoupons = fetchedCoupons.map(coupon => ({
                ...coupon,
                communities: coupon.communities.map(id => communityMap[id] || id), // Replace ID with name
                fanspages: coupon.fanspages.map(id => fansPageMap[id] || id), // Replace ID with name
                courses: coupon.courses.map(id => courseMap[id] || id) // Replace ID with name
            }));

            // Update state
            setCouponsDetails(enrichedCoupons);
            setCommunities(communitiesResponse.data.communities);
            setYogaFans(paidFans);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            // setLoading(false);
            setTimeout(() => {
                setLoading(false);
            }, 1500);
        }
    };


  

    
    

          

    const handleRemove = (e) => {
        setSearchTerm('');
        setPage(0)
    }

    const handleOpen = (id) => {
        setDeleteId(id)
        setOpenDel(true)
    }

    const handleCloseDel = () => {
        setDeleteId('')
        setOpenDel(false)
    }

    const handleDelete = async () => {
        
        await axios.put(process.env.REACT_APP_BASE_URL + `Coupon/deleteCoupons/${deleteid}`, {}, { headers: headerformdata },).then((res) => {
            handleCloseDel()
            fetchData();
            enqueueSnackbar("Coupon deleted successfully", { variant: 'success' })

        }).catch((err) => {
            console.log(err);
        })
    }


    const loaderContainerStyle = {
        // height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
        
    };

    const loaderTextStyle = {
        fontSize: '24px',
        color: '#555'
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [currentData, setCurrentData] = useState(null);
    const [menuType, setMenuType] = useState(null); // Track which menu to show

    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setCurrentData(row);
        console.log(row)
    };

    

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentData(null);
    };
    const open = Boolean(anchorEl);

    const EditData = (row) =>{
        const data = rawCouponData.filter(item => item._id === row._id);
        console.log(data)
        
        navigate(`/teachers/details/settings/editcoupons/${params.id}`,{ state: { couponData: data[0] } })
    }

    // const [expandedRow, setExpandedRow] = useState(null);

    // const handleToggleExpand = (rowId) => {
    //     setExpandedRow(expandedRow === rowId ? null : rowId);
    // };
  
    return (
    <>
    <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', boxShadow: 'none' }} className='Datatable'>
                <Toolbar className='toolbar comptoolbar'>
                        <Box className="mb-10">
                            <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Coupons and Offers</Typography>
                        </Box>

                    {/* {deletearry && deletearry.length > 0 && <Button className='del-btn' onClick={handleDeleteData}>Delete</Button>} */}
                    <Box component='div' className='DTsearch mr-10'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10'>Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                value={searchTerm}
                                className='textfield search'
                                onChange={(e) => { setSearchTerm(e.target.value); setPage(0) }}
                                placeholder='Search Here...'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {searchTerm !== '' && (
                                                <IconButton onClick={handleRemove} size="small">
                                                    <CloseOutlined className='fz-18' />
                                                </IconButton>
                                            )}
                                            <SearchOutlined className='fz-18' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>
                </Toolbar>
                <TableContainer id='table'>
                {loading ? (
                <Box className="loader-container" sx={loaderContainerStyle}>
                    <div className="loader" style={loaderTextStyle}><CircularProgress sx={{color:"#ed6d44"}}/></div>
                </Box>
            ) : (
                <Table sx={{ minWidth: 700 }} aria-label="customized table" className='tble'>
                    <TableHead className='DT-head'>
                        <TableRow>
                           
                            
                            <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Offer Type</strong></TableCell>
                            <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Coupon Name</strong></TableCell>
                            <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Discount Type</strong></TableCell>
                            <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Amount</strong></TableCell>
                            {/* <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Active From</strong></TableCell> */}
                            {/* <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Active To</strong></TableCell>  */}
                            {/* <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Products</strong></TableCell> */}
                            <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'><strong>Action</strong></TableCell>
                            {/* <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'><strong>Approved</strong></TableCell> */}
                            {/* <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Payment Status</strong></TableCell> */}
                            {/* <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Specialization</strong></TableCell> */}
                            {/* <TableCell style={{ minWidth: 180 }} align="left" className='tbl_head'><strong>Address</strong></TableCell> */}
                            {/* <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Phone</strong></TableCell> */}
                        </TableRow>
                    </TableHead>
                    
                        

                    <TableBody className="characters ">
                            {
                            
                            
                            coupons && coupons?.length > 0 ? coupons.map((row, i) => (
                                            <>
                                            <TableRow className=''>
                                                {/* <TableCell align="center" className='DT-row'>
                                                        {row.image
                                                        ? <img className="cat_image" src={process.env.REACT_APP_BASE_URL + row.image} alt='' height={50} width={50} />
                                                        : <PersonIcon style={{ height: 50, width: 50 }} />}
                                                </TableCell> */}
                                                <TableCell className='DT-row'>{row.offerType ? row.offerType : '-'}</TableCell>
                                                <TableCell className='DT-row'>{row.coupon_code ? row.coupon_code : '-'}</TableCell>
                                                <TableCell className='DT-row'>{row.discountType ? row.discountType : '-'}</TableCell>
                                                <TableCell align="left" className='DT-row'>{row.discountAmount ? `${row.discountAmount} ${row.discountType === "Percentage" ? " % " : " ₹ "}  ` : '-'}</TableCell>


                                                {/* <TableCell align="left" className='DT-row'>{row.activate ? moment(row.activate ).format('MMMM D, YYYY [at] h:mm A') : '-'}
                                                </TableCell>
                                                <TableCell align="left" className='DT-row'>{row.expiresAt ? moment(row.expiresAt ).format('MMMM D, YYYY [at] h:mm A') : '-'}
                                                </TableCell> */}

                                                {/* <TableCell className='DT-row'>
                                                    <IconButton onClick={(e) => handleClick(e, row)}> 
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </TableCell> */}
                                                {/* Course Preview */}
                                                {/* <TableCell className='DT-row'>
                                                    {row.fanspages && row.fanspages.length > 0 ? row.fanspages.join(', ') : '-'}
                                                </TableCell>

                                                <TableCell className='DT-row'>
                                                    {row.communities && row.communities.length > 0 ? row.communities.join(', ') : '-'}
                                                </TableCell> */}

                                                {/* Fans Preview */}
                                                {/* <TableCell className='DT-row'>
                                                    {row.fanspages && row.fanspages.length > 0 ? row.fanspages.join(', ') : '-'}
                                                </TableCell> */}

                                                 
                                                {/* Communities Preview */}
                                                {/* <TableCell className='DT-row'>
                                                    <Button onClick={(e) => handleClick(e, row, 'communities')}>
                                                        {row.communities && row.communities.length > 0 ? 'View Communities' : '-'}
                                                    </Button>
                                                </TableCell> */}

                                                {/* Fans Preview */}
                                                {/* <TableCell className='DT-row'>
                                                    <Button onClick={(e) => handleClick(e, row, 'fans')}>
                                                        {row.fanspages && row.fanspages.length > 0 ? 'View Fans' : '-'}
                                                    </Button>
                                                </TableCell> */}

                                                {/* Course Preview */}
                                                {/* <TableCell className='DT-row'>
                                                    <Button onClick={(e) => handleClick(e, row, 'courses')}>
                                                        {row.courses && row.courses.length > 0 ? 'View Courses' : '-'}
                                                    </Button>
                                                </TableCell> */}

                                                 {/* Products Column with Eye Icon */}
                            




                                                
                                        <TableCell align="center" className='DT-row' style={{ cursor: 'pointer' }}>
                                            <Box className="flex-center justify-content-center actionbtns">
                                                <Tooltip title="View">
                                                    <Box>
                                                        <IconButton onClick={(e) => handleClick(e, row)}> {/* Use a unique identifier for the row */}
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Tooltip>
                                                <Tooltip title="Edit">
                                                    <Box
                                                        className='a_tag'
                                                        onClick={() => EditData(row)}
                                                    >
                                                        <EditOutlinedIcon className='icons text-grey' />
                                                    </Box>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <Box>
                                                        <DeleteOutline className='icons text-grey'
                                                            onClick={() => handleOpen(row._id)}
                                                        />
                                                    </Box>
                                                </Tooltip>
                                                

                                            </Box>
                                        </TableCell>                        
                                            </TableRow>
                                            
                    
                                            </>
                                            
                                
                            )) : <TableRow className=''>
                                <TableCell className='DT-row' align="center" colSpan={5}>
                                    No Data Found
                                </TableCell>
                            </TableRow>
                            
                            }
                    </TableBody>
                    {/* Menu for Communities, Fans, or Courses */}
            
                    <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {currentData && (
                    <>
                        <div style={{ padding: '16px' }}>
                            {currentData.communities && currentData.communities.length > 0 && (
                                <>
                                    <Typography variant="h6" component="strong">Communities:</Typography>
                                    <List>
                                        {currentData.communities.map((community, index) => (
                                            <ListItem key={index}>{community}</ListItem>
                                        ))}
                                    </List>
                                </>
                            )
                            }
                            
                            {currentData.fanspages && currentData.fanspages.length > 0 && (
                                <>
                                    <Typography variant="h6" component="strong">Fans:</Typography>
                                    <List>
                                        {currentData.fanspages.map((fan, index) => (
                                            <ListItem key={index}>{fan}</ListItem>
                                        ))}
                                    </List>
                                </>
                            )}

                            {currentData.courses && currentData.courses.length > 0 && (
                                <>
                                    <Typography variant="h6" component="strong">Courses:</Typography>
                                    <List>
                                        {currentData.courses.map((course, index) => (
                                            <ListItem key={index}>{course}</ListItem>
                                        ))}
                                    </List>
                                </>
                            )}
                            
                            
                            {
                                currentData.offerType === 'cart_Level' && (
                                    <>
                                    <Typography variant="h6" component="strong">Minimun Amount:</Typography>
                                    <List>
                                        
                                            <ListItem >{`${currentData.minimum_amount}`}</ListItem>
                                        
                                    </List>
                                </>
                                )
                            }
                        </div>
                    </>
                )}
            </Menu>
                 
                </Table>
            )}
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                />
            </Paper>
            
            <Dialog 
            open={openDel} 
            keepMounted 
            onClose={handleCloseDel} 
            aria-describedby="alert-dialog-slide-description" 
            className='custom_modal'>

                <DialogTitle>{"Would you like to delete This Coupon?"}</DialogTitle>

                <DialogActions>
                    <Button onClick={handleCloseDel} className='cancel_btn'>No</Button>
                    <Button onClick={handleDelete} className='theme-btn'>Yes</Button>
                </DialogActions>
            </Dialog>
        </Box>
    </>
  )
}

export default Datatablecoupons