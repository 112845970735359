import { Box, Button, FormControl, FormControlLabel, Grid, List, ListItem, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip, Typography ,
  Dialog,
	DialogActions,	DialogTitle,DialogContent,
} from '@mui/material'
import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import courseImg from './../../../../../assets/images/Registrerbanner.png'
import { KeyboardArrowRight } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import MiniDrawer from '../../../../../components/drawer';
import axios from 'axios';
import courseprofile from '../../../../../../src/assets/images/courseimage.png'
import { enqueueSnackbar } from 'notistack';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import JSZip from 'jszip';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';

import { EditorState, convertToRaw,convertFromRaw } from 'draft-js';

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import LinearBuffer from './CourseDetail/content_uploader/LinearBuffer';
import LinearWithValueLabel from './LinearProgressWithLabel';
import Confetti from 'react-confetti';


// Custom styled tooltip
const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    padding: 0;
    background: none;
  }
  & .MuiTooltip-arrow {
    color: #ffffff;
    &::before {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
  }
`;

// Custom styled info icon
const StyledInfoIcon = styled(InfoIcon)`
  transition: all 0.3s ease;
  color: #666;
  &:hover {
    color: #2196f3;
    transform: scale(1.1);
  }
`;



const questions = [
	'Lorem Ipsum is simply dummy?',
	'Lorem Ipsum is simply dummy?',
	'Lorem Ipsum is simply dummy?'
]
const categories = [
	'Welcome',
	'First Lesson',
	'Second Sesson'
]
const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme }) => ({
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
	variants: [
		{
			props: ({ expand }) => !expand,
			style: {
				transform: 'rotate(0deg)',
			},
		},
		{
			props: ({ expand }) => !!expand,
			style: {
				transform: 'rotate(180deg)',
			},
		},
	],
}));

function BpRadio(props) {
	return (
		<Radio
			sx={{
				'&:hover': {
					bgcolor: 'transparent',
				},
			}}
			disableRipple
			color="default"
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			{...props}
		/>
	);
}
const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: '50%',
	width: 16,
	height: 16,
	boxShadow:
		theme.palette.mode === 'dark'
			? '0 0 0 1px rgb(16 22 26 / 40%)'
			: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
	backgroundImage:
		theme.palette.mode === 'dark'
			? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
			: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	'.Mui-focusVisible &': {
		outline: '2px auto rgba(19,124,189,.6)',
		outlineOffset: 2,
	},
	'input:hover ~ &': {
		backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background:
			theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
	},
}));

const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: '#f3714d',
	backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	'&:before': {
		display: 'block',
		width: 16,
		height: 16,
		backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		content: '""',
	},
	'input:hover ~ &': {
		backgroundColor: '#f3714d',
	},
});

export default function CreateCourse() {
	//UseState's:
	const [courseimageurl, setCourseImageUrl] = React.useState('')
	const [courseimage, setCourseImage] = React.useState('')
	const [coursetitle, setCourseTitle] = React.useState('');
	const [shortdescription, setShortDescription] = React.useState('')
	const [modeofcourse, setModeofCourse] = React.useState('');
	const [expandedStates, setExpandedStates] = useState([false]);
	const [uploadLoading,setUploadLoading] = React.useState(false);

	const [isPublished,setIsPublished] = React.useState(false);
	const [showConfetti, setShowConfetti] = React.useState(false);
	const [teacherName,setTeacherName] = React.useState('');
	const [festiveOffer, setFestiveOffer] = React.useState(false)
  const [truebutton,setTruebutton]=useState(false)

	const headerlocal = {
		'Content-Type': 'application/json',
		"Access-Control-Allow-Origin": "*",
		Authorization: localStorage.getItem("token")
	}
	const headerformdata = {
		'Content-Type': 'multipart/form-data',
		Authorization: localStorage.getItem("token")
	}
  const [Addon, setAddon] = React.useState(false);

	const [courseAddons, setcourseAddons] = React.useState([]);
	const [addonsModal, setAddonsModal] = React.useState(false);
	const [selectedCard, setSelectedCard] = useState(null);
	

	let navigate = useNavigate()
	let { id } = useParams()


	// DigitalDownloadForm;
	const [openDigitalDownloadBox,setOpenDigitalDownloadBox] =React.useState(false);

	// Publish Box Open;
	const [openPublishBox,setOpenPublishCourse] = React.useState(false);
	const [publishDeatils,setPublishDetails] = React.useState(null);

	//Teacher details:
	React.useEffect(()=>{
		GetTeacher()
	  },[])
	
	  const GetTeacher = () => {
		axios.get(process.env.REACT_APP_BASE_URL + `Admin/getTeachersbyid/${auth?._id}`)
		.then((res)=>{
		  setTeacherName(res.data.teachers[0].username);
		})
		.catch((err)=>{
		  console.log(err,"error in GetTeacher");
		})
	  }


    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());

	  //Text Editor function

	  const onEditorStateChange = (newEditorState) => {
        setInpError({ descriptionmsg: '' })
        setEditorState(newEditorState);
    };

	const getEditorContent = () => {
        const contentState = editorState?.getCurrentContent();
        const rawContent = convertToRaw(contentState);
        const plainText = contentState.getPlainText();
        return { rawContent, plainText };

    };

    const handleFestiveOffer = (e) => {
		
      setFestiveOffer(e.target.value);
  
    }

	const [paymenttype, setPaymentType] = React.useState('free')
	const [payingAmount, setPayingAmount] = React.useState('');
	const [file,setFile] = React.useState([])
	const [digiDownLoadId,setDigiDownLoad] = React.useState("")

	const [input, setInput] = React.useState([{ type: '', name: '', size: 0 }]);
  const [fileDetails,setFileDetails] = React.useState([{size:"",count:""}])

  const handleFileChange = async (e) => {
    
    const selectedFiles = Array.from(e.target.files); // Convert FileList to an array
    if (selectedFiles.length > 0) {
        const zip = new JSZip();
        const fileDetails = []; // Array to hold file details for preview
        let totalSizeInBytes = 0; // Variable to hold total size in bytes

        for (const selectedFile of selectedFiles) {
            // Validate file type if needed
            const extension = selectedFile.name.split('.').pop().toLowerCase();
            const fullName = selectedFile.name;
            const sizeInMB = (selectedFile.size / (1024 * 1024)).toFixed(2); // Converts bytes to MB

            // Add the file to the zip
            zip.file(fullName, selectedFile);

            // Push the file details for preview
            fileDetails.push({ type: extension, name: fullName, size: sizeInMB });

            // Accumulate the total size
            totalSizeInBytes += selectedFile.size;
        }

        // Calculate total size in MB
        const totalSizeInMB = (totalSizeInBytes / (1024 * 1024)).toFixed(2);

        // Add total size to the fileDetails array (optional)
        setFileDetails([{size: totalSizeInMB,count:fileDetails.length}])

        

        // Generate the zip file as a Blob
        const zipBlob = await zip.generateAsync({ type: 'blob' });

        // Create a new file from the Blob
        const zipFile = new File([zipBlob], 'files.zip', { type: 'application/zip' });

        // Update the input state with the file information
        setInput(fileDetails); // Update to show details of all files
        setFile(zipFile); // Set the zip file for uploading
    }
};

  console.log(input)

	  


	const handleExpandClick = (index) => {
		setExpandedStates((prev) =>
			prev.map((state, i) => (i === index ? !state : state))
		);
	};

	const auth = JSON.parse(localStorage.getItem("userData"))

	//Validation:
	const [error, setError] = React.useState({ titlemsg: '', modemsg: '',onetimemsg:"" });
	const [inperror, setInpError] = React.useState({ descriptionmsg: '', filemsg: '',paymentmsg:"" });


	const Validation = () => {
		if (!coursetitle) {
			setError({ titlemsg: 'This field is required' })
			return false
		}
		if (!modeofcourse) {
			setError({ modemsg: 'This field is required' })
			return false
		}
		
		return true
	}

	const validateEditorContent = () => {
		const content = editorState.getCurrentContent();
		return !content.hasText(); // Returns true if there's no text
	  };

	const DigiValidation = () =>{
		
		if(modeofcourse === "digitaldownload"){
			
			if(validateEditorContent()){
				setInpError({descriptionmsg:"This field is required"})
				return false;
			}
			
			if(!input[0].name){
			
				setInpError({filemsg:"This field is required"})
				return false;
			}
			if(paymenttype === "onetimepayment"){
				console.log("called")

				if(!payingAmount){
					setInpError({paymentmsg:"This field is required"})
					return false;
				}
			}
		}
		return true;
	}


	const handleImageChange = (e) => {
		const PreviewImage = URL.createObjectURL(e.target.files[0])
		const file = e.target.files[0];
		if (PreviewImage && file) {
			setCourseImageUrl(URL.createObjectURL(file));
			setCourseImage(file)
		}
	};


	const DigiDownloadCreateCourse = async () =>{
		setUploadLoading(true)
		if(DigiValidation()){
      setTruebutton(true)

			const { rawContent, plainText } = getEditorContent();
			console.log(digiDownLoadId)
			const url = `${process.env.REACT_APP_BASE_URL}Course1/addPaymentPricingforDigital/${digiDownLoadId}`;
			let formdata = new FormData()
			console.log(rawContent)
			formdata.append('rawdescription',JSON.stringify(rawContent) )
			formdata.append("plaindescription", plainText)
			formdata.append("course_paymenttype", paymenttype)
			if (file) {
				formdata.append(`files`, file);
				formdata.append(`name`, input[0].name);
				formdata.append(`size`, input[0].size);
			}
			
			if(paymenttype !== "free") formdata.append("amountOneTime", payingAmount)   
			formdata.append("currency", "USD")
			// if(file) formdata.append("files", file);
		
    
			try {
				const response = await axios.put(url,formdata,{
					headers:{
						"Content-Type": "multipart/form-data",
						  Authorization: localStorage.getItem("token"),
					}
				})
				enqueueSnackbar('Data Added Successfully',{variant:'success'})
				setOpenDigitalDownloadBox(false)
				setOpenPublishCourse(true)
				setPublishDetails(response.data.course)
				setIsPublished(response.data.course.publishCourse)
				console.log(response.data.course)
			} catch (error) {
				console.error("Error uploding course Digital Download Data", error);		
				enqueueSnackbar('Error uploading Digital Download Data',{variant:'error'})

			} finally{
				setUploadLoading(false)
        setTruebutton(false)
			}

			// setEditorState(EditorState.createEmpty())
			// setInput([{ type: '', name: '', size: 0 }])
			// setPayingAmount('')

		}
	}
	


	//Create course functionality:
	const AddCreatecourse = () => {
		let valid = Validation()
		if (valid == true) {
      setTruebutton(true)
			let formdata = new FormData()
			formdata.append('image',courseimage ? courseimage : '')
			formdata.append("course_title", coursetitle)
			formdata.append("shortDescription", shortdescription)
			formdata.append("mode_of_course", modeofcourse)
      formdata.append("addCourseFrom","Teacher")
			formdata.append("createdby[]", id)
      formdata.append("teacher[]", id);
      
			// formdata.append("festiveOffer", festiveOffer)
			console.log([...formdata])
			axios.post(process.env.REACT_APP_BASE_URL + 'Course1/AddingCourse', formdata)
				.then((res) => {
					
					if (res.data.creatcourse.mode_of_course === "digitaldownload") {
            enqueueSnackbar("Digital Download Created Successfully", {
              variant: "success",
            });
            setDigiDownLoad(res.data.creatcourse._id);
            setOpenDigitalDownloadBox(true);
            return;
          }
          if (res.data.creatcourse) {
            navigate(
              `/teachers/details/courses/details/${res.data.creatcourse._id}`
            );
          }

					enqueueSnackbar('Course Created Successfully',{variant:'success'})
					setCourseImageUrl('');
					setCourseImage('');
					setCourseTitle('');
				})
				.catch((error) => {
					console.log(error, "error in AddCreatecourse");
					enqueueSnackbar(error.response && error.response.data.error ? error.response.data.error : "Something went wrong.", { variant: 'error' })
          if (error.response.data?.status == 3) {
            setAddon(true);
            }
				})
        .finally(()=>{
          setTruebutton(false)
        })
		}
	}

	const handlePublishToggle = async (event) => {
		
		  const newPublishStatus = true;
			try {
				const response = await axios.put(
					process.env.REACT_APP_BASE_URL + `Course1/PublishCourse/${digiDownLoadId}`,
					{ publishCourse: true, publishername: teacherName }
				);
				setIsPublished(newPublishStatus);
				console.log(response.data)

	  
				if (newPublishStatus) {
					enqueueSnackbar('Course Published Successfully', { variant: 'success' });
					setShowConfetti(true); // Trigger confetti

					setTimeout(() => {
						navigate(`/teachers/details/course/${response.data.update._id}`)
					}, 3000);
					
				} else {
					enqueueSnackbar('Course Unpublished Successfully', { variant: 'success' });
				}
	  
				// Optionally, you can refresh course details after updating
			} catch (error) {
				console.error("Error updating publish status:", error);
				enqueueSnackbar('Error while Publishing Digital content', { variant: 'error' });

			
		  }
	  };
	  

	const handleUnPublishToggle = async (event) => {
		
			const newPublishStatus = false;
			try {
				const response = await axios.put(
					process.env.REACT_APP_BASE_URL + `Course1/PublishCourse/${digiDownLoadId}`, 
					{ publishCourse: false,publishername:teacherName }
				);
				setIsPublished(newPublishStatus);
	  
				// Use newPublishStatus for the notification
				if (newPublishStatus) {
					enqueueSnackbar('Course Published Successfully', { variant: 'success' });
				} else {
				 setShowConfetti(false)
					enqueueSnackbar('Course Unpublished Successfully', { variant: 'success' });
				}
	  
				// Optionally, you can refresh course details after updating
			} catch (error) {
				console.error("Error updating publish status:", error);
				enqueueSnackbar('Error while UnPublishing Digital content', { variant: 'error' });

			}
		
	  };

	
	const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };
	const trimmedDescription = publishDeatils?.plaindescription?.split(' ').slice(0, 20).join(' ') + (publishDeatils?.plaindescription?.split(' ').length > 20 ? '...' : '');

  const [isTextBoxOpen, setTextBoxOpen] = useState(true);
  const [text, setText] = useState('');

  const handleImageClick = () => {
    setTextBoxOpen(false);
  };

  const toggleTextBox = () => {
    setTextBoxOpen(!isTextBoxOpen);
  };


  const handlePurchaseAddons = async () => {
		var someData = auth
			? auth.planId?.addOns?.filter((item) => item.sectionName == "Course")
			: []; //display only events addons
		// var someData = auth ? auth.planId?.addOns : [] // display all addons
		setcourseAddons(someData);
		setAddonsModal(true);
		setAddon(false);
	};
	
	
	
	const handleAddonClose = () => {
		setAddon(false);
	};
	
	
	const AddonClose = () => {
		setAddonsModal(false);
	};
	
	const handleCardClick = (item) => {
		setSelectedCard(item);
	};
	
	const handleAddonSubmit = async () => {
		const body = {
			userId: auth._id,
			customerId: auth.stripeCustomerId,
			// AddOns : selectedCard // if selectedCard is an array
			AddOns: [selectedCard], // [selectedCard] because selectedCard is an object.. If multiple items can be selected push each item into selectedCard.
			redirectUrl:
				`https://${auth.name}.247sites.in/teachers/details/course/add/${auth._id}`,
		};
    await axios
			.put(
				process.env.REACT_APP_BASE_URL + `subscription/UpdateAddOnsByUserid`,
				body,
				{ headers: headerlocal }
			)
			.then((res) => {
				console.log(res.data?.PaymentLink, "update response console");
				window.open(res.data?.PaymentLink);
				AddonClose();
			})
			.catch((err) => {
				console.log(err, "UpdateAddOnsByUserid catch error");
			});
	};







	return (
    <Box className={auth.role_id === 1 ? "withSubmenu" : ""}>
      <MiniDrawer menuOrder={4} />
      <Box component="section" className="contentWraper">
        <Box className="headingBox">
          <Typography
            component="h4"
            variant="h4"
            className="Submenu-page-heading formTitle"
          >
            Course
          </Typography>
        </Box>

        <Box className="course firstScreen whiteContainer">
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="flex-center justify-content-center"
            >
              <Box className="h-100 p-20 leftContent">
                <Box>
                  <Typography component="h5" variant="h5" className="semibold">
                    Describe what you'd like to teach and we'll craft your
                    course
                  </Typography>
                  <Typography component="div" variant="body1" className="mt-30">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry
                  </Typography>
                </Box>
                <List className="w100 my-30">
                  {questions.map((question, index) => (
                    <ListItem className="p-0">
                      <ListItemText>
                        <Typography component="span" className="semibold">{`${
                          index + 1
                        }. ${question}`}</Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
                <Typography component="div" variant="body1" className="fz-14">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry
                </Typography>
              </Box>
            </Grid>

            {openDigitalDownloadBox && (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className="flex-center justify-content-center rightContent_grid"
              >
                <Box className="d-flex justify-content-center p-20 flex-col">
                  <Card className="mt-20 p-20">
                    <Typography
                      component="div"
                      className="label"
                      style={{ marginRight: 430 }}
                    >
                      Description{" "}
                      <Typography component="span" className="star">
                        {" "}
                        *
                      </Typography>
                    </Typography>

                    <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange}
                      editorStyle={{ minHeight: 300 }}
                    />
                    {inperror.descriptionmsg && (
                      <p style={{ color: "red" }} className="fz-13 m-0">
                        {inperror.descriptionmsg}
                      </p>
                    )}
                  </Card>

                  <Card
                    sx={{
                      display: "flex",

                      gap: 2,
                    }}
                    className="mt-20 p-20"
                  >
                    {/* Left section (1 part) */}
                    <Box sx={{ width: "25%" }}>
                      <Card sx={{ p: 2 }}>
                        <Box>
                          <Typography component="div" className="label">
                            Update File{" "}
                            <Typography
                              component="span"
                              sx={{ color: "error.main" }}
                            >
                              *
                            </Typography>
                          </Typography>

                          <Button
                            className="upload_btn"
                            variant="contained"
                            component="label"
                            fullWidth
                            sx={{ mt: 1 }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <FileUploadOutlinedIcon
                                sx={{ fontSize: 18, mr: 0.5 }}
                              />
                              Update File
                            </Box>
                            <input
                              //   accept={row.type === "Video" ? "video/*" :
                              //   row.type === "Audio" ? "audio/*" : "file/*"}
                              accept="file/*"
                              onChange={(e) => {
                                handleFileChange(e);
                                setInpError({ filemsg: "" });
                              }}
                              multiple
                              hidden
                              type="file"
                            />
                          </Button>
                        </Box>
                        {inperror.filemsg && (
                          <Typography
                            sx={{ color: "error.main", fontSize: 13, mt: 1 }}
                          >
                            {inperror.filemsg}
                          </Typography>
                        )}
                      </Card>
                    </Box>

                    {/* Right section (3 parts) */}
                    <Box sx={{ width: "75%" }}>
                      <Card sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                          {!fileDetails[0].size &&
                            !file.length &&
                            !input[0].type && (
                              <Grid
                                item
                                xs={6}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  minHeight: "100px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    minHeight: "100px",
                                  }}
                                >
                                  <Typography variant="body2">
                                    File Details
                                  </Typography>
                                </Box>
                              </Grid>
                            )}

                          {/* Left column */}
                          {fileDetails[0].size && (
                            <Grid item xs={6}>
                              <Box>
                                <Typography component="div" className="label">
                                  ZIP Name
                                </Typography>
                                <Typography
                                  component="div"
                                  sx={{ color: "error.main" }}
                                >
                                  {file.name}
                                </Typography>

                                <Typography
                                  component="div"
                                  className="label"
                                  sx={{ mt: 2 }}
                                >
                                  Total Size
                                </Typography>
                                <Typography
                                  component="div"
                                  sx={{ color: "error.main" }}
                                >
                                  {fileDetails[0]?.size &&
                                    `${fileDetails[0].size} MB`}
                                </Typography>
                              </Box>
                            </Grid>
                          )}

                          {/* Right column */}

                          <Grid item xs={6}>
                            {fileDetails[0].size && input[0].type && (
                              <Box>
                                <Typography component="div" className="label">
                                  Additional Details
                                </Typography>
                                <Typography variant="body2">
                                  List of Files:
                                </Typography>
                                <ul>
                                  {input.map((file, index) => (
                                    <li key={index}>{file.name}</li>
                                  ))}
                                </ul>
                                <Typography variant="body2">
                                  Count Of Files: {fileDetails[0].count}
                                </Typography>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Card>
                    </Box>
                  </Card>

                  <Card className="mt-20 p-20">
                    <Box className="mb-20 ">
                      <Typography
                        component="div"
                        className="label"
                        style={{ marginRight: 430 }}
                      >
                        Choose Payment Mode{" "}
                        <Typography component="span" className="star">
                          {" "}
                          *
                        </Typography>
                      </Typography>
                      <RadioGroup
                        row
                        aria-labelledby="demo-customized-radios"
                        name="payment_type"
                        onChange={(e) => {
                          setPaymentType(e.target.value);
                          setInpError({ paymentmsg: "" });
                        }}
                        value={paymenttype}
                      >
                        <FormControlLabel
                          value="free"
                          className="radio-btns"
                          control={<BpRadio />}
                          label={
                            <Typography className="fz-14">Free</Typography>
                          }
                        />

                        <FormControlLabel
                          value="onetimepayment"
                          className="radio-btns"
                          control={<BpRadio />}
                          label={
                            <Typography className="fz-14">Paid</Typography>
                          }
                        />
                      </RadioGroup>
                    </Box>

                    {paymenttype == "onetimepayment" && (
                      <Grid
                        container
                        columnSpacing={2}
                        rowSpacing={2}
                        className="mb-20"
                      >
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <FormControl variant="standard" fullWidth>
                            <Typography component="div" className="label">
                              Amount
                              <Typography component="span" className="star">
                                {" "}
                                *
                              </Typography>
                            </Typography>
                            <TextField
                              id="filled-size-small"
                              variant="outlined"
                              size="small"
                              className="textfield"
                              placeholder="Amount"
                              name="amount"
                              onInput={(e) => onlyNumbers(e)}
                              value={"$" + payingAmount}
                              // disabled={OTPayment}
                              sx={{
                                marginBottom: "5px",
                              }}
                              onChange={(e) => {
                                setPayingAmount(e.target.value);
                                setInpError({ paymentmsg: "" });
                              }}
                            />
                            {inperror.paymentmsg && (
                              <p style={{ color: "red" }} className="fz-13 m-0">
                                {inperror.paymentmsg}
                              </p>
                            )}
                          </FormControl>
                        </Grid>

                        {/* {
						
						OTPayment ? <Button className='theme-btn' sx={{width:"95%",margin:"auto"}} onClick={() => handlePaidEditDetails(paymenttype)} 
						>Update</Button> :
						    <Button className='theme-btn' sx={{width:"95%",margin:"auto"}} onClick={() => handlePaidDetails(paymenttype)}>Submit</Button>
							} */}
                      </Grid>
                    )}
                  </Card>

                  <Box className="text-right mt-30">
                    {/* <Button className="theme-btn" onClick={() => navigate('/teachers/details/courses/details')}>Create</Button> */}

                    {truebutton?
                     <Button
                     className="theme-btn"
                    
                   >
                     Please wait...
                   </Button>
                    :
                    <Button
                    className="theme-btn"
                    onClick={DigiDownloadCreateCourse}
                  >
                    Submit
                  </Button>
                    }
                   
                  </Box>
                </Box>
              </Grid>
            )}

            {openPublishBox && (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className="flex-center justify-content-center rightContent_grid"
              >
                <Box className="d-flex justify-content-center p-20 flex-col">
                  <Card style={{ width: "410px" }} className="mt-20 p-20">
                    <Box>
                      <Typography className="fz-16 text_black semibold">
                        Course Name
                      </Typography>
                      <Typography
                        component="h6"
                        variant="h6"
                        className="fz-16 text_black mb-10"
                      >
                        {publishDeatils.course_title}
                      </Typography>

                      <Typography className="fz-16 text_black semibold">
                        Course Description
                      </Typography>
                      <Typography
                        component="h6"
                        variant="h6"
                        className="fz-16 text_black mb-10"
                      >
                        {trimmedDescription}
                      </Typography>

                      <Typography className="fz-16 text_black semibold">
                        File Name
                      </Typography>
                      <Typography
                        component="h6"
                        variant="h6"
                        className="fz-16 text_black mb-10"
                      >
                        {publishDeatils.files[0].name
                          ? publishDeatils.files[0].name
                          : "NA"}
                      </Typography>

                      <Typography className="fz-16 text_black semibold">
                        Payment Type
                      </Typography>
                      <Typography
                        component="h6"
                        variant="h6"
                        className="fz-16 text_black mb-10"
                      >
                        {publishDeatils
                          ? publishDeatils.course_paymenttype
                          : "NA"}
                      </Typography>

                      <Typography className="fz-16 text_black semibold">
                        Course Fees
                      </Typography>
                      <Typography
                        component="h6"
                        variant="h6"
                        className="fz-16 text_black mb-10"
                      >
                        {publishDeatils.amountOneTime
                          ? `${publishDeatils.currency === "USD" ? `$` : ""} ${
                              publishDeatils.amountOneTime
                            } `
                          : "free"}
                      </Typography>

                      <FormControl
                        variant="standard"
                        fullWidth
                        className="mt-20 mb-20"
                      >
                        <Typography
                          component="div"
                          className="label fz-16 text_black semibold"
                        >
                          Would you like to include this FansPage in Festive
                          Offer?
                        </Typography>
                        <RadioGroup
                          row
                          aria-labelledby="demo-customized-radios"
                          name="payment_type"
                          onChange={handleFestiveOffer}
                          value={festiveOffer}
                        >
                          <FormControlLabel
                            value={true}
                            className="radio-btns"
                            control={<BpRadio />}
                            label={
                              <Typography className="fz-16">Yes</Typography>
                            }
                          />
                          <FormControlLabel
                            value={false}
                            className="radio-btns"
                            control={<BpRadio />}
                            label={
                              <Typography className="fz-16">No</Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>

                      {!isPublished ? (
                        <Button
                          variant="contained"
                          className="theme-btn"
                          onClick={(e) => handlePublishToggle(e)}
                        >
                          Publish Course
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          className="theme-btn"
                          onClick={(e) => handleUnPublishToggle(e)}
                        >
                          UnPublish Course
                        </Button>
                      )}
                      {showConfetti == true && (
                        <>
                          <Confetti
                            width={window.innerWidth}
                            height={window.innerHeight}
                            recycle={false}
                            numberOfPieces={500}
                          />
                        </>
                      )}

                      {/* <Typography component='div' className='label' >Course Name</Typography>
										<Typography component='span' >{publishDeatils.course_title}</Typography> */}
                    </Box>
                  </Card>
                </Box>
              </Grid>
            )}

            {!openDigitalDownloadBox && !openPublishBox && (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className="flex-center justify-content-center rightContent_grid"
              >
                <Box className="d-flex justify-content-center p-20 flex-col">
                  <Typography
                    component="h5"
                    variant="h5"
                    className="fw-bold mb-30"
                  >
                    Create Course
                  </Typography>

                  <Box>
                    <Typography component="body2">Course Image</Typography>

                    <StyledTooltip
                      open={isTextBoxOpen}
                      onClose={toggleTextBox}
                      title={
                        <Box
                          sx={{
                            bgcolor: "#ffffff",
                            p: 2,
                            borderRadius: "8px",
                            fontSize: "0.875rem",
                            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                            border: "1px solid rgba(0, 0, 0, 0.05)",
                            maxWidth: "220px",
                            animation: "fadeIn 0.3s ease-in-out",
                            position: "relative",
                            "&:hover": {
                              boxShadow: "0 6px 24px rgba(0, 0, 0, 0.15)",
                            },
                            "@keyframes fadeIn": {
                              from: {
                                opacity: 0,
                                transform: "translateY(-10px)",
                              },
                              to: {
                                opacity: 1,
                                transform: "translateY(0)",
                              },
                            },
                          }}
                        >
                          <Box
                            sx={{
                              position: "relative",
                              color: "#333",
                              fontWeight: 500,
                              lineHeight: 1.5,
                              "&::before": {
                                content: '""',
                                position: "absolute",
                                left: -2,
                                top: 0,
                                width: "3px",
                                height: "100%",
                                background:
                                  "linear-gradient(45deg, #2196f3, #64b5f6)",
                                borderRadius: "4px",
                              },
                            }}
                          >
                            <Box sx={{ pl: 2 }}>
                              Click on the image to add your course image
                            </Box>
                          </Box>
                        </Box>
                      }
                      placement="right"
                      arrow
                    >
                      <IconButton
                        onClick={toggleTextBox}
                        sx={{
                          padding: "8px",
                          marginBottom: "5px",
                          transition: "all 0.3s ease",
                          "&:hover": {
                            background: "rgba(33, 150, 243, 0.08)",
                          },
                        }}
                      >
                        <StyledInfoIcon fontSize="small" />
                      </IconButton>
                    </StyledTooltip>
                  </Box>

                  <Box sx={{ marginLeft: "150px" }}>
                    <label>
                      <Box
                        sx={{
                          width: 150,
                          height: 150,
                          borderRadius: "50%",
                          overflow: "hidden",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          border: "2px solid #ccc",
                          position: "relative",
                          "&:hover .overlay": {
                            opacity: 0.7,
                          },
                        }}
                        onClick={handleImageClick}
                      >
                        <img
                          src={courseimageurl || courseimage || courseprofile}
                          width={150}
                          height={150}
                          className="borderR50"
                          style={{
                            cursor: "pointer",
                            objectFit: "cover",
                            position: "relative",
                            zIndex: 1,
                          }}
                          alt="Course"
                        />
                        <Box
                          className="overlay"
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(0, 0, 0, 0.4)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            transition: "opacity 0.3s ease",
                            opacity: 0.5,
                            zIndex: 2,
                          }}
                        >
                          <AddIcon
                            sx={{
                              color: "white",
                              fontSize: "40px",
                              filter:
                                "drop-shadow(0px 2px 4px rgba(0,0,0,0.3))",
                            }}
                          />
                        </Box>
                        <input
                          accept="image/png, image/jpg, image/jpeg"
                          onChange={handleImageChange}
                          hidden
                          type="file"
                        />
                      </Box>
                    </label>
                  </Box>
                  {/* {isTextBoxOpen && (
          <Box style={{ marginTop: 10 }}>
             <Typography component="div" >click Here to Add Image</Typography>
          </Box>
        )} */}

                  <FormControl fullWidth variant="standard" className="mb-20">
                    <Typography component="div" className="label">
                      Course Title{" "}
                      <Typography component="span" className="star">
                        *
                      </Typography>
                    </Typography>
                    <TextField
                      id="filled-size-small"
                      variant="outlined"
                      size="small"
                      className="textfield"
                      name="coursetitle"
                      value={coursetitle}
                      onChange={(e) => {
                        setCourseTitle(e.target.value);
                        setError({ titlemsg: "" });
                      }}
                    />
                    {error.titlemsg && (
                      <p style={{ color: "red" }} className="fz-13 m-0">
                        {error.titlemsg}
                      </p>
                    )}
                  </FormControl>

                  <FormControl variant="standard" fullWidth>
                    <Typography component="div" className="label">
                      Description
                    </Typography>
                    <textarea
                      // placeholder='Long Description '
                      className="textarea"
                      rows={4}
                      cols={40}
                      value={shortdescription}
                      onChange={(e) => setShortDescription(e.target.value)}
                    />
                  </FormControl>

                  <FormControl variant="standard" fullWidth className="mt-20">
                    <Typography component="div" className="label">
                      Course Mode{" "}
                      <Typography component="span" className="star">
                        *
                      </Typography>
                    </Typography>
                    <RadioGroup
                      row
                      aria-labelledby="demo-customized-radios"
                      name="coursemode"
                      value={modeofcourse}
                      onChange={(e) => {
                        setModeofCourse(e.target.value);
                        setError({ modemsg: "" });
                      }}
                    >
                      <FormControlLabel
                        value="online"
                        className="radio-btns"
                        control={<BpRadio />}
                        label={
                          <Typography className="fz-14 semibold">
                            Online
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="prerecorded"
                        className="radio-btns"
                        control={<BpRadio />}
                        label={
                          <Typography className="fz-14 semibold">
                            Pre Recorded
                          </Typography>
                        }
                      />

                      <FormControlLabel
                        value="digitaldownload"
                        className="radio-btns"
                        control={<BpRadio />}
                        label={
                          <Typography className="fz-14 semibold">
                            Digital Download
                          </Typography>
                        }
                      />

                      <FormControlLabel
                        value="Challenges"
                        className="radio-btns"
                        control={<BpRadio />}
                        label={
                          <Typography className="fz-14 semibold">Challenges</Typography>
                        }
                      />
                    </RadioGroup>
                    {error.modemsg && (
                      <p style={{ color: "red" }} className="fz-13 m-0">
                        {error.modemsg}
                      </p>
                    )}
                  </FormControl>

                  <Box className="text-right mt-30">
                    {/* <Button className="theme-btn" onClick={() => navigate('/teachers/details/courses/details')}>Create</Button> */}
                    
                    
                  {truebutton?
                  <Button
                  className="theme-btn"
                  
                >
                  Please wait...
                </Button>
                :
                <Button
                      className="theme-btn"
                      onClick={() => AddCreatecourse()}
                    >
                      Create
                    </Button>
                }
                    
                    
                    
                  </Box>

                  {/* -------------------------------***************------------------------ */}

                  {/* <Card sx={{ maxWidth: 345 }} className='p-20'>
						<Typography component='div' variant="body2" className='semibold fz-18 mb-10'>
							Course Title
						</Typography>
						<CardMedia
							component="img"
							height="194"
							image={courseImg}
							alt="Paella dish"
						/>
						<CardContent className='p-0 my-10'>
							<Typography component='div' variant="body2" className='semibold fz-18 mb-10'>
								Course Description
							</Typography>
							<Typography variant="body2" sx={{ color: 'text.secondary' }}>
								This impressive paella is a perfect party dish and a fun meal to cook
								together with your guests. Add 1 cup of frozen peas along with the mussels,
								if you like.
							</Typography>
						</CardContent>
						<Typography component='div' variant="body2" className='semibold fz-18 mb-20'>
							Introduction
						</Typography>
						<CardActions disableSpacing className='p-0 flex-col'>

							{categories.map((category, i) =>
								<Box className="w100 flex-between">
									<Typography variant="body2" className='text_black semibold' >
										{category}
									</Typography>
									<ExpandMore
										// expand={expandedStates[i]}
										// onClick={() => handleExpandClick(i)}
										aria-expanded={expandedStates[i]}
										aria-label="show more"
									>
										<KeyboardArrowRight className='text_black' />
									</ExpandMore>
								</Box>
							)}

						</CardActions> 

						
							{categories.map((category, i) => (
							<Collapse key={i} in={expandedStates[i]} timeout="auto" unmountOnExit>
								<CardContent>
									<Typography >Lorem Ipsum is simply dummy</Typography>
								</CardContent>
							</Collapse>
						))} 
							</Card> */}

                  {/* -------------------------------***************------------------------ */}
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>

      <Dialog
					open={Addon}
					keepMounted
					onClose={handleAddonClose}
					aria-describedby="alert-dialog-slide-description"
					className="custom_modal"
				>
					<DialogTitle>Would you like to purchase Course Addons?</DialogTitle>
					<DialogActions>
						<Button onClick={handleAddonClose} className="cancel_btn">
							No
						</Button>
						<Button onClick={handlePurchaseAddons} className="theme-btn">
							Yes
						</Button>
					</DialogActions>
				</Dialog>
				
				
				
				
				
				
				
				
				
				
				
					<Dialog open={addonsModal} onClose={AddonClose} maxWidth="sm" fullWidth>
					<DialogTitle>Select Details</DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							{courseAddons && courseAddons.length > 0 ? (
								courseAddons.map((item, index) => (
									<Grid item xs={12} sm={6} md={4} key={index}>
										<Card
											variant="outlined"
											sx={{
												cursor: "pointer",
												"&:hover": { boxShadow: 3 },
												border:
													selectedCard?.count === item?.count
														? "2px solid #1976d2"
														: "1px solid #e0e0e0",
											}}
											onClick={() => handleCardClick(item)}
										>
											<CardContent>
												<Typography variant="h6">{item.sectionName}</Typography>
												<Typography variant="body1">
													Amount Type: {item.amount_type}
												</Typography>
												<Typography variant="body1">
													Amount: {item.amount}
												</Typography>
												<Typography variant="body1">
													Count: {item?.count}
												</Typography>
											</CardContent>
										</Card>
									</Grid>
								))
							) : (
								<Grid container justifyContent="center" alignItems="center">
									<Grid item xs={12} sm={6} md={4}>
										<Card
											variant="outlined"
											sx={{
												cursor: "pointer",
												"&:hover": { boxShadow: 3 },
												mt: 5,
											}}
										>
											<CardContent>
												<Typography variant="body1">
													Currently your plan don't have any addons
												</Typography>
											</CardContent>
										</Card>
									</Grid>
								</Grid>
							)}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={AddonClose} color="primary">
							Cancel
						</Button>
						{courseAddons && courseAddons.length > 0 ? (
							<Button
								onClick={handleAddonSubmit}
								color="primary"
								variant="contained"
								disabled={!selectedCard}
							>
								Submit
							</Button>
						) : null}
					</DialogActions>
				</Dialog>


    </Box>
  );
}
