import React, { useState } from "react"
// import { BusinessOutlined, Lock, Person } from '@mui/icons-material';
import {  Lock, Person } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, IconButton, CircularProgress } from '@mui/material'
import axios from 'axios'
import { VisibilityOff, Visibility } from '@mui/icons-material';
// import { useNavigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack'
import contactusLogo from './../../assets/images/contactusLogo.png';
// import firebase from 'firebase/compat/app';
// import { deleteToken, getMessaging, getToken } from 'firebase/messaging';
import { getMessaging,getToken } from 'firebase/messaging';
// import { initializeApp } from 'firebase/app';

// import { messaging, setupNotifications } from "../../firebase";
// import { messaging } from "../../firebase";


export default function TeacherLogin() {
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar()
    const [userdata, setUserData] = React.useState({ email: "", password: "" })
    const [error, setError] = useState({ email: "", password: "" })
    const [fcmkey, setFcmKey] = useState('')
    const [loading,setLoading] = useState(false)
    const messaging = getMessaging();
    
    let hostname= window.location.hostname;
    

    const [values, setValues] = React.useState({
        showPassword: false,
        showRePassword: false
    });

    // const handleClickShowPassword = () => {
    //     setValues({
    //         ...values,
    //         showPassword: !values.showPassword,
    //     });
    // };

    const handleClickShowRePassword = () => {
        setValues({
            ...values,
            showRePassword: !values.showRePassword,
        });
    };

    // let uname, uvalue
    const handleChange = (e) => {
        setUserData({ ...userdata, [e.target.name]: e.target.value })
        setError({ ...error, [e.target.name]: "" })
    }


    // const validator = () => {
    //     const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    //     if (!userdata.email && !userdata.password) {
    //         setError({ email: "Email cannot be empty", password: "Password cannot be empty" })
    //         return false
    //     }

    //     if (!userdata.email) {
    //         setError({ email: "Email  cannot be empty" });
    //         return false
    //     }

    //     if (!userdata.email.match(emailRegex)) {
    //         setError({ email: "Email is invalid" });
    //         return false
    //     }
    //     if (!userdata.password) {
    //         setError({ password: "Password cannot be empty" });
    //         return false
    //     }
    //     return true
    // }

    const validator = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
        if (!userdata.email && !userdata.password) {
            setError({ email: "Email cannot be empty", password: "Password cannot be empty" })
            return false
        }
        if (!userdata.email) {
            setError({ email: "Email cannot be empty" });
            return false
        }
        if (!userdata.email.match(emailRegex)) {
            setError({ email: "Email is invalid" });
            return false
        }
        if (!userdata.password) {
            setError({ password: "Password cannot be empty" });
            return false
        }
        return true
    }

    React.useEffect(() => {
        const fetchData = async () => {
          try {
            const currentToken = await getToken(messaging, { vapidKey: "BCch_dBcI75By1vAAUqNoKNe5elsltpcncqVeMSTPvoZTQtgT149-zC1Pf9l52One6Bh9cvZR1rZoqOeoVR9rKo" });
            if (currentToken) {
              setFcmKey(currentToken);
            } else {
              console.log('No FCM token available.');
            }
          } catch (error) {
            console.error('Error getting FCM token:', error);
          }
        };
      
        fetchData(); // Call the async function
      
        // Note: Dependency array is empty, meaning this effect runs only once after mount
      
      }, [messaging]);


//     React.useEffect(()=>{
//         const requestPermissionAndGetToken = async () => {
//             try {
//                 const currentToken = await getToken(messaging, { vapidKey: "BCch_dBcI75By1vAAUqNoKNe5elsltpcncqVeMSTPvoZTQtgT149-zC1Pf9l52One6Bh9cvZR1rZoqOeoVR9rKo"});
//                 if (currentToken) {
//                     console.log('Current token for FCM:', currentToken);
//                     // Send the token to your server or store it for later use
//                 } else {
//                     console.log('No registration token available. Request permission to generate one.');
//                 }
//             } catch (error) {
//                 console.error('An error occurred while retrieving token. ', error);
//             }
            
//         // getToken(messaging,{vapidKey:"BCch_dBcI75By1vAAUqNoKNe5elsltpcncqVeMSTPvoZTQtgT149-zC1Pf9l52One6Bh9cvZR1rZoqOeoVR9rKo"})
//         }
//         requestPermissionAndGetToken();
// },[messaging]);
    
      

    // React.useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const currentToken = await getToken(messaging, { vapidKey: "BCch_dBcI75By1vAAUqNoKNe5elsltpcncqVeMSTPvoZTQtgT149-zC1Pf9l52One6Bh9cvZR1rZoqOeoVR9rKo" });
    //             console.log(currentToken,'currentToken')
    //             if (currentToken) {
    //                 setFcmKey(currentToken);
    //             } else {
    //                 console.log('No FCM token available.');
    //             }
               
    //         } catch (error) {
    //             console.error('Error getting FCM token:', error);
    //         }
    //     };

        // fetchData(); // Call the async function

        // Note: Dependency array is empty, meaning this effect runs only once after mount

    // }, []);



    // const handleLogin = (e) => {
    //     e.preventDefault();
    //     var validate = validator();

    //     if (validate) {
    //         var body = {
    //             email: userdata.email,
    //             password: userdata.password,
    //             FCM_token: fcmkey,
    //         }
    //         console.log(body, "bodddd");
    //         axios.post(process.env.REACT_APP_BASE_URL + "Admin/TeacherLogin", body).then((res) => {
    //             localStorage.setItem("userData", JSON.stringify(res.data.teacher))
    //             localStorage.setItem("token", res.data.token)
    //             console.log(res.data, 'Teacher login details');
    //             // enqueueSnackbar("Logged in successfully", { variant: 'success' })         
    //             navigate("/events")
    //         }).catch((err) => {
    //             setError({ password: err.response ? err.response.data.error : "Something went wrong" })
    //             enqueueSnackbar(err.response ? err.response.data.error : "Something went wrong", { variant: 'error' });

    //             console.log(err)
    //         })
    //     }
    // }

    // const handleLogin = (e) => {
    //     e.preventDefault();
    //     var validate = validator();

    //     if (validate) {
    //         var body = {
    //             email: userdata.email,
    //             password: userdata.password,
    //             FCM_token: fcmkey,
    //         }
    //         console.log(body, "bodddd");
    //         axios.post(process.env.REACT_APP_BASE_URL + "Admin/TeacherLogin", body).then((res) => {
    //             localStorage.setItem("userData", JSON.stringify(res.data.teacher))
    //             localStorage.setItem("token", res.data.token)
    //             console.log(res.data, 'Teacher login details');
    //             enqueueSnackbar("Logged in successfully", { variant: 'success' })
    //             navigate(`/teachers/details/events/${res.data.teacher._id}`)
    //         }).catch((err) => {
    //             setError({ email: "", password: "" });

    //             if (err.response) {
    //                 if (err.response.data.error.includes("Email")) {
    //                     setError({ ...error, email: err.response.data.error });
    //                 } else if (err.response.data.error.includes("Password")) {
    //                     setError({ ...error, password: err.response.data.error });
    //                 } else {
    //                     setError({ ...error, password: err.response.data.error });
    //                 }
    //                 enqueueSnackbar(err.response.data.error, { variant: 'error' });
    //             } else {
    //                 setError({ ...error, password: "Something went wrong" });
    //                 enqueueSnackbar("Something went wrong", { variant: 'error' });
    //             }
    //         })
    //     }
    // }

    // const handleLogin = (e) => {
    //     e.preventDefault();
    //     const validate = validator();
    
    //     if (validate) {
    //         const body = {
    //             email: userdata.email,
    //             password: userdata.password,
    //             FCM_token: fcmkey,
    //         };
    //         console.log(body, "bodddd");
    //         axios.post(process.env.REACT_APP_BASE_URL + "Admin/TeacherLogin", body)
    //             .then((res) => {
    //                 if (!res.data.teacher.SetVerifyTeacher) {
    //                     enqueueSnackbar("Your account has not been approved by the admin.", { variant: 'error' });
    //                     return;
    //                 }
    //                 localStorage.setItem("userData", JSON.stringify(res.data.teacher));
    //                 localStorage.setItem("token", res.data.token);
    //                 console.log(res.data, 'Teacher login details');
    //                 enqueueSnackbar("Teacher Login Successfully", { variant: 'success' });
    //                 navigate(`/teachers/details/events/${res.data.teacher._id}`);
    //             })
    //             .catch((err) => {
    //                 setError({ email: "", password: "" });
    
    //                 if (err.response) {
    //                     if (err.response.data.error.includes("Email")) {
    //                         setError({ ...error, email: err.response.data.error });
    //                     } else if (err.response.data.error.includes("Password")) {
    //                         setError({ ...error, password: err.response.data.error });
    //                     } else {
    //                         setError({ ...error, password: err.response.data.error });
    //                     }
    //                     enqueueSnackbar(err.response.data.error, { variant: 'error' });
    //                 } else {
    //                     setError({ ...error, password: "Something went wrong" });
    //                     enqueueSnackbar("Something went wrong", { variant: 'error' });
    //                 }
    //             });
    //     }
    // };


    const handleLogin = async (e) => {
        setLoading(true);
        e.preventDefault();
        const validate = validator();
        if(!validate){
            setLoading(false)
            return;
        }
    
        if (validate) {
            const body = {
                email: userdata.email,
                password: userdata.password,
                FCM_token: fcmkey,
                hostname:hostname
            };
            
            
            try {
                const res = await axios.post(process.env.REACT_APP_BASE_URL + "Admin/TeacherLogin", body);
        
             
                if (!res.data.teacher.SetVerifyTeacher) {
                    enqueueSnackbar("Your account has not been approved by the admin.", { variant: 'error' });
                    return;
                }
        
                if (res.data.teacher.isTwoFactorAuthentocated === true) {
                    enqueueSnackbar("OTP has been sent to your Mail address", { variant: 'success' });
                    navigate("/login",{ state: { body: body } });
                    setLoading(false)

                }else{
                    
                localStorage.setItem("userData", JSON.stringify(res.data.teacher));
                localStorage.setItem("token", res.data.token);
                
                enqueueSnackbar("Teacher Login Successfully", { variant: 'success' });
        
                // Construct the dynamic subdomain URL
                const username = res.data.teacher.username; 
                // Adjust if the username field is different
                const subdomainUrl = `https://${username}.247sites.in/dashboard/${res.data.teacher._id}`;
                // Redirect to the constructed subdomain URL
                window.location.href = subdomainUrl;
                
                // navigate(`/dashboard/${res.data.teacher._id}`);
                }
        
                
        
            } catch (err) {
                setError({ email: "", password: "" });
        
                if (err.response) {
                    console.log(err.response);
                    if (err.response.data.error.includes("Email")) {
                        
                        setError((prevError) => ({ ...prevError, email: err.response.data.error }));
                    } else if (err.response.data.error.includes("Password")) {
                        
                        setError((prevError) => ({ ...prevError, password: err.response.data.error }));
                    } else {
                        
                        setError((prevError) => ({ ...prevError, password: err.response.data.error }));
                    }
                    enqueueSnackbar(err.response.data.error, { variant: 'error' });
                } else {
                    
                    setError((prevError) => ({ ...prevError, password: "Something went wrong" }));
                    enqueueSnackbar("Something went wrong", { variant: 'error' });
                }
            }finally{
                setLoading(false)
            }
        }
    };
    
    


    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleLogin(e)
        }
    }

    return (
        <Box className='login'>
            <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className='login_img'>
                        <Box className='logintext_box'>
                            <h1 className='heading semibold'>Yoga</h1>
                            <Typography component='p' variant='body1' className='subheading'>The rhythm of the body, the melody of the mind, and the harmony of the soul create the symphony of life.</Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className='Login_content'>
                        <Box className="header_main_left">
                            <img src={contactusLogo} alt="pic" height={100} />
                        </Box>
                        <Typography component='h4' variant='h4'>Teachers Login</Typography>
                        <Box className='form'>

                            <FormControl variant="standard">
                                <Typography component='div' className='label'>Email</Typography>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    className='textfield'
                                    placeholder='Email'
                                    name='email'
                                    autoComplete='off'
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Person className='start_icon' /></InputAdornment>,
                                    }}
                                />
                                {error.email && <p style={{ color: 'red' }} className="fz-13 m-0">{error.email}</p>}
                            </FormControl>
                            <FormControl variant="standard">
                                <Typography component='div' className='label'>Password</Typography>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    placeholder='Password'
                                    type={values.showRePassword ? 'text' : 'password'}
                                    className='textfield'
                                    name='password'
                                    onKeyDown={_handleKeyDown}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Lock className='start_icon' /></InputAdornment>,
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowRePassword}
                                                    edge="end">
                                                    {values.showRePassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />
                                {error.password && <p style={{ color: 'red' }} className="fz-13 m-0">{error.password}</p>}
                            </FormControl>
                        </Box>
                        {
                            loading ? <Button className='loginbtn' disabled ><CircularProgress sx={{color:"white"}}/></Button>:
                            <Button className='loginbtn' onClick={handleLogin}>Login</Button>
                        }
                        
                        {/* <Box className="logintype">
                            <Box className='a-link'>
                                <Typography component='a' href='/'>Login as Admin </Typography>
                            </Box>
                            <Box className='a-link'>
                                    <Typography component='a' href='/forgetPassword'>Forgot your password?</Typography>
                            </Box> 
                        </Box> */}
                        </Box>
                </Grid>
            </Grid>
        </Box>
    )
}