import React, { useState } from 'react'
import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, FormControl, IconButton } from '@mui/material';
import MiniDrawer from '../../../../../components/drawer'
import { useLocation, useParams } from 'react-router-dom'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw,convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CloseIcon from '@mui/icons-material/Close';





import { styled } from '@mui/system';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import DownloadButton from './DownloadButton';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: '15px',
  },
  '& .MuiDialog-paper': {
    width: '70%',  // Set width to 70% of the screen width
    height: '70%', // Set height to 70% of the screen height
    maxWidth: 'none', // Prevent maxWidth restriction
  },
}));


const QnAViewPage = () => {
    // const { id, state } = useLocation();
    const {id}=useParams();


    const auth = JSON.parse(localStorage.getItem("userData"));

    const [question, setQuestion] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [answer, setAnswer] = useState("");

    const [file, setFile] = useState(null);
    const [error, setError] = useState({});

    const [editorState, setEditorState] = React.useState(
      EditorState.createEmpty()
    );

    //Text Editor function

    const onEditorStateChange = (newEditorState) => {
      //   setInpError({ descriptionmsg: '' })
      setEditorState(newEditorState);
    };

    const getEditorContent = () => {
      const contentState = editorState?.getCurrentContent();
      const rawContent = convertToRaw(contentState);
      const plainText = contentState.getPlainText();
      return { rawContent, plainText };
    };

    //Hook's State:
    React.useEffect(() => {
      getQuestion();
    }, []);

    // Fetch questions
    const getQuestion = () => {
      axios
        .get(
          process.env.REACT_APP_BASE_URL +
            `Questions/GetQuestionsToteacher/${auth._id}`
        )
        .then((res) => {
          let question = res.data.questions.find((q) => q._id === id);
          
          if (question && question.answers) {
            // Map through each answer and update the content to EditorState
            const updatedAnswers = question.answers.map((answer) => {
              let editorState = null;
    
              // Check if answer.content exists and is a valid raw JSON string
              if (answer.content) {
                try {
                  // Parse the content as raw JSON and convert it to contentState
                  const contentState = convertFromRaw(JSON.parse(answer.content));
                  editorState = EditorState.createWithContent(contentState); // Create EditorState
                } catch (error) {
                  console.error("Error parsing content:", error);
                }
              }
    
              // Return the answer with the modified editorState attached
              return {
                ...answer,  // Keep all other properties of the answer intact
                editorState, // Add the editorState property
              };
            });
    
            // Update the question's answers with the modified answers
            question.answers = updatedAnswers;
          }
          setQuestion(question);
        })
        .catch((err) => {
          console.log(err, "error in CourseTable");
        });
    };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // File size validation (Max 20MB)
      if (selectedFile.size > 20 * 1024 * 1024) {
        setError({ ...error, file: "File size should not exceed 20MB" });
      } else {
        setError({ ...error, file: "" });
        setFile(selectedFile);
      }
    }
  };

    // Handle file removal (clear the file state)
    const handleRemoveFile = () => {
      setFile(null);  // Clear the selected file
      setError({ ...error, file: "" });  // Clear any file-related errors
    };

  const handleSubmitAnswer = () => {
    // Editor validation
    const content = editorState.getCurrentContent();
    if (!content.hasText()) {
      setError({ ...error, editor: "Please provide an answer" });
      return;
    }

    // If there's an uploaded file and it has a validation error
    if (file && file.size > 20 * 1024 * 1024) {
      setError({ ...error, file: "File size should not exceed 20MB" });
      return;
    }

    // Create FormData object
    const formData = new FormData();
    const rawContent = convertToRaw(content);  // Convert editor state to raw content
    formData.append("content", JSON.stringify(rawContent));  // Save raw content as a JSON strin
    // formData.append("content", content.getPlainText());
    formData.append("userId", auth._id);
    if (file) formData.append("attachments", file);
    
    
    // Call your API endpoint
    submitAnswer(formData);
  };


  const submitAnswer = async (formData) => {
    axios.post(process.env.REACT_APP_BASE_URL + `Questions/adminAddAnswer/${id}`, formData)
      .then((res)=>{
        setEditorState(EditorState.createEmpty());
        setFile(null);
        setOpenDialog(false);
        getQuestion();   
        enqueueSnackbar('Your Answer is added Successfully.',{variant:'success'});    
      })
      .catch((err)=>{
        console.log("erro while add the data", err);
      enqueueSnackbar('Something went wrong. Please check your input.', { variant: 'error' });
      })
  };  

    const handleDialogOpen = () => {
        setOpenDialog(true);
      };
    
      const handleDialogClose = () => {
        setOpenDialog(false);
      };

  return (
    <>
      <Box className="companies dashboard">
        <MiniDrawer menuOrder={14} />
        <Box component="section" className="contentWraper">
          <Box className="headingBox">
            <Typography
              component="h4"
              variant="h4"
              className="Submenu-page-heading formTitle"
            >
              Question And Answer
            </Typography>
          </Box>
          <Box className="whiteContainer">
            <Box component="div" className="p-10">
              <Typography
                component="h4"
                variant="h4"
                className="Submenu-page-heading"
              >
                Question : {question?.title}
              </Typography>
            </Box>
            <Box component="div" className="p-10">
              <Typography component="span" variant="span">
                Description : {question?.description}
              </Typography>
            </Box>
            <Box className="whiteContainer">
              {/* Map through the answers array */}
              {question?.answers?.map((answer) => (
                <Box
                  key={answer._id}
                  sx={{
                    border: "1px solid #ccc",
                    padding: 2,
                    margin: 2,
                    borderRadius: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h6"
                    className="answer-username"
                  >
                    <strong>{answer?.user.username}</strong>
                  </Typography>
                  <Typography variant="body2" className="answer-email">
                    <em>{answer?.user.email}</em>
                  </Typography>
                  <Typography variant="body1" sx={{ marginTop: 1 }}>
                    {
                      <Editor
                        editorState={
                          answer.editorState || EditorState.createEmpty()
                        } // Use the stored editorState or an empty one
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        readOnly={true} // Set readOnly to true to prevent editing when displaying answers
                        editorStyle={{ minHeight: 100 }}
                        toolbarHidden={true} // Hides the toolbar completely
                      />
                    }
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ marginTop: 1, color: "gray" }}
                  >
                    {new Date(answer.createdAt).toLocaleString()}
                  </Typography>
                  {answer?.attachments && (
                    <DownloadButton
                      downloadUrl={
                        process.env.REACT_APP_BASE_URL + `${answer.attachments}`
                      }
                      fileName={answer.attachments.split("/").pop()}
                    />
                  )}
                </Box>
              ))}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 2,
                  marginRight: 2,
                  marginBottom: 2,
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleDialogOpen}
                  className="addbtn"
                >
                  Answer
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Dialog Box for Answering */}
      <BootstrapDialog
        onClose={handleDialogClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        maxWidth="md"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Submit Your Answer
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* <TextField
                label="Your Answer"
                fullWidth
                multiline
                rows={4}
                value={answer}
                onChange={handleAnswerChange}
                variant="outlined"
              /> */}

              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                editorStyle={{ minHeight: 100 }}
              />
              {error.editor && (
                <Typography color="error" variant="body2">
                  {error.editor}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              {!file && (
                <FormControl variant="standard" fullWidth>
                  <label className="form-labels label" for="my-input">
                    Upload Attachment{" "}
                    <Typography component="span" className="star">
                      {" "}
                    </Typography>
                  </label>
                  <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                    <Button
                      className="multiple_upload_btn"
                      variant="contained"
                      component="label"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
                      </Box>
                      <input
                        name="file"
                        accept="*/*" // Accept all file types
                        onChange={handleFileChange}
                        hidden
                        type="file"
                      />
                    </Button>
                  </Box>
                  {error.file && (
                    <Typography color="error" variant="body2">
                      {error.file}
                    </Typography>
                  )}
                </FormControl>
              )}

              {/* Show File Preview Box if a file is selected */}
              {file && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 2,
                    border: "1px solid #ddd",
                    padding: 1,
                    borderRadius: 1,
                  }}
                >
                  <Box>
                    <div>
                      <strong>File Details:</strong>{" "}
                    </div>
                    <div>
                      <strong>File Name:</strong>{" "}
                      {/* {getFileDetails(formDataForAnswer.attachments)?.name} */}
                      {file.name}
                    </div>
                    <div>
                      <strong>File Type:</strong>{" "}
                      {/* {getFileDetails(formDataForAnswer.attachments)?.type} */}
                      {file.type}
                    </div>
                    <div>
                      <strong>File Size:</strong>{" "}
                      {/* {getFileDetails(formDataForAnswer.attachments)?.size} */}
                      {file.size}
                    </div>
                  </Box>
                  <IconButton onClick={handleRemoveFile} color="error">
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            color="primary"
            className="cancel_btn"
          >
            Cancel
          </Button>
          <Button onClick={handleSubmitAnswer} color="primary">
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default QnAViewPage