import React from 'react'
import MiniDrawer from '../../../../../components/drawer'
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, Dialog,
	DialogActions,	DialogTitle,DialogContent,Card,CardContent,
	 } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { useNavigate, useParams } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';
import { usePlacesWidget } from "react-google-autocomplete";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function AddJob() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),

    }
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const user = JSON.parse(localStorage.getItem('userData'))
    const [category, setCategory] = React.useState({ subcategoryname: "", description: "" })
    const [error, setError] = React.useState({ job_created: "", job_title: "", company_name: "", job_type: "", hours_of_work: "", city: "", skill_required: "", experience_required: "", natureofjob: "", minsalary: "", maxsalary: "", no_of_positions: "" })
    const [truebutton, setTruebutton] = React.useState(false)
    const [details, setDetails] = React.useState({ job_created: "", job_title: "", company_name: "", area: "", city: "", skill_required: "", natureofjob: "", minsalary: "", maxsalary: "", preffered_certificate: "", no_of_positions: "", description: "", currency: '₹' })
    const [hours_of_work, setHours_of_work] = React.useState('');
    const [startdate, setStartDate] = React.useState(dayjs());
    const [enddate, setEndDate] = React.useState(dayjs());
    const [job_type, setJob_type] = React.useState('')
    //console.log(user,"useruseruser")

    const [Addon, setAddon] = React.useState(false);
	
	const [jobAddons, setjobAddons] = React.useState([]);
	const [addonsModal, setAddonsModal] = React.useState(false);
	
	const [selectedCard, setSelectedCard] = React.useState(null);

    const params = useParams()

    // let name, value;
    const handledetails = (e) => {
        const { name, value } = e.target;

        // Handle formatting for salary fields
        let formattedValue = value;
        if (name === 'minsalary' || name === 'maxsalary') {
            formattedValue = value.replace(/,/g, ''); // Remove commas if present

            // Format number with commas
            if (!isNaN(formattedValue)) {
                formattedValue = Number(formattedValue).toLocaleString();
            }
        }
        setDetails({ ...details, [name]: formattedValue });
        setError('');
        
    }

    const handleCurrencyChange = (e) => {
        setDetails(prevState => ({
            ...prevState,
            currency: e.target.value
        }));
    };

    const Validator = () => {
        if (!details.job_title && !details.company_name && !job_type && !hours_of_work && !details.city && !details.skill_required && !details.experience_required && !details.natureofjob && !details.minsalary && !details.maxsalary && !details.no_of_positions) {
            setError({ job_title: "This field is required.", company_name: "This field is required.", job_type: "This field is required.", hours_of_work: "This field is required.", city: "This field is required.", skill_required: "This field is required.", experience_required: "This field is required.", natureofjob: "This field is required.", minsalary: "This field is required.", maxsalary: "This field is required.", no_of_positions: "This field is required.", })
            return false
        }
        if (!user) {
            setError({ job_title: "This field is required." })
            return false
        }
        if (!details.job_title) {
            setError({ job_title: "This field is required." })
            return false
        }
        if (!details.company_name) {
            setError({ company_name: "This field is required." })
            return false
        }
        if (!job_type) {
            setError({ job_type: "This field is required." })
            return false
        }
        if (!hours_of_work) {
            setError({ hours_of_work: "This field is required." })
            return false
        }
        if (!details.city) {
            setError({ city: "This field is required." })
            return false
        }
        if (!details.skill_required) {
            setError({ skill_required: "This field is required." })
            return false
        }
        if (!details.experience_required) {
            setError({ experience_required: "This field is required." })
            return false
        }
        if (!details.natureofjob) {
            setError({ natureofjob: "This field is required." })
            return false
        }
        if (!details.minsalary) {
            setError({ minsalary: "This field is required." })
            return false
        }
        if (!details.maxsalary) {
            setError({ maxsalary: "This field is required." })
            return false
        }
        if (!details.no_of_positions) {
            setError({ no_of_positions: "This field is required." })
            return false
        }
        return true
    }
    // React.useEffect(() => {
    //     getCategory()
    // }, [])
    // const getCategory = async () => {
    //     await axios.get(process.env.REACT_APP_BASE_URL + `Category/GetCategory`, { headers: headerlocal })
    //         .then((res) => {
    //             setRecordings(res.data.category)
    //             // console.log(res.data.category,"category");
    //         }).catch((error) => {
    //             console.log(error, "Error ");
    //         })
    // }

    // const handleFromDateTimeChange = (newValue) => {
    // 	console.log(newValue);
    // 	setHours_of_work(newValue);
    // 	setError(prevError => ({ ...prevError, fromTime: '' }))
    // };


    const handleSubmit = async () => {
        var Valid = Validator()
        if (Valid) {
            var body = {
                "jobApproveByAdmin":false,
                "jobCreated_by": user._id,
                "Job_title": details.job_title,
                "company_name": details.company_name,
                "Job_type": job_type,
                "description": details.description,
                "hours_projectwise": hours_of_work,
                "start_date": startdate,
                "end_date": enddate,
                "area": details.area,
                "city": details.city,
                "skill_set_required": details.skill_required,
                "experience": details.experience_required,
                "nature_of_the_job": details.natureofjob,
                "max_salary": details.maxsalary,
                "min_salary": details.minsalary,
                "preferred_certifications": details.preffered_certificate,
                "number_of_position_open": details.no_of_positions,
                "currency": details.currency
            }
            
            await axios.post(process.env.REACT_APP_BASE_URL + `Jobs/Addjobs`, body, { headers: headerlocal }).then((res) => {
                setTruebutton(false)
                navigate(`/teachers/details/career/${params.id}`)
                enqueueSnackbar("Job Details added successfully", { variant: 'success' })
                setDetails({ job_title: "", company_name: "", hours_of_work: "", area: "", city: "", skill_required: "", experience_required: "", natureofjob: "", minsalary: "", maxsalary: "", preffered_certificate: "", no_of_positions: "" })
                setJob_type('')

            }).catch((error) => {
                setTruebutton(false)

                enqueueSnackbar(error.response && error.response.data.error ? error.response.data.error : "Something went wrong.", { variant: 'error' });

                if (error.response.data?.status == 3) {
                    setAddon(true);
                    }
                console.log(error.response, "error");
            })
        }
    }
    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };


    const handlePurchaseAddons = async () => {
		var someData = user
			? user.planId?.addOns?.filter((item) => item.sectionName == "Jobs")
			: []; //display only events addons
		// var someData = auth ? auth.planId?.addOns : [] // display all addons
		setjobAddons(someData);
		setAddonsModal(true);
		setAddon(false);
	};
	
	
	
	const handleAddonClose = () => {
		setAddon(false);
	};
	
	
	const AddonClose = () => {
		setAddonsModal(false);
	};
	
	const handleCardClick = (item) => {
		setSelectedCard(item);
	};
	
	const handleAddonSubmit = async () => {
		const body = {
			userId: user._id,
			customerId: user.stripeCustomerId,
			// AddOns : selectedCard // if selectedCard is an array
			AddOns: [selectedCard], // [selectedCard] because selectedCard is an object.. If multiple items can be selected push each item into selectedCard.
			redirectUrl:
				 `https://${user.name}.247sites.in/teachers/details/career/addjobdetails/${user._id}`,
		};
    await axios
			.put(
				process.env.REACT_APP_BASE_URL + `subscription/UpdateAddOnsByUserid`,
				body,
				{ headers: headerlocal }
			)
			.then((res) => {
				console.log(res.data?.PaymentLink, "update response console");
				window.open(res.data?.PaymentLink);
				AddonClose();
			})
			.catch((err) => {
				console.log(err, "UpdateAddOnsByUserid catch error");
			});
	};








    return (
        <Box className=''>
            <MiniDrawer menuOrder={12} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle mb-20'>Add Job Details</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Job Title<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Job Title'
                                                name="job_title"
                                                value={details.job_title}
                                                onChange={handledetails}

                                            />
                                            {error.job_title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.job_title}</p>}

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Company Name<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Company Name'
                                                name="company_name"
                                                value={details.company_name}
                                                onChange={handledetails}
                                            />
                                            {error.company_name && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.company_name}</p>}

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={12}>
                                        <FormControl fullWidth >
                                            <Typography component='div' className='label'>Select Job Type <Typography component='span' className='star'>*</Typography></Typography>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={job_type ? job_type : "sel"}
                                                placeholder="Select Job Type"
                                                className="select"
                                                onChange={(e) => { setJob_type(e.target.value); setError({ job_type: "" }) }}
                                            >
                                                <MenuItem disabled value={'sel'}>Select Job Type</MenuItem>
                                                <MenuItem value={"parttime"}>Part Time</MenuItem>
                                                <MenuItem value={"fulltime"}>Full Time</MenuItem>
                                            </Select>
                                            {error.job_type && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.job_type}</p>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Description</Typography>
                                            <textarea
                                                className='textarea'
                                                id="filled-size-small"
                                                variant="outlined"
                                                placeholder='Description'
                                                name="description"
                                                value={details.description}
                                                onChange={handledetails}
                                                rows={4}
                                                cols={40}
                                                style={{resize:'none'}}
                                            />

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={12}>
                                        <FormControl fullWidth >
                                            <Typography component='div' className='label'>Hours/Project Wise<Typography component='span' className='star'>*</Typography></Typography>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={hours_of_work ? hours_of_work : "sel"}
                                                placeholder="Select Hours/Project Wise"
                                                className="select"
                                                onChange={(e) => { setHours_of_work(e.target.value); setError({ hours_of_work: "" }) }}
                                            >
                                                <MenuItem disabled value={'sel'}>Select Job Type</MenuItem>
                                                <MenuItem value={"hours"}>Hours</MenuItem>
                                                <MenuItem value={"project_wise"}>Project Wise</MenuItem>
                                            </Select>
                                            {error.hours_of_work && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.hours_of_work}</p>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth className='datepicker'>
                                            <Typography component='div' className='label'> Start Date</Typography>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                    <DatePicker
                                                        value={startdate}
                                                        onChange={(newValue) => setStartDate(newValue)}
                                                        minDate={dayjs()}
                                                        format="DD-MM-YYYY"
                                                        className='textfield w100'
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                            {/* {error.subcategoryname && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.subcategoryname}</p>} */}

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth className='datepicker'>
                                            <Typography component='div' className='label'> End Date</Typography>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                    <DatePicker
                                                        // label="Date"
                                                        value={enddate}
                                                        onChange={(newValue) => setEndDate(newValue)}
                                                        minDate={dayjs()}
                                                        format="DD-MM-YYYY"
                                                        className='textfield w100'
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                            {/* {error.subcategoryname && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.subcategoryname}</p>} */}

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Area</Typography>
                                            <textarea
                                                className='textarea'
                                                // style={{ textAlign:"center" }}
                                                id="filled-size-small"
                                                variant="outlined"
                                                placeholder='Address'
                                                name="area"
                                                value={details.area}
                                                onChange={handledetails}
                                                rows={4}
                                                cols={40}
                                                style={{resize:'none'}}
                                            />

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>City
                                            <Typography component='span' className='star'>*</Typography>
                                            </Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='City'
                                                name="city"
                                                value={details.city}
                                                onChange={handledetails}
                                            // InputProps={{
                                            //     startAdornment: <InputAdornment position="start"></InputAdornment>,
                                            // }}
                                            />
                                            {error.city && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.city}</p>}

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Skill Set Required<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                className='textfield'
                                                //style={{ width: '100%' }}
                                                id="filled-size-small"
                                                size='small'
                                                variant="outlined"
                                                placeholder='Skill Set Required'
                                                name="skill_required"
                                                value={details.skill_required}
                                                onChange={handledetails}
                                            // rows={4}
                                            // cols={40}
                                            />
                                            {error.skill_required && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.skill_required}</p>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Experience Required<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                className='textfield'
                                                //style={{ width: '100%' }}
                                                id="filled-size-small"
                                                size='small'
                                                variant="outlined"
                                                placeholder='Experience Required'
                                                name="experience_required"
                                                value={details.experience_required}
                                                onInput={(e) => onlyNumbers(e)}
                                                onChange={handledetails}
                                            // rows={4}
                                            // cols={40}
                                            />
                                            {error.experience_required && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.experience_required}</p>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Nature of Work<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Nature of Work'
                                                value={details.natureofjob}
                                                name="natureofjob"
                                                onChange={handledetails}
                                            // InputProps={{
                                            //     startAdornment: <InputAdornment position="start"></InputAdornment>,
                                            // }}
                                            />
                                            {error.natureofjob && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.natureofjob}</p>}

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Minimum Salary

                                            <Typography component='span' className='star'>*</Typography>
                                            </Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Minimum Salary'
                                                value={details.minsalary}
                                                name="minsalary"
                                                onInput={(e) => onlyNumbers(e)}
                                                onChange={handledetails}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            $
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            {error.minsalary && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.minsalary}</p>}

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Maximum Salary

                                            <Typography component='span' className='star'>*</Typography>
                                            </Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Maximum Salary'
                                                value={details.maxsalary}
                                                name="maxsalary"
                                                onInput={(e) => onlyNumbers(e)}
                                                onChange={handledetails}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            $
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            {error.maxsalary && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.maxsalary}</p>}

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Preferred Certifications</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Preffered Certifications'
                                                name="preffered_certificate"
                                                value={details.preffered_certificate}
                                                onChange={handledetails}
                                            // InputProps={{
                                            //     startAdornment: <InputAdornment position="start"></InputAdornment>,
                                            // }}
                                            />
                                            {/* {error.subcategoryname && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.subcategoryname}</p>} */}

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>No of Positions<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='No of Positions '
                                                name="no_of_positions"
                                                value={details.no_of_positions}
                                                onInput={(e) => onlyNumbers(e)}
                                                onChange={handledetails}
                                            // InputProps={{
                                            //     startAdornment: <InputAdornment position="start"></InputAdornment>,
                                            // }}
                                            />
                                            {error.no_of_positions && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.no_of_positions}</p>}

                                        </FormControl>
                                    </Grid>

                                </Grid>
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    <Button variant="contained" className='addbtn ' onClick={handleSubmit}>Save</Button>
                                </Box>
                            </Box>

                            <Dialog
					open={Addon}
					keepMounted
					onClose={handleAddonClose}
					aria-describedby="alert-dialog-slide-description"
					className="custom_modal"
				>
					<DialogTitle>Would you like to purchase Job Addons?</DialogTitle>
					<DialogActions>
						<Button onClick={handleAddonClose} className="cancel_btn">
							No
						</Button>
						<Button onClick={handlePurchaseAddons} className="theme-btn">
							Yes
						</Button>
					</DialogActions>
				</Dialog>
				
				
				
				
				
				
				
				
				
				
				
					<Dialog open={addonsModal} onClose={AddonClose} maxWidth="sm" fullWidth>
					<DialogTitle>Select Details</DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							{jobAddons && jobAddons.length > 0 ? (
								jobAddons.map((item, index) => (
									<Grid item xs={12} sm={6} md={4} key={index}>
										<Card
											variant="outlined"
											sx={{
												cursor: "pointer",
												"&:hover": { boxShadow: 3 },
												border:
													selectedCard?.count === item?.count
														? "2px solid #1976d2"
														: "1px solid #e0e0e0",
											}}
											onClick={() => handleCardClick(item)}
										>
											<CardContent>
												<Typography variant="h6">{item.sectionName}</Typography>
												<Typography variant="body1">
													Amount Type: {item.amount_type}
												</Typography>
												<Typography variant="body1">
													Amount: {item.amount}
												</Typography>
												<Typography variant="body1">
													Count: {item?.count}
												</Typography>
											</CardContent>
										</Card>
									</Grid>
								))
							) : (
								<Grid container justifyContent="center" alignItems="center">
									<Grid item xs={12} sm={6} md={4}>
										<Card
											variant="outlined"
											sx={{
												cursor: "pointer",
												"&:hover": { boxShadow: 3 },
												mt: 5,
											}}
										>
											<CardContent>
												<Typography variant="body1">
													Currently your plan don't have any addons
												</Typography>
											</CardContent>
										</Card>
									</Grid>
								</Grid>
							)}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={AddonClose} color="primary">
							Cancel
						</Button>
						{jobAddons && jobAddons.length > 0 ? (
							<Button
								onClick={handleAddonSubmit}
								color="primary"
								variant="contained"
								disabled={!selectedCard}
							>
								Submit
							</Button>
						) : null}
					</DialogActions>
				</Dialog>




                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}