import React, { useEffect, useRef, useState } from "react";
import Box from '@mui/material/Box';
import Check from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Stepper from '@mui/material/Stepper';
import { Button, Tooltip, CircularProgress, FormControl, Input, Checkbox, ListItemText, Grid, Select, RadioGroup, Radio, FormGroup, Grow, InputAdornment, OutlinedInput, MenuItem, MenuList, Popper, TextField, FormControlLabel } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { usePlacesWidget } from "react-google-autocomplete";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PropTypes from 'prop-types';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CompareIcon from '@mui/icons-material/Compare';
import { useCommunity } from "../Context/CommunityContext";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Call, Category, Close, Dvr, ListAlt, WidthFull } from '@mui/icons-material';
import AutoComplete from "./Autocomplete";
import EmailEditor from "react-email-editor";
import Sample from "./sample.json"
import DOMPurify from 'dompurify';
import './CommunityEvent.css'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const steps = ['Basic Information', 'Event Information', 'Event Description'];

export default function DataTable({ params }) {
    const [communityData, setCommunityData] = useState(null)
    
  
	
	console.log(communityData)
    // console.log(params, 'paramschild1');
    const auth = JSON.parse(localStorage.getItem('userData'))
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor:
                    '#f3714d',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor:
                    '#f3714d',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundColor:
                '#f3714d',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundColor:
                '#f3714d',
        }),
    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            1: <ListAlt />,
            2: <CompareIcon />,
            3: <Dvr />,
        };
        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    ColorlibStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
        /**
         * The label displayed in the step icon.
         */
        icon: PropTypes.node,
    };

    function BpRadio(props) {
        return (
            <Radio
                sx={{
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                }}
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                {...props}
            />
        );
    }
    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#f3714d',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#f3714d',
        },
    });


    const { communityEvent, addCommunityEvent, fetchCommunity } = useCommunity();
    console.log(communityEvent, "communityEventcommunityEvent");
    const [buttondisable, setButtondisable] = React.useState(false)
    const [staffdata, setEventdata] = React.useState([])
    const [selecttedEvent, setSelecttedEvent] = React.useState([])
    const [activeStep, setActiveStep] = React.useState(0);
    const navigate = useNavigate()
    const [event, setEvent] = React.useState([])
    const [status, setStatus] = useState('')
    const [catError, setCatError] = React.useState('')
    const [subcatError, setSubCatError] = React.useState('')
    const [imageError, setImageError] = React.useState('')
    const [tag, setTag] = React.useState([])
    const [categoryId, setCategoryId] = React.useState('')
    const [subcategoryId, setSubCategoryId] = React.useState('')
    const [statesingle, setStatesingle] = React.useState([])
    const [statesingle2, setStatesingle2] = React.useState([])
    const [selectedFromDateTime, setSelectedFromDateTime] = useState(dayjs(new Date()).add(3,'hours'));
    const [selectedToDateTime, setSelectedToDateTime] = useState(dayjs(new Date()).add(3,'hours'));
    const [category, setCategory] = React.useState([])
    const [description, setDescription] = React.useState('')
    const [subcat, setSubcat] = React.useState([])
    const [state1, setState1] = React.useState([])
    const [state2, setState2] = React.useState([])
    const [imageAddError, setImageAddError] = React.useState('')
    const [selectedVideo, setSelectedVideo] = useState([]);
    const [videoPreviewUrl, setVideoPreviewUrl] = useState([]);
    const [addDisable, setAddDisable] = React.useState(false)
    const [cityName, setCityName] = useState('')
    const [lat, setLat] = useState(0.0)
    const [lng, setLng] = useState(0.0)
    const [selectGallert, setSelectGallery] = useState('Image')
    const [addEventFrom, setaddEventFrom] = useState('CommunityTeacher')
    const { enqueueSnackbar } = useSnackbar()
    const [tagError, setTagError] = useState('')
    const [truebutton, setTruebutton] = React.useState(false)

    const [error, setError] = React.useState({ eventName: "", status: "", link: '', organizer: "", fromTime: "", toTime: "", video: "", location: "" })
    const [newevent, setNewEvent] = React.useState({ eventName: '', description: "", fees: "", organizer: "", specialization: "", address: "" })
    const [designEditor, setDesignEditor] = useState(Sample)
    const emailEditorRef = useRef({ editor: {} });
    const [newTag, setNewTag] = useState('');


    const onLoad = () => {
        emailEditorRef.current.editor.loadDesign(designEditor);
    };

    useEffect(() => {
        // Retrieve and parse community data from local storage when the component mounts
        const communityDataString = localStorage.getItem('communityData');
        if (communityDataString) {
            setCommunityData(JSON.parse(communityDataString));
        }
    }, []);

   
    
    console.log(selecttedEvent, 'selecttedEventselecttedEvent');
    React.useEffect(() => {
        getCategory()
    }, [])

    React.useEffect(() => {
        getSubCategory()
    }, [categoryId])

    const getCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `Category/GetCategory`, { headers: headerlocal }).then((res) => {
            setCategory(res.data.category)
        }).catch((error) => {
            console.log(error.response, "error");
        })
    }
    const getSubCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `SubCategory/GetSubcategorybasedonCategoryId?category_id=${categoryId}`, { headers: headerlocal })
            .then((res) => { setSubcat(res.data.subcategory) }).catch((error) => {
                console.log(error.response, "error");
            })
    }

    const handleRemoveRestart = () => {
        setSubCategoryId('')
    }

    let urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
    const validate = () => {
        if (!newevent.eventName && !status && statesingle2?.length == 0 && !newevent.organizer) {
            setError({ eventName: "This field is required.", status: "This field is required.", organizer: "This field is required." })
            setCatError("This field is required.")
            setImageError("This field is required.")
            return false
        }
        if (!newevent.eventName) {
            setError({ eventName: "This field is required." })
            return false
        }
        if (!status) {
            setError({ status: "This field is required." })
            return false
        }
        if (status === "offline") {
            if(!cityName){
                setError({ location: "This field is required." })
                return false;
            }
        }

            if (status === "online") {
                if(!newevent.link){
                    setError({ link: "This field is required." })
                    return false;
                }
            
        }
        if (!categoryId) {
            setCatError("This field is required.")
            return false
        }
        if (!newevent.organizer) {
            setError({ organizer: "This field is required." })
            return false
        }
        // if (currentDate <= selectedFromDateTime ) {
        // 	setError({ fromTime: "Please select a date and time greater than the present date and time." })
        // 	return false
        // }
        // if (selectedFromDateTime < selectedToDateTime ) {
        // 	setError({ toTime: "Please select a date and time greater than the from date and time." })
        // 	return false
        // }
        if (statesingle2?.length == 0) {
            setImageError("This field is required.")
            return false
        }

        return true
    }

    const multipleImgChange = (e, index) => {
        setImageAddError('');

        // Validate file size
        const maxSize = 1024 * 1024; // 1MB in bytes
        const files = e.target.files;
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        for (let i = 0; i < files.length; i++) {
            if (files[i].size > maxSize) {
                // Handle file size error (for example, set an error state)
                setImageAddError('Image size should be less than 1 MB.');
                continue; // Skip this file
            }

            fileObj.push(files[i]);
            fileArray.push(URL.createObjectURL(files[i]));
            filepath.push(files[i]);
        }

        var data = state1.filter(x => x != null);
        var data1 = state2.filter(x => x != null);
        setState1(data);
        setState2(data1);
        setState1(prevValue => prevValue.concat(fileArray));
        setState2(prevValue => prevValue.concat(filepath));
    }

    const indexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
    }


    const SingleImage = (e, index) => {
        setImageError('');
        setError('');

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files);

        for (let i = 0; i < fileObj[0].length; i++) {
            const img = new Image();
            img.src = URL.createObjectURL(fileObj[0][i]);

            img.onload = function () {
                if (fileObj[0][i].size > 1000000) { // 1 MB in bytes
                    setImageError('Image size should be less than 1 MB.');
                } else {
                    fileArray.push(URL.createObjectURL(fileObj[0][i]));
                    filepath.push(fileObj[0][i]);

                    var data = statesingle.filter(x => x != null);
                    var data1 = statesingle2.filter(x => x != null);
                    setStatesingle(data);
                    setStatesingle2(data1);
                    setStatesingle(prevValue => prevValue.concat(fileArray));
                    setStatesingle2(prevValue => prevValue.concat(filepath));
                }
            };
        }
    };

    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }

    // const { ref, autocompleteRef } = usePlacesWidget({
    //     // need to change this API key 
    //     apiKey: 'AIzaSyB-U-riOWcHLeZOeh197bv_RGfF4mF6Jj8',
    //     onPlaceSelected: (place) => {
    //         setCityName(place.formatted_address)
    //         setLat(place.geometry.location.lat());
    //         setLng(place.geometry.location.lng());
    //         // setError(prevError => ({ ...prevError, location: '' }))
    //     },
    //     options: {
    //         types: "(regions)" | 'establishment'
    //     },
    // });
    const handleFromDateTimeChange = (newValue) => {
        // console.log(newValue);
        setSelectedFromDateTime(newValue);
        // setError(prevError => ({ ...prevError, start_date: '' }))
    };
    const handleToDateTimeChange = (newValue) => {
        setSelectedToDateTime(newValue);
        // setError(prevError => ({ ...prevError, end_date: '' }))
    };

    React.useEffect(() => {
        getTags()
    }, [])

    const getTags = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Tags/GetTags`, { headers: headerlocal });
            setTag(response.data.Tag);
            console.log(response, "tags");
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    const [selecttedTag, setSelecttedTag] = React.useState([])
    const [tagName, setTagName] = React.useState([])
    const handlechangeTag = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
            target: { value },
        } = event;
        setTagName(
            typeof value === 'string' ? value.split(',') : value,
        );
        getTagval()
    }
    React.useEffect(() => {
        getTagval()
    }, [tagName])

    function getTagval() {
        var array = []
        const selecttedTag = tag.filter(content => tagName.includes(content.tagname));
        // console.log(selecttedTag,'selecttedTagselecttedTagselecttedTag');
        selecttedTag.map((item, i) => {
            var val = { tagname: item.tagname }
            Object.assign(item, val)
            array.push(val)
        })
        setSelecttedTag(array)
    }

   

    React.useEffect(() => {
        fetchCommunity(params.id);
    }, [params.id]);


    let name, value;
    const handlechange = (e) => {
        name = e.target.name;
        value = e.target.value;
        setNewEvent({ ...newevent, [name]: value })
        setError('')
        // console.log("Name:", name);
        // console.log("Value:", value);
    }


    const handleEditor = (e) => {
        const unlayer = emailEditorRef.current?.editor;
        unlayer?.exportHtml((data) => {
            // const { design, html } = data;
            const { design } = data;
            // const sanitizedHtml = DOMPurify.sanitize(html);
            const editor = { "body": design.body }
            setDesignEditor(editor)
            enqueueSnackbar('Saved changes', { variant: 'info' });
        })
    }

    // React.useEffect(() => {
    //     // Retrieve and parse community data from local storage when the component mounts
    //     const communityDataString = localStorage.getItem('communityData');
    //     if (communityDataString) {
    //         setCommunityData(JSON.parse(communityDataString));
    //     }
    // }, []);
    const[Tableloading,setTableloading]=React.useState(true)

  
    React.useEffect(() => {
        if(communityData){

            getEvent()
        }
    }, [communityData])

    const getEvent = async () => {

setTableloading(true)
        try {
            await axios.get(process.env.REACT_APP_BASE_URL + `Event/GetEvent?createdby=${params.id}`, { headers: headerlocal }).then((res) => {
                console.log(res.data)
                res.data.event && res.data.event.forEach((item) => {
                    // Check if communityData is available and has event_id
                    const isSelected = communityData && communityData.event_id 
                        ? communityData.event_id.some(event => event.event_id === item._id)
                        : false;
                    if (isSelected) {
                        setSelecttedEvent(prevState => [...prevState, {

                            specialization: item.specialization,
                            event_id: item._id, 
                            event_name: item.event_name, 
                            Category_id: item.Category_id, 
                            Subcategory_id: item.Subcategory_id, 
                            allimages: item.allimages, 
                            event_Organizer: item.event_Organizer,
                            status: item.status, tags: item.tags, 
                            ToTime: item.ToTime, 
                            fromTime: item.fromTime, 
                            formatted_address: item.formatted_address, 
                            image: item.image, 
                            shortDescription: item.shortDescription,
                           
                        }]);
                    }
                    console.log(isSelected, 'isSelectedisSelected');
                    var value = { selectEvent: isSelected }
                    Object.assign(item, value)
                })
                // console.log(res.data, 'res.datares.datares.data');
                setEvent(res.data.event)
            }).catch((err) => console.log(err, "error"))

        } catch (error) {
            console.log(error.response, "error");
        }
        finally{
            setTimeout(() => {
                setTableloading(false);
            }, 2000);
        }
    }

    
console.log(event)


    const habdleSelectEvent = async (e, item, i) => {
        var updatedEvents = [...event];
        var value = item.selectEvent
        if (value === false) {
            updatedEvents[i].selectEvent = true;
            setEvent(updatedEvents)
            setSelecttedEvent(prev => prev.concat({ 
                specialization: item.specialization, 
                event_id: item._id, 
                event_name: item.event_name, 
                Category_id: item.Category_id, 
                Subcategory_id: item.Subcategory_id, 
                allimages: item.allimages, 
                event_Organizer: item.event_Organizer, 
                status: item.status, 
                tags: item.tags, 
                ToTime: item.ToTime, 
                fromTime: item.fromTime, 
                formatted_address: item.formatted_address,
                image: item.image, 
                shortDescription: item.shortDescription ,
            }))
        
        }
        if (value === true) {
            var data1 = selecttedEvent.filter((val) => val.event_id !== item._id)
            setSelecttedEvent(data1)
            updatedEvents[i].selectEvent = false;
            setEventdata(updatedEvents)
            
        }
    }

    //     // Toggle selectEvent property
    //     updatedEvents[i].selectEvent = !updatedEvents[i].selectEvent;

    //     // Update event state to reflect selection
    //     setEvent(updatedEvents);

    //     if (updatedEvents[i].selectEvent) {
    //         // If event is selected, add it to selecttedEvent state
    //         setSelecttedEvent(prev => [
    //             ...prev,
    //             {
    //                 event_name: item.event_name,
    //                 event_id: item._id,
    //                 shortDescription: item.description,
    //                 event_image: item.image,
    //                 specialization: item.specialization
    //             }
    //         ]);
    //     } else {
    //         // If event is deselected, remove it from selecttedEvent state
    //         const updatedSelectedEvents = selecttedEvent.filter((val) => val.event_id !== item._id);
    //         setSelecttedEvent(updatedSelectedEvents);
    //     }
    // };

    const handleEditGallery = (e) => {
        setSelectGallery(selectGallert === 'Image' ? 'Video' : 'Image')

    }

    const handleAddNewTag = () => {
        if (true) {
            let body = {
                tagname: newTag,
                createdby: auth && auth._id
            };
            axios.post(process.env.REACT_APP_BASE_URL + 'tags/addTags', body, headerformdata)
                .then((res) => {
                    enqueueSnackbar("Tag added successfully", { variant: 'success' });
                    console.log(res, "tags addedd");
                    setTag([...tag, { _id: res.data._id, tagname: newTag }]);
                    setTagName([...tagName, newTag]);
                    setNewTag('')
                })
                .catch((err) => {
                    enqueueSnackbar(err && err.response && err.response.data.message ? err.response.data.message : "Something went wrong", { variant: 'error' });
                    console.log(err);
                });
        }
    };

    const handleVideoChange = (e, index) => {
        setError({ video: '' });
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
        fileObj.push(e.target.files);
        for (let i = 0; i < fileObj[0].length; i++) {
            if (fileObj[0][i].size > 25 * 1024 * 1024) { // Corrected comparison
                setError({ video: 'Video should be less than 25 MB' });
                return;
            }
            fileArray.push(URL.createObjectURL(fileObj[0][i]));
            filepath.push(fileObj[0][i]);
        }

        setVideoPreviewUrl(fileArray);
        setSelectedVideo(filepath);
    }



    const clearVideo = () => {
        setSelectedVideo([]);
        setVideoPreviewUrl([]);
    };
    const handleNewTagChange = (e) => {
        setNewTag(e.target.value);
    }

    const handleUpdateCommunity = async () => {
        // var valid = validate();
        // if (valid == true) {
        // setTruebutton(true);
        const filteredData = [];
        const seenIds = {};

        selecttedEvent.forEach(item => {
            if (!seenIds[item.event_id]) {
                filteredData.push(item);
                seenIds[item.event_id] = true;
            }
        });

        console.log(filteredData)
        let body = {
            event_id: JSON.stringify(filteredData.length > 0 ? filteredData : []),
        }
        
        
        console.log(body, 'bodybodybody');
        axios.post(process.env.REACT_APP_BASE_URL + `community/UpdateEventCommunity/${communityData?._id}`, body, { headers: headerlocal }).then((res) => {
            console.log(res, "resfrfrfrfrfrfrfrfrf");
            setSelecttedEvent([]);
            fetchCommunity(params.id)
            enqueueSnackbar("Community updated successfully", { variant: 'success' });
            localStorage.setItem('communityData', JSON.stringify(res.data.updateventcommunity));
           

			// navigate(`/teachers/details/community/${params.id}`)

        }).catch((err) => {
            enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' });
            console.log(err, "error");
        });
    
    }

    const handleNext = () => {
        var valid = validate()
        if (valid === true) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        if (valid === true && validatenew === true) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };


    const validatenew = () => {
        // if (state2?.length == 0 && selectedVideo?.length == 0) {
        // 	setError({ video: "This field is required." })
        // 	setImageAddError("This field is required.")
        // 	return false
        // }
        // if (state2?.length == 0) {
        // 	setImageAddError("This field is required.")
        // 	return false
        // }
        // if (selectedVideo?.length == 0) {
        // 	setError({ video: "This field is required." })
        // 	return false
        // }

        return true
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
console.log([params.id])
    const addEventDetails = () => {
        var multipleImage = state2.filter(x => x != null);
        var valid = validate()
        var validnew = validatenew()
        if (valid === true && validnew === true) {
            const unlayer = emailEditorRef.current?.editor;
            unlayer?.exportHtml((data) => {
                // const { design, html } = data;
                const { design } = data;
                // const sanitizedHtml = DOMPurify.sanitize(html);
                const formData = new FormData()
                // Assuming params.id is the value you want to send as an array element
                
                let idArray = [];

                // if (selecttedTeacher.length > 0) {
				// 	const idArray = selecttedTeacher.map(id => id.user_id);
				// 	idArray.forEach(id => {
				// 		formData.append('createdby[]', id);
				// 	});
				// 	formData.append('createdby[]', auth._id);
				// } else {
				// 	formData.append('createdby[]', auth._id);
				// }

                // if (params && params.id) {
                //     idArray = Array.isArray(params.id) ? params.id : [params.id];
                // }

                // if (selecttedTeacher.length > 0) {
				// 	const idArray = selecttedTeacher.map(id => id.user_id);
				// 	idArray.forEach(id => {
				// 		formData.append('createdby[]', id);
				// 	});
				// 	formData.append('createdby[]', auth._id);
				// } else {
				// 	formData.append('createdby[]', auth._id);
				// }


                // if (selecttedTeacher.length > 0) {
				// 	const idArray = selecttedTeacher.map(id => id.user_id);
				// 	idArray.forEach(id => {
				// 		formData.append('createdby[]', id);
				// 	});
				// 	formData.append('createdby[]', auth._id);
				// } else {
				// 	formData.append('createdby[]', auth._id);
				// }



             
                // idArray = [params?.id,communityData?._id]
                idArray = [communityData?._id]
                console.log(idArray,"pass",communityData)
                
                // idArray.map(id => (
                //     formData.append('createdby[]',id )
                // )                  
                // );
                //  if (params && params.id) {
                //     formData.append('createdby[]',params.id );
                // }
             
                // formData.append('createdby[]',JSON.stringify(idArray) );

                
              

                formData.append("createdbyTeacher[]", params?.id)

                formData.append('createdby[]', idArray)
                formData.append('addEventFrom', addEventFrom)
                formData.append("event_name", newevent.eventName)
                formData.append("status", status)
                formData.append("event_Organizer", newevent.organizer)
                formData.append("Category_id", categoryId)
                formData.append("Subcategory_id", subcategoryId)
                formData.append("fromTime", selectedFromDateTime)
                formData.append("ToTime", selectedToDateTime)
                formData.append("formatted_address", cityName)
                formData.append("latitude", lat)
                formData.append("longitude", lng)
                formData.append("DescriptionJSON", design && JSON.stringify(design.body))
                // formData.append("event_description", sanitizedHtml)
                // formData.append("DescriptionJSON", design && JSON.stringify(design.body))
                formData.append("shortDescription", description)
                formData.append("link", newevent.link)
                formData.append('tags', JSON.stringify(selecttedTag.length > 0 ? selecttedTag : []))
                formData.append("formatted_address", cityName)
                formData.append("gallery", selectGallert === 'Image' ? 'Image' : 'Video')
                 statesingle2 ? statesingle2.forEach((item) => { formData.append("newimage", item) }) : formData.append("newimage", '') 
                
                    selectGallert === 'Image' ? <>
                        {multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '')}
                        {selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", '') }) : formData.append("Video", '')}
                    </> : <>
                        {multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", '') }) : formData.append("additionalimages", '')}
                        {selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '')}</>
                
                function convertLatLongToNumbers(formData) {
                    const tempData = {};
                    for (let pair of formData.entries()) {
                        const key = pair[0].trim();
                        let value = pair[1];
                        if (key === 'latitude' || key === 'longitude') {
                            value = parseFloat(value);
                        }
                        tempData[key] = value;
                    }
                    formData = new FormData();
                    for (const [key, value] of Object.entries(tempData)) {
                        formData.append(key, value);
                    }
                    return formData;
                }
                const convertedFormData = convertLatLongToNumbers(formData);
            
                axios.post(process.env.REACT_APP_BASE_URL + `Event/AddEvent`, convertedFormData, { headers: headerformdata })
                    .then((res) => {
                        const resp = res.data.addEvent;
                        console.log(resp, "response");
                        if (resp) {
                            const obj = {
                                "event_name": resp.event_name || "",
                                "event_id": resp._id || "",
                                "shortDescription": resp.shortDescription || "",
                            };
                            console.log(obj, "object");
                            addCommunityEvent(obj);
                            fetchCommunity();
                            setCategoryId('');
                            setSubCategoryId('');
                            setState1([])
                            setState2([])
                            setStatesingle('')
                            setSelectedVideo('')
                            getEvent()
                            setNewEvent({ eventName: '', description: "", owner: "", specialization: "", address: "" });
                            enqueueSnackbar("Event added successfully", { variant: 'success' });

                			navigate(`/community/community-details/event/${params.id}`)


                        } else {
                            enqueueSnackbar("Something went wrong", { variant: 'error' });
                        }
                    }).catch((err) => {
                        console.log(err)
                        enqueueSnackbar(err && err.response && err.response.message ? err.response.message : err.response.data.error ? err.response.data.error : "Somthing went wrong", { variant: 'error' })
                    })
            })
        }
    }



    return (
        <Box sx={{ width: '100%' }} className="">
            <Box className="whiteContainer newmerchant mb-20">
                <Typography component='h6' className='formTitle'>Select Events</Typography>

                <Grid container className='mb-20 '>
                    <Grid item sm={12} md={12} className="w-100">
                        <Box className="form-box staff_list">
                            <Grid container columnSpacing={2} rowSpacing={2}>
                                {Tableloading?
                                (
                                    <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ height: '100%' }}
                                  >
                                    <Grid item>
                                      <CircularProgress sx={{ color: '#F3714D' }} />
                                    </Grid>
                                  </Grid>
                                )
                                :
                                
                                (event && event.length > 0 ? event.map((item, index) => (
                                    <Grid item sm={12} md={12} lg={4} className="w-100 d-flex" key={index}>
                                        <Box
                                            // key={item._id}
                                            onClick={(e) => habdleSelectEvent(e, item, index)}
                                            className={item.selectEvent ? "selected_staff staff_card" : "staff_card"}
                                        >
                                            <Check className="checksvg"/>
                                            <Avatar className='mr-10' src={process.env.REACT_APP_BASE_URL + item.image}>
                                                {item.event_name && item.event_name.substring(0, 1)}
                                            </Avatar>
                                            <Box>
                                                <Box className='fz-16 semibold'>{item.event_name}</Box>
                                                {/* {category && category?.length > 0 ? category.map((data) => (
                                                    <Box className="fz-13" value={data._id}>{data.category_title}</Box>)) : <Box disabled>{"No Record's found"}</Box>} */}
                                            </Box>
                                        </Box>
                                    </Grid>
                                )) : 
                                (
                                <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                style={{ height: '100%' }}
                              >
                                <Grid item>
                                  <Typography>No events found</Typography>
                                </Grid>
                              </Grid>))}
                            </Grid>
                        </Box>

                    </Grid>
                </Grid>
                <Box className="text-right ">
                    {buttondisable === false ? <Button variant="contained" onClick={handleUpdateCommunity}
                        className='theme-btn mt-20'>Submit</Button> : <Button variant="contained" className='theme-btn mt-20'>Please wait</Button>}
                </Box>
            </Box>
            {/* <Grid container className='newmerchant'> */}
            <Box className=''>
                <Box className='whiteContainer p-20'>
                    <Typography component='h6' className='formTitle '>Add Events</Typography>

                    <Stepper className="mb-20 mt-20" alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === 0 &&
                        <Box className='steppy_content'>
                            <Box className="maxw_700">
                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Event Name <Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            placeholder='Event Name'
                                            size="small"
                                            name='eventName'
                                            className='textfield'
                                            style={{ width: '100%' }}
                                            value={event.eventName}
                                            onChange={(e) => {
                                                handlechange(e); setError(prevError => ({ ...prevError, eventName: '' }))
                                            }}

                                        />
                                        {error.eventName && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.eventName}</p>}
                                    </FormControl>
                                </Box>



                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Select Mode<Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl fullWidth >
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={status ? status : "sel"}
                                            placeholder="Select mode of event"
                                            className="select"
                                            onChange={(e) => { setStatus(e.target.value); setError('') }}
                                        >
                                            <MenuItem disabled value={'sel'}>Select status of Event</MenuItem>
                                            <MenuItem value={"online"}>Online</MenuItem>
                                            <MenuItem value={"offline"}>Offline</MenuItem>
                                        </Select>
                                        {error.status && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.status}</p>}
                                    </FormControl>
                                </Box>
                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Select Tags<Typography component='span' className='star'></Typography></Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            className='select'
                                            value={tagName}
                                            onChange={(e) => handlechangeTag(e,)}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                const selecttedTag = tag.filter(content => selected.includes(content.tagname));
                                                if (selecttedTag.length === 0) {
                                                    return "Any Tags";
                                                } else if (selecttedTag.length === 1) {
                                                    return selecttedTag[0].tagname;
                                                } else {
                                                    return `${selecttedTag.length} Tags selected`;
                                                }
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {tag && tag.length > 0 ? tag.map((name) => (
                                                <MenuItem key={name._id} value={name.tagname} >
                                                    <Checkbox checked={tagName.indexOf(name.tagname) > -1} />
                                                    <ListItemText primary={name.tagname} />
                                                </MenuItem>
                                            )) : <MenuItem>No user found</MenuItem>}
                                        </Select>
                                        {/* <p className='error-field'>{error.userselect}</p> */}
                                    </FormControl>
                                </Box>
                                <Box className="mb-20">
                                    <Typography component='div' className='label'>New Tag</Typography>
                                    <Box className='flex-center align-items-start'>
                                        <FormControl variant="standard" fullWidth className="flex-1">
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                placeholder='New Tag'
                                                size="small"
                                                name='newTag'
                                                className='textfield mr-10 '
                                                value={newTag}
                                                onChange={handleNewTagChange}
                                            />
                                            {tagError && <p style={{ color: 'red' }} className="fz-13 m-0" >{tagError}</p>}
                                        </FormControl>
                                        <Button onClick={handleAddNewTag} variant="contained" className='theme-btn mt-10'>Add Tag</Button>
                                    </Box>
                                </Box>

                                {/* <Box className='mb-20'>
									<Typography component='div' className='label'>Select Teacher</Typography>
									<FormControl fullWidth>
										<Select
											labelId="demo-multiple-checkbox-label"
											id="demo-multiple-checkbox"
											multiple
											className='select'
											value={teacherName}
											onChange={(e) => handlechangeTeacher(e,)}
											input={<OutlinedInput />}
											renderValue={(selected) => {
												const selecttedTeacher = teacher.filter(content => selected.includes(content.username));
												if (selecttedTeacher.length === 0) {
													return "Any Teacher";
												} else if (selecttedTeacher.length === 1) {
													return selecttedTeacher[0].username;
												} else {
													return `${selecttedTeacher.length} Teacher selected`;
												}
											}}
											MenuProps={MenuProps}
										>
											{teacher && teacher.length > 0 ? teacher.map((name) => (
												<MenuItem key={name._id} value={name.username} >
													<Checkbox checked={teacherName.indexOf(name.username) > -1} />
													<ListItemText primary={name.username} />
												</MenuItem>
											)) : <MenuItem>No teacher found</MenuItem>}
										</Select>
										{/* <p className='error-field'>{error.userselect}</p> */}
                                {/* </FormControl> */}
                                {/* </Box> */}

                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Event Organizer <Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            placeholder='Event Organizer'
                                            size="small"
                                            name='organizer'
                                            className='textfield'
                                            style={{ width: '100%' }}
                                            value={event.organizer}
                                            onChange={(e) => { handlechange(e); setError(prevError => ({ ...prevError, organizer: '' })) }}

                                        />
                                        {error.organizer && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.organizer}</p>}
                                    </FormControl>
                                </Box>

                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Select Category <Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl fullWidth >
                                        <Select
                                            className="select"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={categoryId ? categoryId : 'sel'}
                                            onChange={(e) => { setCategoryId(e.target.value); setCatError("") }}>
                                            <MenuItem disabled value={'sel'} >Select category</MenuItem>
                                            {category && category?.length > 0 ? category.map((data) => (
                                                <MenuItem value={data._id}>{data.category_title}</MenuItem>)) : <MenuItem disabled>{"No Record's found"}</MenuItem>}
                                        </Select>
                                        {catError && <p style={{ color: 'red' }} className="fz-13 m-0" >{catError}</p>}
                                    </FormControl>
                                </Box>
                                <Box className="mb-20" >
                                    <Typography component='div' className='label' style={{ marginRight: '10px' }}>Select Sub Category</Typography>
                                    <FormControl fullWidth style={{ flex: 1 }}>
                                        <Select
                                            className="select"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            disabled={categoryId !== '' ? false : true}
                                            value={subcategoryId ? subcategoryId : 'sel18'}
                                            onChange={(e) => {
                                                setSubCategoryId(e.target.value);
                                                setSubCatError('');
                                                setError({ servicename: '' });
                                                setCatError('');
                                                setSubCatError('');
                                            }}>
                                            <MenuItem disabled value={'sel18'}>Select sub category</MenuItem>
                                            {subcat && subcat?.length > 0 ? (
                                                subcat.map((data5) => (
                                                    <MenuItem value={data5._id}>{data5.subcategory_title}</MenuItem>
                                                ))) : (<MenuItem disabled>{"No Record's found"}</MenuItem>)}
                                        </Select>
                                        {subcatError && (<p style={{ color: 'red' }} className="fz-13 m-0">{subcatError}</p>)}
                                    </FormControl>
                                    {subcategoryId !== '' && (
                                        <RestartAltIcon style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handleRemoveRestart()} />
                                    )}
                                </Box>

                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Link<Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            placeholder='Link'
                                            size="small"
                                            name='link'
                                            className='textfield'
                                            style={{ width: '100%' }}
                                            value={event.link}
                                            onChange={(e) => { handlechange(e) }}
                                        />
                                        {error.link && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.link}</p>}
                                    </FormControl>
                                </Box>
                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Start Date<Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl variant="standard" fullWidth className="datepicker">
                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <DemoContainer components={['DateTimePicker', 'DateTimePicker']} >
                                                <DateTimePicker
                                                    onChange={handleFromDateTimeChange}
                                                    value={selectedFromDateTime && selectedFromDateTime}
                                                    // minDate={dayjs()}
                                                    renderInput={(props) => <TextField {...props} />}
                                                    format="DD-MM-YYYY hh:mm a"
                                                    viewRenderers={{
                                                        hours: renderTimeViewClock,
                                                        minutes: renderTimeViewClock,
                                                        seconds: renderTimeViewClock,
                                                    }}
                                                    className="textfield"
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                        {error.fromTime && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.fromTime}</p>}
                                    </FormControl>
                                </Box>
                                <Box className="mb-20">
                                    <Typography component='div' className='label'>End Date<Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl variant="standard" fullWidth className="datepicker">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                                <DateTimePicker
                                                    size="small"
                                                    className="textfield"
                                                    onChange={handleToDateTimeChange}
                                                    value={selectedToDateTime && selectedToDateTime}
                                                    minDate={dayjs(selectedFromDateTime)}
                                                    renderInput={(props) => <TextField {...props} />}
                                                    format="DD-MM-YYYY hh:mm a"
                                                    viewRenderers={{
                                                        hours: renderTimeViewClock,
                                                        minutes: renderTimeViewClock,
                                                        seconds: renderTimeViewClock,
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                        {error.toTime && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.toTime}</p>}
                                    </FormControl>
                                </Box>


                                {status === 'offline' &&
                                    <Box className="mb-20">
                                        <Typography component='div' className='label'>Location <Typography component='span' className='star'> * </Typography></Typography>
                                        <AutoComplete cityName={cityName} setCityName={setCityName} lat={lat} setLat={setLat} lng={lng} setLng={setLng} className="textfield" />
                                    </Box>
                                    
                                    }
                                    {error.location && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.location}</p>}

                                {/* <Box className="mb-10">
									<Typography component='div' className='label'>Location<Typography component='span' className='star'>*</Typography></Typography>
									<input
										style={{ outline: "none", fontFamily: "Nunito,sans-serif", fontSize: "14px", width: '100%' }}
										ref={ref}
										placeholder="location"
									/>
									{error.location && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.location}</p>}
								</Box>
 */}

                                <Grid item xs={12} sm={12} md={12}  >
                                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                        <Typography component='div' className='label'>Primary Image <Typography component='span' className='star'> * </Typography></Typography>
                                        {statesingle.length < 1 &&
                                            <Button className="upload_btn" variant="contained" component="label">
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
                                            </Button>}
                                    </Box>
                                </Grid>
                                {imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}
                                <Grid item xs={12} sm={12} md={12}  >
                                    {statesingle.length > 0 &&
                                        <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                            {statesingle.length > 0 ? statesingle.map((x, i) => (
                                                <Grid item xs={6} sm={4} md={4} lg={4} >
                                                    <Box className='uploaded_img' >
                                                        <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                                        <img src={x} alt='' height={155} width={195} />
                                                    </Box>
                                                </Grid>
                                            )) : ""}
                                        </Grid>}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Typography className=' fz-12 mt-10 mb-20' component='p' variant='p'><strong>Note:</strong> Image size should be more than 280px * 280px for better quality picture.</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}  >
                                    <Box className="mb-10">
                                        <Typography component='div' className='label'>Short Description </Typography>
                                        <FormControl variant="standard" fullWidth>
                                            <textarea
                                                placeholder='Short Description '
                                                className='textarea'
                                                defaultValue={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                rows={4}
                                                cols={40} 
                                                style={{resize:'none'}}/>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                {
                                    activeStep === 0 &&
                                    <>
                                        <Box className='heading-btns mt-40' style={{ textAlign: 'end' }}>
                                            <Button onClick={handleNext} variant="contained" className='theme-btn  '>Next</Button>
                                        </Box>
                                    </>}
                            </Box>
                        </Box >}
                    {activeStep === 1 &&
                        <Box className=" mb-10 steppy_content">
                            <Grid container columnSpacing={2} rowSpacing={2} className=" mb-10">

                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography component='div' className='label'>Images/Video </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={8} >
                                    <Box >
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-customized-radios"
                                            name="payment_type"
                                            onChange={handleEditGallery}
                                            value={selectGallert}
                                        >
                                            <FormControlLabel value="Image" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Additional images</Typography>} />
                                            <FormControlLabel value="Video" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Video</Typography>} />
                                        </RadioGroup>
                                    </Box>
                                </Grid>
                                {selectGallert === 'Image' ? <>
                                    <Grid item xs={12} sm={12} md={12}  >
                                        <Box className="mb-10 ">
                                            <Typography component='div' className='label'>Additional Images</Typography>

                                            {state1.length < 1 &&
                                                <Button className="multiple_upload_btn" variant="contained" component="label">
                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                    <input accept={"image/png, image/jpg,image/jpeg"} onChange={multipleImgChange} hidden multiple type="file" />
                                                </Button>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}  >
                                        {state1.length > 0 &&
                                            <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                {state1.length > 0 ? state1.map((x, i) => (
                                                    <Grid item xs={6} sm={4} md={3} lg={3} >
                                                        <Box className='uploaded_img' >
                                                            <Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
                                                            <img src={x} alt='' height={155} width={195} />
                                                        </Box>
                                                    </Grid>
                                                )) : ""}
                                                <Grid item xs={6} sm={4} md={3} lg={3} >
                                                    <label htmlFor="contained-button-file1" className="w-full">
                                                        <Box className='multiple_upload_btn' >
                                                            <Input
                                                                inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
                                                                onChange={multipleImgChange}
                                                                style={{ display: "none", }}
                                                                id="contained-button-file1"
                                                                type="file"
                                                            />
                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
                                                        </Box>
                                                    </label>

                                                </Grid>
                                            </Grid>}
                                        <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 280px * 280px for better quality picture.</Typography>
                                        {imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageAddError}</p>}
                                    </Grid>

                                </> : <>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography component='div' className='label'>Video</Typography>
                                        <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                            {selectedVideo && selectedVideo?.length === 0 &&
                                                <Button className="multiple_upload_btn" variant="contained" component="label">
                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
                                                    </Box>
                                                    <input id="video-upload" accept="video/*" onChange={handleVideoChange} hidden type="file" />
                                                </Button>
                                            }
                                            <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                {selectedVideo && selectedVideo?.length > 0 &&
                                                    <Grid item xs={6} sm={4} md={4} lg={4} >
                                                        <Box  >
                                                            <Button className='close_icon' onClick={clearVideo}><Close /></Button>
                                                            <video className='uploaded_vdo' controls>
                                                                <source src={videoPreviewUrl} type={selectedVideo.type} />
                                                            </video>
                                                        </Box></Grid>}
                                            </Grid>
                                        </Box>
                                        {error.video && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.video}</p>}
                                    </Grid>

                                </>}


                                {
                                    activeStep === 1 &&
                                    <Grid item xs={12} sm={12} md={12}>

                                        <Box className='heading-btns mt-40' style={{ textAlign: 'end' }}>
                                            <Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>

                                            <Button onClick={handleNext} variant="contained" className='theme-btn  '>Next</Button>
                                        </Box>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    }

                    {activeStep === 2 &&
                        <Box className=" mb-10 steppy_content">
                            <Grid item xs={12} sm={12} md={12} lg={12} className='mb-10'>
                                <EmailEditor ref={emailEditorRef}
                                    onLoad={onLoad}
                                    projectId={144031}
                                    tools={{
                                        "custom#product_tool": {
                                            // data: { products },
                                            // properties: {
                                            // 	productLibrary: {
                                            // 		editor: {
                                            // 			data: {
                                            // 				products,
                                            // 			},
                                            // 		},
                                            // 	},
                                            // },
                                        },
                                    }}
                                    options={{
                                        designTags: {
                                            business_name: "My business",
                                            current_user_name: "Ron",
                                        },
                                        customCSS: [
                                            "https://examples.unlayer.com/examples/product-library-tool/productTool.css",
                                        ],
                                        customJS: [
                                            window.location.protocol + "//" +
                                            window.location.host + "/custom.js",
                                        ],
                                    }}
                                />
                            </Grid>
                            <Box className='heading-btns mt-20' style={{ textalign: 'end' }}>
                                <Button onClick={handleEditor} variant="contained" className='theme-btn  mr-10'>SaveDesign</Button>
                            </Box>
                        </Box>
                    }
                    <>
                        {activeStep === 2 && truebutton === false ?
                            <>
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    <Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>
                                    <Button onClick={addEventDetails} variant="contained" className='theme-btn'>Submit</Button>
                                </Box>
                            </> : activeStep === 2 && truebutton === true && <>
                                <Box className='heading-btns mt-20' style={{ textalign: 'end' }}>
                                    <Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>
                                    <Button variant="contained" className='theme-btn  mr-10'>Please wait...</Button>
                                </Box>
                            </>}</>
                </Box >
            </Box>
            {/* </Grid> */}
        </Box>
    );
}
