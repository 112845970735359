import React, { useRef, useState } from "react";
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Sidebar from './../../Sidebar'
import MiniDrawer from '../../../../../components/drawer';
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, TextField, Typography, Input,	Dialog,
	DialogActions,	DialogTitle,DialogContent,Card,CardContent, } from '@mui/material'
import { Close } from '@mui/icons-material';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:
        '#E80A89',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:
        '#E80A89',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

function Service_Steppyform() {
  const headerlocal = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    Authorization: localStorage.getItem("token")
  }
  const headerformdata = {
    'Content-Type': 'multipart/form-data',
    Authorization: localStorage.getItem("token")
  }
  const auth = JSON.parse(localStorage.getItem("userData"));
  const params = useParams()

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [state1, setState1] = React.useState([]);
  const [state2, setState2] = React.useState([]);
  const [imageAddError, setImageAddError] = React.useState('');
  const [addImageFrom, setaddImageFrom] = useState('Teacher')
  const [truebutton, setTruebutton] = React.useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [Addon, setAddon] = React.useState(false);

	const [imageAddons, setimageAddons] = React.useState([]);
	const [addonsModal, setAddonsModal] = React.useState(false);
	
	const [selectedCard, setSelectedCard] = useState(null);

  const multipleImgChange = (e, index) => {
    setImageAddError('');

    // Validate file size
    const maxSize = 1024 * 1024; // 1MB in bytes
    const files = e.target.files;
    var fileObj = [];
    var fileArray = [];
    var filepath = [];

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxSize) {
        // Handle file size error (for example, set an error state)
        setImageAddError('Image size should be less than 1 MB.');
        continue; // Skip this file
      }

      fileObj.push(files[i]);
      fileArray.push(URL.createObjectURL(files[i]));
      filepath.push(files[i]);
    }

    var data = state1.filter(x => x != null);
    var data1 = state2.filter(x => x != null);
    setState1(data);
    setState2(data1);
    setState1(prevValue => prevValue.concat(fileArray));
    setState2(prevValue => prevValue.concat(filepath));
  }

  const indexDelete = (e, index) => {
    var filteredpreview = state1.filter((value, i) => {
      return i !== index;
    });
    var filteredraw = state2.filter((val, i) => {
      return i !== index;
    });
    setState1(filteredpreview);
    setState2(filteredraw);
  }
  // console.log(JSON.stringify(params.id))

  const handleAddImage = () => {
    var multipleImage = state2.filter(x => x != null);
    var valid = validatenew();

    if (valid === true) {
      setTruebutton(true);
      setIsLoading(true);
      const formData = new FormData();
      formData.append('addImageFrom', addImageFrom)

      multipleImage?.forEach((item) => { formData.append("additionalimages", item) }) 
			formData.append('createdby[]', params.id);
			formData.append('approve_images', true);

      


      axios.post(process.env.REACT_APP_BASE_URL + `image/addimage`, formData, { headers: headerformdata })
        .then((res) => {
          setTruebutton(false);
          navigate(`/teachers/details/image/${params.id}`);
          setState1([]);
          setState2([]);
          enqueueSnackbar("Additional images added successfully", { variant: 'success' })
          console.log(res, "added");
        })
        .catch((err) => {
          setTruebutton(false);
          
          enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
          console.log(err, "error");
          if (err.response.data?.status == 3) {
            setAddon(true);
            }
            
        }).finally(() => {
          setIsLoading(false);
        })
    }
  }

  const validatenew = () => {
    if (state2?.length === 0) {
      setImageAddError("This field is required.")
      return false
    }
    return true
  }
  const handlePurchaseAddons = async () => {
		var someData = auth
			? auth.planId?.addOns?.filter((item) => item.sectionName == "Images")
			: []; //display only events addons
		// var someData = auth ? auth.planId?.addOns : [] // display all addons
		setimageAddons(someData);
		setAddonsModal(true);
		setAddon(false);
	};
	
	
	
	const handleAddonClose = () => {
		setAddon(false);
	};
	
	
	const AddonClose = () => {
		setAddonsModal(false);
	};
	
	const handleCardClick = (item) => {
		setSelectedCard(item);
	};

  
	
	const handleAddonSubmit = async () => {
		const body = {
			userId: auth._id,
			customerId: auth.stripeCustomerId,
			// AddOns : selectedCard // if selectedCard is an array
			AddOns: [selectedCard], // [selectedCard] because selectedCard is an object.. If multiple items can be selected push each item into selectedCard.
			redirectUrl:
				`https://${auth.name}.247sites.in/
				teachers/details/image/add/${auth._id}`,
		};
    await axios
			.put(
				process.env.REACT_APP_BASE_URL + `subscription/UpdateAddOnsByUserid`,
				body,
				{ headers: headerlocal }
			)
			.then((res) => {
				console.log(res.data?.PaymentLink, "update response console");
				window.open(res.data?.PaymentLink);
				AddonClose();
			})
			.catch((err) => {
				console.log(err, "UpdateAddOnsByUserid catch error");
			});
	};






  return (
    <Box className={auth.role_id === 1 ? 'withSubmenu' : ''}>
      {auth.role_id === 1 ? <MiniDrawer menuOrder={2} submenuOrder={1} />
        : <MiniDrawer menuOrder={6} />}
      {auth.role_id === 1 ? <Sidebar subtype={5} /> : ''}
      <Box component="section" className="contentWraper">
        <Box className='whiteContainer p-20'>
          <Typography component='h6' className='formTitle mb-20'>Images</Typography>

          
              <Grid container columnSpacing={2} rowSpacing={2} className=" mb-10">
                <Grid item xs={12} sm={12} md={12}  >
                  <label className='form-labels label' for="my-input" >Additional Images<Typography component='span' className='star'> * </Typography></label>
                  <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                    {state1.length < 1 &&
                      <Button className="multiple_upload_btn" variant="contained" component="label">
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                        <input accept={"image/png, image/jpg,image/jpeg"} onChange={multipleImgChange} hidden multiple type="file" />
                      </Button>
                    }
                  </Box>
                </Grid>


                <Grid item xs={12} sm={12} md={12}  >
                  {state1.length > 0 &&
                    <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                      {state1.length > 0 ? state1.map((x, i) => (
                        <Grid item xs={6} sm={4} md={3} lg={2} >
                          <Box className='uploaded_img' >
                            <Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
                            <img src={x} alt='' height={155} width={195} />
                          </Box>
                        </Grid>
                      )) : ""}
                      <Grid item xs={6} sm={4} md={3} lg={2} >
                        <label htmlFor="contained-button-file1" className="w-full">
                          <Box className='multiple_upload_btn' >
                            <Input
                              inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
                              onChange={multipleImgChange}
                              style={{ display: "none", }}
                              id="contained-button-file1"
                              type="file"
                            />
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
                          </Box>
                        </label>
                      </Grid>
                    </Grid>
                  }
                  {imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageAddError}</p>}

                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
                </Grid>

              </Grid>
        

          {/* {truebutton === false ?
            <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
              <Button onClick={handleAddImage} variant="contained" className='theme-btn'>Submit</Button>
            </Box>
            : truebutton === true &&
            <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
              <Button variant="contained" className='theme-btn'>Please Wait</Button>
            </Box>} */}

            {isLoading ? 
              <Button variant="contained" className='theme-btn' disabled>Please Wait...</Button> 
              : 
              <Button onClick={handleAddImage} variant="contained" className='theme-btn'>Submit</Button>
            }

        </Box >
        <Dialog
					open={Addon}
					keepMounted
					onClose={handleAddonClose}
					aria-describedby="alert-dialog-slide-description"
					className="custom_modal"
				>
					<DialogTitle>Would you like to purchase Image Addons?</DialogTitle>
					<DialogActions>
						<Button onClick={handleAddonClose} className="cancel_btn">
							No
						</Button>
						<Button onClick={handlePurchaseAddons} className="theme-btn">
							Yes
						</Button>
					</DialogActions>
				</Dialog>
				
				
				
				
				
				
				
				
				
				
				
					<Dialog open={addonsModal} onClose={AddonClose} maxWidth="sm" fullWidth>
					<DialogTitle>Select Details</DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							{imageAddons && imageAddons.length > 0 ? (
								imageAddons.map((item, index) => (
									<Grid item xs={12} sm={6} md={4} key={index}>
										<Card
											variant="outlined"
											sx={{
												cursor: "pointer",
												"&:hover": { boxShadow: 3 },
												border:
													selectedCard?.count === item?.count
														? "2px solid #1976d2"
														: "1px solid #e0e0e0",
											}}
											onClick={() => handleCardClick(item)}
										>
											<CardContent>
												<Typography variant="h6">{item.sectionName}</Typography>
												<Typography variant="body1">
													Amount Type: {item.amount_type}
												</Typography>
												<Typography variant="body1">
													Amount: {item.amount}
												</Typography>
												<Typography variant="body1">
													Count: {item?.count}
												</Typography>
											</CardContent>
										</Card>
									</Grid>
								))
							) : (
								<Grid container justifyContent="center" alignItems="center">
									<Grid item xs={12} sm={6} md={4}>
										<Card
											variant="outlined"
											sx={{
												cursor: "pointer",
												"&:hover": { boxShadow: 3 },
												mt: 5,
											}}
										>
											<CardContent>
												<Typography variant="body1">
													Currently your plan don't have any addons
												</Typography>
											</CardContent>
										</Card>
									</Grid>
								</Grid>
							)}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={AddonClose} color="primary">
							Cancel
						</Button>
						{imageAddons && imageAddons.length > 0 ? (
							<Button
								onClick={handleAddonSubmit}
								color="primary"
								variant="contained"
								disabled={!selectedCard}
							>
								Submit
							</Button>
						) : null}
					</DialogActions>
				</Dialog>


      </Box >
    </Box >
  )
}

export default Service_Steppyform;
