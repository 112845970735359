import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MiniDrawer from '../../../../../components/drawer';
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, Radio,Card,CardContent, Dialog,DialogTitle,DialogContent,DialogActions,TextField, Typography, FormControlLabel, Input, RadioGroup, OutlinedInput, Checkbox, ListItemText } from '@mui/material'
import { Close, Dvr, ListAlt, WidthFull } from '@mui/icons-material';
import CompareIcon from '@mui/icons-material/Compare';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Switch from '@mui/material/Switch';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from 'draft-js-export-html';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import ClearIcon from '@mui/icons-material/Clear';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EmailEditor from "react-email-editor";
import DOMPurify from 'dompurify';
import Sample from './sample.json'
import Sidebar from './../../Sidebar'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};
function BpRadio(props) {
	return (
		<Radio
			sx={{
				'&:hover': {
					bgcolor: 'transparent',
				},
			}}
			disableRipple
			color="default"
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			{...props}
		/>
	);
}
const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: '50%',
	width: 16,
	height: 16,
	boxShadow:
		theme.palette.mode === 'dark'
			? '0 0 0 1px rgb(16 22 26 / 40%)'
			: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
	backgroundImage:
		theme.palette.mode === 'dark'
			? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
			: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	'.Mui-focusVisible &': {
		outline: '2px auto rgba(19,124,189,.6)',
		outlineOffset: 2,
	},
	'input:hover ~ &': {
		backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background:
			theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
	},
}));

const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: '#f3714d',
	backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	'&:before': {
		display: 'block',
		width: 16,
		height: 16,
		backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		content: '""',
	},
	'input:hover ~ &': {
		backgroundColor: '#f3714d',
	},
});
const IOSSwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 40,
	height: 20,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 0,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(20px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
				opacity: 1,
				border: 0,
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5,
			},
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#33cf4d',
			border: '6px solid #fff',
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color:
				theme.palette.mode === 'light'
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
		},
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 18.5,
		height: 18.5,
	},
	'& .MuiSwitch-track': {
		borderRadius: 18.5 / 2,
		backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500,
		}),
	},
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
	display: 'flex',
	height: 22,
	alignItems: 'center',
	...(ownerState.active && {
		color: '#784af4',
	}),
	'& .QontoStepIcon-completedIcon': {
		color: '#784af4',
		zIndex: 1,
		fontSize: 18,
	},
	'& .QontoStepIcon-circle': {
		width: 8,
		height: 8,
		borderRadius: '50%',
		backgroundColor: 'currentColor',
	},
}));

function QontoStepIcon(props) {
	const { active, completed, className } = props;
	return (
		<QontoStepIconRoot ownerState={{ active }} className={className}>
			{completed ? (
				<Check className="QontoStepIcon-completedIcon" />
			) : (
				<div className="QontoStepIcon-circle" />
			)}
		</QontoStepIconRoot>
	);
}

QontoStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor:
				'#f3714d',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor:
				'#f3714d',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor:
			theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
		borderRadius: 1,
	},
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
	zIndex: 1,
	color: '#fff',
	width: 50,
	height: 50,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...(ownerState.active && {
		backgroundColor:
			'#f3714d',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	}),
	...(ownerState.completed && {
		backgroundColor:
			'#f3714d',
	}),
}));

function ColorlibStepIcon(props) {
	const { active, completed, className } = props;

	const icons = {
		1: <ListAlt />,
		2: <CompareIcon />,
		3: <Dvr />,
	};
	return (
		<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
			{icons[String(props.icon)]}
		</ColorlibStepIconRoot>
	);
}

ColorlibStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
	/**
	 * The label displayed in the step icon.
	 */
	icon: PropTypes.node,
};

const steps = ['Basic Information', 'Blog Information', 'Blog Description'];
export default function AddBlogs() {
	const params = useParams()
	const auth = JSON.parse(localStorage.getItem('userData'))
	const headerlocal = {
		'Content-Type': 'application/json',
		"Access-Control-Allow-Origin": "*",
		Authorization: localStorage.getItem("token")
	}
	const headerformdata = {
		'Content-Type': 'multipart/form-data',
		Authorization: localStorage.getItem("token")
	}
	const [activeStep, setActiveStep] = React.useState(0);
	const navigate = useNavigate()
	const { enqueueSnackbar } = useSnackbar()
	const [blog, setBlog] = React.useState('')
	const [truebutton, setTruebutton] = React.useState(false)
	const [error, setError] = React.useState({ eventName: "", organizer: "", fromTime: "", toTime: "", video: "" })
	const [data, setData] = React.useState([])
	const [categoryId, setCategoryId] = React.useState('')
	const [subcategoryId, setSubCategoryId] = React.useState('')
	const [catError, setCatError] = React.useState('')
	const [subcatError, setSubCatError] = React.useState('')
	const [imageError, setImageError] = React.useState('')
	const [imageAddError, setImageAddError] = React.useState('')
	const [state1, setState1] = React.useState([])
	const [state2, setState2] = React.useState([])
	const [statesingle, setStatesingle] = React.useState([])
	const [statesingle2, setStatesingle2] = React.useState([])
	const [description, setDescription] = React.useState('')
	const [richeditor, setRichtor] = React.useState("")
	const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
	const dataofeditor = `${richeditor}`
	const [publishername, setPublishername] = useState('');
	const [selectedVideo, setSelectedVideo] = useState([]);
	const [videoPreviewUrl, setVideoPreviewUrl] = useState([]);
	const [recordings, setRecordings] = React.useState([])
	const [value, setValue] = React.useState(dayjs());
	const [isPublic, setPublic] = React.useState(false)
	const [isPrimary, setPrimary] = React.useState(false)
	const [selectGallert, setSelectGallery] = useState('Image')
	const [teacher, setTeacher] = React.useState([])
	const emailEditorRef = useRef({ editor: {} });
	const [addBlogFrom, setaddBlogFrom] = useState('Teacher')
	const [selectedCard, setSelectedCard] = useState(null);
	const [addonsModal, setAddonsModal] = React.useState(false)
	const [numberOfBlogs, setNumberOfBlogs] = useState('');
	const [open, setOpen] = React.useState(false);
	const [eventAddons, setEventAddons] = React.useState([])
	const redirecturl = process.env.REACT_APP_REDIRECT_URL
	console.log(redirecturl,"redirecturl");

	const [amount] = useState('1000'); // Fixed amount value
	const datas = [
		{ sectionName: "Event", amount_type: "INR", amount: "30", count: "3" },
		{ sectionName: "Event", amount_type: "INR", amount: "50", count: "5" }
	];

	const handleNumberOfBlogsChange = (event) => {
		setNumberOfBlogs(event.target.value);
	};


	const handleCardClick = (item) => {
		setSelectedCard(item);
	};

	const handleSubmit = async () => {
		const body = {
			userId: auth._id,
			customerId : auth.stripeCustomerId,
			// AddOns : selectedCard // if selectedCard is an array
			AddOns: [selectedCard],   // [selectedCard] because selectedCard is an object.. If multiple items can be selected push each item into selectedCard.
			redirectUrl: `https://${auth.name}.247sites.in/teachers/details/blog/add/${auth._id}`
	
			}
		console.log(body,'bodybodybody')
		await axios.put(process.env.REACT_APP_BASE_URL + `subscription/UpdateAddOnsByUserid`, body,{ headers: headerlocal })
			.then(res => {
				console.log(res.data?.PaymentLink, "update response console")
				window.open(res.data?.PaymentLink)
				onClose();
			})
			.catch(err => {
				console.log(err,"UpdateAddOnsByUserid catch error")
			})
	};


	const handleClose = () => {
        setOpen(false);
    };


	const handlePurchaseAddons = async () => {
		 var someData = auth ? auth.planId?.addOns?.filter(item => item.sectionName == 'Blogs') : [] //display only events addons
		//var someData = auth ? auth.planId?.addOns : [] // display all addons
		setEventAddons(someData)
		setAddonsModal(true)
		setOpen(false)
	}

	const onClose = () => {
		setAddonsModal(false)
	}



	const [designEditor, setDesignEditor] = useState(Sample)
	const onLoad = () => {
		emailEditorRef.current.editor.loadDesign(designEditor);
	};
	const handleEditor = (e) => {
		const unlayer = emailEditorRef.current?.editor;
		unlayer?.exportHtml((data) => {
			const { design, html } = data;
			const sanitizedHtml = DOMPurify.sanitize(html);
			const editor = { "body": design.body }
			setDesignEditor(editor)
			enqueueSnackbar('Saved changes', { variant: 'info' });
		})
	}

	const handleEditGallery = (e) => {
		setSelectGallery(selectGallert === 'Image' ? 'Video' : 'Image')
	}
	React.useEffect(() => {
		getCategory()
	}, [])

	React.useEffect(() => {
		getSubCategory()
	}, [categoryId])

	const handleTogglePublic = () => {
		setPublic(!isPublic)
	}
	const handleTogglePrimary = () => {
		setPrimary(!isPrimary)
	}

	React.useEffect(() => {
		getTeacher()
	}, [])

	const getTeacher = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getTeachers`, { headers: headerlocal });
			setTeacher(response.data.teachers);
			console.log(response, "teachers");
		} catch (error) {
			console.log(error.response, "error");
		}
	}

	const [selecttedTeacher, setSelecttedTeacher] = React.useState([])
	const [teacherName, setTeacherName] = React.useState([])
	const handlechangeTeacher = (event, item) => {
		const {
			target: { value },
		} = event;
		setTeacherName(
			typeof value === 'string' ? value.split(',') : value,
		);
		getTeacherval()
	}
	React.useEffect(() => {
		getTeacherval()
	}, [teacherName])

	function getTeacherval() {
		var array = []
		const selecttedTeacher = teacher.filter(content => teacherName.includes(content.username));
		selecttedTeacher.map((item, i) => {
			var val = { username: item.username, email: item.email }
			Object.assign(item, val)
			array.push(val)
		})
		setSelecttedTeacher(array)
	}


	const getCategory = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `Category/GetCategory`, { headers: headerlocal }).then((res) => {
			setRecordings(res.data.category)
		}).catch((error) => {
			console.log(error.response, "error");
		})
	}

	const getSubCategory = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `SubCategory/GetSubcategorybasedonCategoryId?category_id=${categoryId}`, { headers: headerlocal })
			.then((res) => {
				setData(res.data.subcategory)
			}).catch((error) => {
				console.log(error.response, "error");
			})
	}

	React.useEffect(() => {
		if (dataofeditor) {
			try {
				const contentState = stateFromHTML(dataofeditor);
				setEditorState(EditorState.createWithContent(contentState));
			} catch (error) {
				console.error("Error during HTML to ContentState conversion:", error);
			}
		}
	}, [dataofeditor]);

	const handleRemoveRestart = () => {
		setSubCategoryId('')
	}

	const multipleImgChange = (e, index) => {
		setImageAddError('');
	
		// Validate file size
		const maxSize = 1024 * 1024; // 1MB in bytes
		const files = e.target.files;
		var fileObj = [];
		var fileArray = [];
		var filepath = [];
	
		for (let i = 0; i < files.length; i++) {
			if (files[i].size > maxSize) {
				// Handle file size error (for example, set an error state)
				setImageAddError('Image size should be less than 1 MB.');
				continue; // Skip this file
			}
			
			fileObj.push(files[i]);
			fileArray.push(URL.createObjectURL(files[i]));
			filepath.push(files[i]);
		}
	
		var data = state1.filter(x => x != null);
		var data1 = state2.filter(x => x != null);
		setState1(data);
		setState2(data1);
		setState1(prevValue => prevValue.concat(fileArray));
		setState2(prevValue => prevValue.concat(filepath));
	}

	const indexDelete = (e, index) => {
		var filteredpreview = state1.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = state2.filter((val, i) => {
			return i !== index;
		});
		setState1(filteredpreview);
		setState2(filteredraw);
	}

	const SingleImage = (e, index) => {
        setImageError('');
        setError('');
    
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
    
        fileObj.push(e.target.files);
    
        for (let i = 0; i < fileObj[0].length; i++) {
            const img = new Image();
            img.src = URL.createObjectURL(fileObj[0][i]);
    
            img.onload = function () {
                if (fileObj[0][i].size > 1000000) { // 1 MB in bytes
                    setImageError('Image size should be less than 1 MB.');
                } else {
                    fileArray.push(URL.createObjectURL(fileObj[0][i]));
                    filepath.push(fileObj[0][i]);
    
                    var data = statesingle.filter(x => x != null);
                    var data1 = statesingle2.filter(x => x != null);
                    setStatesingle(data);
                    setStatesingle2(data1);
                    setStatesingle(prevValue => prevValue.concat(fileArray));
                    setStatesingle2(prevValue => prevValue.concat(filepath));
                }
            };
        }
    };


	const indexDelete1 = (e, index) => {
		var filteredpreview = statesingle.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = statesingle2.filter((val, i) => {
			return i !== index;
		});
		setStatesingle(filteredpreview);
		setStatesingle2(filteredraw);
	}

	const validatenew = () => {
		// if (state2?.length === 0) {
		// 	setImageAddError("This field is required.")
		// 	return false
		// }
		// console.log("commmitment")
		// if (selectedVideo?.length === 0) {
		// 	setError({ video: "This field is required." })
		// 	return false
		// }
		// return true
		if (state2.length == 0 && selectedVideo.length == 0) {
			setImageAddError("This field is required.");
			setError({ video: "This field is required." })

			return false;
		  }
		  return true;
	}
	
	const handleNext = () => {
		// var valid = validate()
		// if (valid === true) {
		// 	setActiveStep((prevActiveStep) => prevActiveStep + 1);
		// }
		// if (valid === true && validatenew === true) {
		// 	setActiveStep((prevActiveStep) => prevActiveStep + 1);
		// }
		const valid = validate();

    if (valid === true) {
      if (activeStep === 1) {
        const validatation = validatenew();

        if (validatation === true) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
	};
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};
	const[user,setUser]=React.useState({ instagram:'', facebook:'',twitter:'', youtube:'',pinterest:''})
	const handleChange = (e, index) => {
		const { name, value } = e.target;
	  
			setUser({ ...user, [name]: value });
	
	};
	


	const validate = () => {
		if (!blog && !categoryId && statesingle?.length == 0) {
			setError({ eventName: "This field is required." })
			setCatError("This field is required.")
			setImageError("This field is required.")
			return false
		}

		if (!blog) {
			setError({ eventName: "This field is required." })
			return false
		}

		if (!categoryId) {
			setCatError("This field is required.")
			return false
		}
		if (statesingle?.length == 0) {
			setImageError("This field is required.")
			return false
		}
		return true
	}

	

	const handleAddBlog = () => {
		var multipleImage = state2.filter(x => x != null);
		var valid = validate()
		// var validnew = validatenew()
		if (valid == true) {
			setTruebutton(true)
			const unlayer = emailEditorRef.current?.editor;
			unlayer?.exportHtml((data) => {
				const { design, html } = data;
				const sanitizedHtml = DOMPurify.sanitize(html);
				console.log(design.body, 'beforeehformadatadesc');
				const formData = new FormData();
				formData.append('addBlogFrom',addBlogFrom )
				formData.append("Blog_title", blog)
				// formData.append("createdby", params && params.id)
				formData.append('createdby[]', params && params.id);
				formData.append("Date", value)
				formData.append("category_id", categoryId)
				formData.append("subcategory_id", subcategoryId)
				formData.append("shortdescription", description)
				formData.append("published_by", publishername)
				formData.append("instagram", user.instagram ? user.instagram : '')
				formData.append("facebook", user.facebook ? user.facebook : '' )
				formData.append("twitter", user.twitter ? user.twitter : '')
				formData.append("youtube", user.youtube ? user.youtube : '')
				formData.append("pinterest", user.pinterest ? user.pinterest : '')
				formData.append("tiktok", user.tiktok ? user.tiktok : '')
				formData.append("Description", sanitizedHtml)
				formData.append("DescriptionJSON", design && JSON.stringify(design.body))
				formData.append("public", isPublic)
				formData.append("primary", isPrimary)
				// formData.append('teacher', JSON.stringify(selecttedTeacher.length > 0 ? selecttedTeacher : []))
				formData.append("gallery", selectGallert === 'Image' ? 'Image' : 'Video')
				{ statesingle2 ? statesingle2.forEach((item) => { formData.append("newimage", item) }) : formData.append("newimage", '') }
				{
					selectGallert === 'Image' ? <>
						{multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '')}
						{selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", '') }) : formData.append("Video", '')}
					</>
						:
						<>
							{multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", '') }) : formData.append("additionalimages", '')}
							{selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '')}</>
				}
				axios.post(process.env.REACT_APP_BASE_URL + `Blog/AddBlog`, formData, { headers: headerformdata }).then((res) => {
					setTruebutton(false)
					navigate(`/teachers/details/blog/${params.id}`);
					setBlog('');
					setCategoryId('');
					setSubCategoryId('');
					setState1([])
					setState2([])
					enqueueSnackbar("Blog added successfully", { variant: 'success' })					
				}).catch((err) => {
					setTruebutton(false)
					enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
					if (err.response.data?.status == 3) {
						setOpen(true)
					}
					console.log(err, "error");
				})
			})
		}
	}
	// const handleVideoChange = (e, index) => {
	// 	setImageError('')
	// 	var fileObj = [];
	// 	var fileArray = [];
	// 	var filepath = [];
	// 	fileObj.push(e.target.files)
	// 	for (let i = 0; i <= fileObj[0].length; i++) {
	// 		if (i < fileObj[0].length) {
	// 			fileArray.push(URL.createObjectURL(fileObj[0][i]))
	// 			filepath.push(fileObj[0][i])
	// 		}
	// 	}
	// 	setVideoPreviewUrl(fileArray)
	// 	setSelectedVideo(filepath)
	// }
	const clearVideo = () => {
		setSelectedVideo([]);
		setVideoPreviewUrl([]);
	};

	const handleVideoChange = (e, index) => {
		setError({ video: '' });	
			var fileObj = [];
		var fileArray = [];
		var filepath = [];
		fileObj.push(e.target.files);
		for (let i = 0; i < fileObj[0].length; i++) {
			if (fileObj[0][i].size > 25 * 1024 * 1024) { 
				setError({ video: 'Video should be less than 25 MB' });
				return;
			}
			fileArray.push(URL.createObjectURL(fileObj[0][i]));
			filepath.push(fileObj[0][i]);
		}
	
		setVideoPreviewUrl(fileArray);
		setSelectedVideo(filepath);
	}


	return (
		// <Box className='withSubmenu'>
		<Box className={auth.role_id == 1 ? 'withSubmenu' : ''}>
		{auth.role_id == 1 ? <MiniDrawer menuOrder={2} submenuOrder={1} />
			: <MiniDrawer menuOrder={5} />}
		{auth.role_id == 1 ? <Sidebar subtype={2} /> : ''}
			<Box component="section" className="contentWraper">
				<Box className='whiteContainer p-20'>
					<Typography component='h6' className='formTitle mb-20'>Add Blog</Typography>
					<Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					{activeStep === 0 &&
						<Box className='steppy_content'>
							<Box className="maxw_700">
								<Box className="mb-20">
									<Typography component='div' className='label'>Blog Name <Typography component='span' className='star'>*</Typography></Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="filled-size-small"
											variant="outlined"
											placeholder='Blog Name'
											size="small"
											className='textfield'
											style={{ width: '100%' }}
											value={blog}
											onChange={(e) => {
												setBlog(e.target.value); setError({ eventName: '' })
											}}

										/>
										{error.eventName && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.eventName}</p>}
									</FormControl>
								</Box>

								<Box className='mb-20'>
									<FormControlLabel className="checkbox_list"
										control={
											<Checkbox
												checked={isPublic}
												onChange={handleTogglePublic}
												name='checkbox-value'
												color='primary'
											/>
										}
										label={<Typography component='div' className='label mb-0'>Do you want to make this event as Public? </Typography>}
									/>
									<FormControlLabel className="checkbox_list"
										control={
											<Checkbox
												checked={isPrimary}
												onChange={handleTogglePrimary}
												name='checkbox-value'
												color='primary'
											/>
										}
										label={<Typography component='div' className='label mb-0'>Do you want to make this event as Primary? </Typography>}
									/>
								</Box>
								{/* <Box className='mb-20'>
									<Typography component='div' className='label'>Select Users</Typography>
									<FormControl fullWidth>
										<Select
											labelId="demo-multiple-checkbox-label"
											id="demo-multiple-checkbox"
											multiple
											className='select'
											value={teacherName}
											onChange={(e) => handlechangeTeacher(e,)}
											input={<OutlinedInput />}
											renderValue={(selected) => {
												const selecttedTeacher = teacher.filter(content => selected.includes(content.username));
												if (selecttedTeacher.length === 0) {
													return "Any Teacher";
												} else if (selecttedTeacher.length === 1) {
													return selecttedTeacher[0].username;
												} else {
													return `${selecttedTeacher.length} Teacher selected`;
												}
											}}
											MenuProps={MenuProps}
										>
											{teacher && teacher.length > 0 ? teacher.map((name) => (
												<MenuItem key={name._id} value={name.username} >
													<Checkbox checked={teacherName.indexOf(name.username) > -1} />
													<ListItemText primary={name.username} />
												</MenuItem>
											)) : <MenuItem>No teacher found</MenuItem>}
										</Select>
										{/* <p className='error-field'>{error.userselect}</p> */}
									{/* </FormControl> */}
								{/* </Box>  */}

								<Box className="mb-20">
									<Typography component='div' className='label'>Cover Image  <Typography component='span' className='star'>*</Typography></Typography>
									<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
										{statesingle.length < 1 &&
											<Button className="multiple_upload_btn" variant="contained" component="label">
												<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
												<input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
											</Button>
										}
									</Box>
									{imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}
								</Box>
								<Box >
									{statesingle.length > 0 &&
										<Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
											{statesingle.length > 0 ? statesingle.map((x, i) => (
												<Grid item xs={6} sm={4} md={4} lg={4} >
													<Box className='uploaded_img' >
														<Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
														<img src={x} alt='' height={155} width={195} />
													</Box>
												</Grid>
											)) : ""}
										</Grid>
									}
								</Box>
								<Box>
									<Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 500px * 600px for better quality picture.</Typography>
								</Box>

								<Box className="mb-20">
									<Typography component='div' className='label'>Select Category <Typography component='span' className='star'>*</Typography></Typography>
									<FormControl fullWidth >
										<Select
											className="select"
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={categoryId ? categoryId : 'sel'}
											onChange={(e) => { setCategoryId(e.target.value); setCatError('') }}>
											<MenuItem disabled value={'sel'} >Select category</MenuItem>
											{recordings && recordings?.length > 0 ? recordings.map((data) => (

												<MenuItem value={data._id}>{data.category_title}</MenuItem>
											)) :
												<MenuItem disabled>{"No Record's found"}</MenuItem>}
										</Select>
										{catError && <p style={{ color: 'red' }} className="fz-13 m-0" >{catError}</p>}
									</FormControl>
								</Box>
								<Box className="mb-20">
									<Typography component='div' className='label' >
										Select Sub Category
									</Typography>
									<Box className="flex-center">
										<FormControl fullWidth style={{ flex: 1 }}>
											<Select
												className="select"
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												disabled={categoryId !== '' ? false : true}
												value={subcategoryId ? subcategoryId : 'sel18'}
												onChange={(e) => {
													setSubCategoryId(e.target.value);
													setSubCatError('');
													setError({ servicename: '' });
													setCatError('');
													setSubCatError('');
												}}
											>
												<MenuItem disabled value={'sel18'}>
													Select sub category
												</MenuItem>
												{data && data?.length > 0 ? (
													data.map((data5) => (

														<MenuItem value={data5._id}>{data5.subcategory_title}</MenuItem>
													))
												) : (
													<MenuItem disabled>{"No Record's found"}</MenuItem>
												)}
											</Select>
											{subcatError && (
												<p style={{ color: 'red' }} className="fz-13 m-0">
													{subcatError}
												</p>
											)}
										</FormControl>
										{subcategoryId !== '' && (
											<RestartAltIcon style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handleRemoveRestart()} />
										)}
									</Box>
								</Box>


								<Box className="mb-20">
                                  <FormControl variant="standard" fullWidth>
                                    <Typography component="div" className="label">
                                      Instagram Link:
                                      <Typography
                                        component="span"
                                        className="star"
                                      ></Typography>
                                    </Typography>
                                    <TextField
                                      id="filled-size-small"
                                      variant="outlined"
                                      size="small"
                                      name="instagram"
                                      className="textfield"
                                      value={user.instagram}
                                      placeholder="Instagram Link"
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                </Box>
                                <Box className="mb-20">
                                  <FormControl variant="standard" fullWidth>
                                    <Typography component="div" className="label">
                                      Facebook Link:
                                      <Typography
                                        component="span"
                                        className="star"
                                      ></Typography>
                                    </Typography>
                                    <TextField
                                      id="filled-size-small"
                                      variant="outlined"
                                      size="small"
                                      name="facebook"
                                      className="textfield"
                                      value={user.facebook}
                                      placeholder="Facebook Link"
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                </Box>
                                <Box className="mb-20">
                                  <FormControl variant="standard" fullWidth>
                                    <Typography component="div" className="label">
                                      Twitter Link:
                                      <Typography
                                        component="span"
                                        className="star"
                                      ></Typography>
                                    </Typography>
                                    <TextField
                                      id="filled-size-small"
                                      variant="outlined"
                                      size="small"
                                      name="twitter"
                                      className="textfield"
                                      value={user.twitter}
                                      placeholder="Twitter Link"
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                </Box>
                                <Box className="mb-20">
                                  <FormControl variant="standard" fullWidth>
                                    <Typography component="div" className="label">
                                      Youtube Link:
                                      <Typography
                                        component="span"
                                        className="star"
                                      ></Typography>
                                    </Typography>
                                    <TextField
                                      id="filled-size-small"
                                      variant="outlined"
                                      size="small"
                                      name="youtube"
                                      className="textfield"
                                      value={user.youtube}
                                      placeholder="Youtube Link"
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                </Box>
                                <Box className="mb-20">
                                  <FormControl variant="standard" fullWidth>
                                    <Typography component="div" className="label">
                                      Pinterest Link:
                                      <Typography
                                        component="span"
                                        className="star"
                                      ></Typography>
                                    </Typography>
                                    <TextField
                                      id="filled-size-small"
                                      variant="outlined"
                                      size="small"
                                      name="pinterest"
                                      className="textfield"
                                      value={user.pinterest}
                                      placeholder="Pinterest Link"
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                </Box>
                        <Box className="mb-20">
                        <FormControl variant="standard" fullWidth>
                                                            <Typography component='div' className='label'>Tiktok Link:<Typography component='span' className='star'></Typography></Typography>
                                                            <TextField
                                                                id="filled-size-small"
                                                                variant="outlined"
                                                                size="small"
                                                                name='tiktok'
                                                                className='textfield'
                                                                value={user.tiktok}
                                                                placeholder='Tiktok Link'
                                                                onChange={handleChange}
                                                            />
                
                                                        </FormControl>
                                </Box>

								<Box className="mb-20">
									<Typography component='div' className='label'>Short Description </Typography>
									<FormControl variant="standard" fullWidth>
										<textarea
											placeholder='Short Description '
											className='textarea'
											defaultValue={description}
											onChange={(e) => setDescription(e.target.value)}
											rows={4}
											cols={40} />
									</FormControl>
								</Box>
								{
									activeStep === 0 &&
									<>
										<Box className='heading-btns mt-40' style={{ textAlign: 'end' }}>
											<Button onClick={handleNext} variant="contained" className='theme-btn  '>Next</Button>
										</Box>
									</>
								}
							</Box>
						</Box >
					}
					{activeStep === 1 &&
						<Box className="steppy_content mb-10">
							<Grid container columnSpacing={2} rowSpacing={2} className=" mb-10">

								<Grid item xs={12} sm={4} md={4} lg={4}>
									<Typography component='div' className='label'>Images/Video </Typography>
								</Grid>
								<Grid item xs={12} sm={8} md={8} lg={8}>
									<Box className=" ">
										<RadioGroup
											row
											aria-labelledby="demo-customized-radios"
											name="payment_type"
											onChange={handleEditGallery}
											value={selectGallert}
										>
											<FormControlLabel value="Image" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Additional images</Typography>} />
											<FormControlLabel value="Video" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Video</Typography>} />
										</RadioGroup>
									</Box>
								</Grid>
								{selectGallert === 'Image' ? <>
									<Grid item xs={12} sm={12} md={12}  >
										<Box className="mb-10 ">
											<Typography component='div' className='label'>Additional Images<Typography component='span' className='star'>*</Typography></Typography>
											<Box className="d-flex justify-content-between flex-wrap align-items-center">
												{state1.length < 1 &&
													<Button className="multiple_upload_btn" variant="contained" component="label">
														<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
														<input accept={"image/png, image/jpg,image/jpeg"} onChange={multipleImgChange} hidden multiple type="file" />
													</Button>
												}
											</Box>
										</Box>
									</Grid>
									<Grid item xs={12} sm={12} md={12}  >
										{state1.length > 0 &&
											<Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
												{state1.length > 0 ? state1.map((x, i) => (
													<Grid item xs={6} sm={4} md={3} lg={3} >
														<Box className='uploaded_img' >
															<Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
															<img src={x} alt='' height={155} width={195} />
														</Box>
													</Grid>
												)) : ""}
												<Grid item xs={6} sm={4} md={4} lg={4} >
													<label htmlFor="contained-button-file1" className="w-full">
														<Box className='multiple_upload_btn' >
															<Input
																inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
																onChange={multipleImgChange}
																style={{ display: "none", }}
																id="contained-button-file1"
																type="file"
															/>
															<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
														</Box>
													</label>
												</Grid>
											</Grid>}
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 280px * 280px for better quality picture.</Typography>
										{imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageAddError}</p>}
									</Grid>
								</> : <>
									<Grid item xs={12} sm={12} md={12}>
										<Typography component='div' className='label'>Video<Typography component='span' className='star'>*</Typography></Typography>
										<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
											{selectedVideo && selectedVideo?.length === 0 &&
												<Button className="multiple_upload_btn" variant="contained" component="label">
													<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
														<FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
													</Box>
													<input id="video-upload" accept="video/*" onChange={handleVideoChange} hidden type="file" />
												</Button>
											}
											<Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mt-20">
												{selectedVideo && selectedVideo?.length > 0 &&
													<Grid item xs={6} sm={4} md={4} lg={4} >
														<Box className="uploaded_vdo " >
															<Button className='close_icon' onClick={clearVideo}><Close /></Button>
															<video className='uploaded_vdo' controls>
																<source src={videoPreviewUrl} type={selectedVideo.type} />
															</video>
														</Box></Grid>}
											</Grid>
										</Box>
										{error.video && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.video}</p>}
									</Grid>
								</>}
								{
									activeStep === 1 &&
									<Grid item xs={12} sm={12} md={12}>

										<Box className='heading-btns mt-40' style={{ textAlign: 'end' }}>
											<Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>

											<Button onClick={handleNext} variant="contained" className='theme-btn  '>Next</Button>
										</Box>
									</Grid>
								}
							</Grid>
						</Box>
					}

					{activeStep === 2 &&
						<Box className="steppy_content mb-10">
							<Grid item xs={12} sm={12} md={12} lg={12} className='mb-10'>
								<EmailEditor ref={emailEditorRef}
									onLoad={onLoad}
									projectId={144031}
									tools={{
										"custom#product_tool": {
											// data: { products },
											// properties: {
											// 	productLibrary: {
											// 		editor: {
											// 			data: {
											// 				products,
											// 			},
											// 		},
											// 	},
											// },
										},
									}}
									options={{
										designTags: {
											business_name: "My business",
											current_user_name: "Ron",
										},
										customCSS: [
											"https://examples.unlayer.com/examples/product-library-tool/productTool.css",
										],
										customJS: [
											window.location.protocol + "//" +
											window.location.host + "/custom.js",
										],
									}}
								/>
							</Grid>
							<Box className='heading-btns mt-20' style={{ textalign: 'end' }}>
								<Button onClick={handleEditor} variant="contained" className='theme-btn  mr-10'>SaveDesign</Button>
							</Box>
						</Box>
					}
					<>
						{activeStep === 2 && truebutton === false ?
							<>
								<Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
									<Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>
									<Button onClick={handleAddBlog} variant="contained" className='theme-btn'>Submit</Button>
								</Box>
							</> : activeStep === 2 && truebutton === true &&
							<>
								<Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
									<Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>
									<Button variant="contained" className='theme-btn  mr-10'>Please wait...</Button>
								</Box>
							</>
						}
					</>
				</Box>
				<Dialog open={addonsModal} onClose={onClose} maxWidth="sm" fullWidth>
					<DialogTitle>Select Details</DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
						{eventAddons && eventAddons.length > 0 && eventAddons.map((item, index) => (
								<Grid item xs={12} sm={6} md={4} key={index}>
									<Card
										variant="outlined"
										sx={{
											cursor: 'pointer',
											'&:hover': { boxShadow: 3 },
											border: selectedCard?.count === item?.count ? '2px solid #1976d2' : '1px solid #e0e0e0'
										}}
										onClick={() => handleCardClick(item)}
									>
										<CardContent>
											<Typography variant="h6">{item.sectionName}</Typography>
											<Typography variant="body1">Amount Type: {item.amount_type}</Typography>
											<Typography variant="body1">Amount: {item.amount}</Typography>
											<Typography variant="body1">Count: {item?.count}</Typography>
										</CardContent>
									</Card>
								</Grid>
							))}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose} color="primary">
							Cancel
						</Button>
						<Button onClick={handleSubmit} color="primary" variant="contained" disabled={!selectedCard}>
							Submit
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={open} keepMounted
					onClose={handleClose}
					aria-describedby="alert-dialog-slide-description"
					className='custom_modal'>
					<DialogTitle>Would you like to purchase Blog Addons?</DialogTitle>
					<DialogActions>
						<Button onClick={handleClose} className='cancel_btn'>No</Button>
						<Button onClick={handlePurchaseAddons} className='theme-btn'>Yes</Button>
					</DialogActions>
				</Dialog>
			</Box>
		</Box >
	)
}